import { FC, ReactNode, Suspense, memo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { SuspenseLoading, Props as SuspenseLoadingProps } from './fallback';

type TSuspenseErrorBoundary = {
  children: ReactNode;
  errorFallback: () => ReactNode;
  onClose?: () => void;
  loadingFallback?: ReactNode;
  isLoading?: boolean;
  loading?: SuspenseLoadingProps;
};

export const SuspenseReactErrorBoundary: FC<TSuspenseErrorBoundary> = memo(
  ({ loadingFallback, errorFallback, children, isLoading, loading }) => {
    return (
      <Suspense
        fallback={
          loadingFallback ?? isLoading ? (
            <SuspenseLoading {...loading} />
          ) : (
            <></>
          )
        }
      >
        <ErrorBoundary fallbackRender={errorFallback}>{children}</ErrorBoundary>
      </Suspense>
    );
  }
);
SuspenseReactErrorBoundary.displayName = 'SuspenseReactErrorBoundary';
