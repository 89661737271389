// import { useBusinessPersonData } from '@/features/InvoiceReport/Edit/components/Context/BusinessPersonDataContext';
import { TGetInvoiceKind } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/services/type';
import { useCallback } from 'react';
import { getInvoiceKind } from '../services/getInvoiceKind.service';

export const useGetInvoiceKind = () => {
  return useCallback((args: TGetInvoiceKind) => {
    return getInvoiceKind(args);
  }, []);
};
