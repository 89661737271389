import { useSelectedApPayee } from '@/components/ApPayee/useChangeApPayee';
import { useIsActiveInstantPayee } from '@/components/ReportForm/InstantPayeeSwitcher/hooks/useIsActiveInstantPayee';
import { SelectedType } from '@/components/ReportForm/Modals/ChangeXXX';
import { PaymentRequestForm } from '@/features/InvoiceReport/Edit/type';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Control,
  FieldPath,
  FieldValues,
  get,
  Path,
  PathValue,
  useFormContext,
  useWatch,
} from 'react-hook-form';

type BookDateByPayeeTypeArgs = {
  control: Control<PaymentRequestForm>;
  originalBookDate: Dayjs | string | undefined;
};

export const useGetBookDateByPayeeType = ({
  control,
  originalBookDate,
}: BookDateByPayeeTypeArgs) => {
  const isActiveInstantPayee = useIsActiveInstantPayee({
    control,
    name: 'payee.value',
  });

  const newBookDateValue = useWatch({ control, name: 'payee.bookDate' });
  const payee = useSelectedApPayee();
  return useMemo(() => {
    if (isActiveInstantPayee) {
      return originalBookDate ? dayjs(originalBookDate) : null;
    }
    return newBookDateValue
      ? dayjs(newBookDateValue)
      : payee?.book_date
      ? dayjs(payee?.book_date)
      : null;
  }, [
    isActiveInstantPayee,
    payee?.book_date,
    newBookDateValue,
    originalBookDate,
  ]);
};

type ChangeBookDateArgs<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
};

export const useChangeBookDate = <TFieldValues extends FieldValues>({
  control,
  name,
}: ChangeBookDateArgs<TFieldValues>) => {
  const isActiveInstantPayee = useIsActiveInstantPayee<TFieldValues>({
    control,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    name: 'payee.value' as FieldPath<TFieldValues>,
  });
  const selectedPayee = useSelectedApPayee();
  const {
    formState: { dirtyFields },
    resetField,
  } = useFormContext<TFieldValues>();
  const bookDateValue = useWatch({ name, control });
  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);
  const onDialogClick = useCallback(
    (selectedValue: SelectedType) => {
      const bookDate = dayjs(
        selectedValue === 'all' ? bookDateValue : selectedPayee?.book_date
      );

      resetField(name, {
        defaultValue:
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          bookDate as PathValue<TFieldValues, Path<TFieldValues>>,
      });
      setOpen(false);
    },
    [bookDateValue, name, resetField, selectedPayee]
  );
  useEffect(() => {
    if (
      (isActiveInstantPayee || selectedPayee) &&
      bookDateValue &&
      get(dirtyFields, 'payee.bookDate') &&
      !get(dirtyFields, 'payee.value') &&
      dayjs(selectedPayee?.book_date).format('YYYY-MM-DD') !==
        dayjs(bookDateValue).format('YYYY-MM-DD')
    ) {
      setOpen(true);
    }
  }, [selectedPayee, isActiveInstantPayee, bookDateValue, dirtyFields]);
  return useMemo(
    () => ({
      open,
      onClose,
      onDialogClick,
    }),
    [onClose, onDialogClick, open]
  );
};
