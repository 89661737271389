import { useErrorInlineNotification } from '@/components/ErrorInlineNotification';
import { CurrencyRatesList } from '@/features/foreign_currency_settings/List';
import { Container } from '@/features/foreign_currency_settings/components/Container/Container';
import { RoundSetting } from '@/features/foreign_currency_settings/components/RoundSetting';
import { RoundSettingEditContextProvider } from '@/features/foreign_currency_settings/context/RoundSettingEdit';
import classnames from 'classnames/bind';
import { FC } from 'react';
import styles from './foreignCurrencySettings.module.scss';

const cx = classnames.bind(styles);

export const ForeignCurrencySettings: FC = () => {
  const [
    { setError },
    ErrorContextHolder,
    ErrorInlineNotificationProvider,
    onClearMessage,
  ] = useErrorInlineNotification();
  return (
    <ErrorInlineNotificationProvider value={setError}>
      <RoundSettingEditContextProvider>
        <Container>
          <div className={cx(styles['notify'])}>{ErrorContextHolder}</div>
          <div className={cx(styles['contents'])}>
            <RoundSetting />
            <CurrencyRatesList onClearMessage={onClearMessage} />
          </div>
        </Container>
      </RoundSettingEditContextProvider>
    </ErrorInlineNotificationProvider>
  );
};
