import { Wrapper } from '@/components/OpenFeature/Wrapper';
import { FinishedProviderSetting } from '@/components/OpenFeature/Wrapper/finishedProviderSetting';
import { useFFContext } from '@/components/OpenFeature/hooks/useFFContext';
import { PopupContainerProvider } from '@/components/ReportForm/Container/PopupContainer';
import { SuspenseErrorBoundary } from '@/components/SuspenseErrorBoundary';
import { AgentApplicantOfficeMemberProvider } from '@/context/AgentApplicantOfficeMember';
import { useGetMfFile } from '@/context/services/mf_file/MfFile.service';
import {
  useGetInvoiceReport,
  useGetInvoiceTransaction,
} from '@/context/services/reportsType/invoiceReports/Edit.service';
import { findReportFormInputTypeValue } from '@/context/services/reportsType/invoiceReports/useConvertApiDataAndPayeeForm';
import {
  useConvertTpmResponseToBusinessPersonData,
  useGetTpmInvoicesSuspense,
} from '@/context/services/tpm/hooks';
import { InvoiceReportEdit } from '@/features/InvoiceReport/Edit/components/InvoiceReportEdit';
import { Route } from '@/features/InvoiceReport/Edit/type';
import { OpenFeatureProvider } from '@moneyforward/user-flex-feature-hooks';
import { FC, memo, useMemo } from 'react';
import { ApiProvider, ComponentAPI } from '../components/Context';

export type Props = {
  invoiceReportsId: string;
  route: Route;
};

const InnerEditTemplate: FC<Props> = memo(({ invoiceReportsId, route }) => {
  const apiData = useGetInvoiceReport(invoiceReportsId);
  const transactionData = useGetInvoiceTransaction(invoiceReportsId);
  const invoiceFile = useGetMfFile(
    apiData.invoice_file?.id,
    Boolean(apiData.invoice_file?.id)
  );
  const registrationNumber = findReportFormInputTypeValue(
    apiData.report_form_inputs,
    'ApReportFormInputInvoiceRegistrationNumberField'
  );
  const resp = useGetTpmInvoicesSuspense(registrationNumber);
  const businessPersonData = useConvertTpmResponseToBusinessPersonData(resp);
  const context = useFFContext();

  return (
    <PopupContainerProvider>
      <ApiProvider
        formValue={apiData}
        detailValue={transactionData}
        invoiceFile={apiData.invoice_file ? invoiceFile : undefined}
        businessPersonData={businessPersonData}
        route={route}
      >
        <ComponentAPI>
          <AgentApplicantOfficeMemberProvider
            officeMember={
              ['normal'].includes(route) ? apiData.office_member?.id : undefined
            }
            received_invoices
            business_document
            mf_file
          >
            <Wrapper isProto={false}>
              <OpenFeatureProvider
                pollInterval={-1}
                context={context}
                domain='ap-frontend-foreign-currency-support'
              >
                <FinishedProviderSetting>
                  <InvoiceReportEdit invoiceReportsId={invoiceReportsId} />
                </FinishedProviderSetting>
              </OpenFeatureProvider>
            </Wrapper>
          </AgentApplicantOfficeMemberProvider>
        </ComponentAPI>
      </ApiProvider>
    </PopupContainerProvider>
  );
});
InnerEditTemplate.displayName = 'InnerEditTemplate';

export const EditTemplate: FC<Props> = memo(({ invoiceReportsId, route }) => {
  const loadingMemo = useMemo(
    () => ({ isFullFlame: true, isOverlay: true } as const),
    []
  );
  return (
    <SuspenseErrorBoundary loading={loadingMemo}>
      <InnerEditTemplate invoiceReportsId={invoiceReportsId} route={route} />
    </SuspenseErrorBoundary>
  );
});
EditTemplate.displayName = 'Edit';
