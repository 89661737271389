import { useFinishedProviderSettings } from '@moneyforward/user-flex-feature-hooks';
import { FC, memo, PropsWithChildren } from 'react';

export const FinishedProviderSetting: FC<PropsWithChildren> = memo(
  ({ children }) => {
    const finished = useFinishedProviderSettings();
    return finished ? children : <></>;
  }
);
FinishedProviderSetting.displayName = 'FinishedProviderSetting';
