import { FC, memo } from 'react';
import { Dialog as BaseDialog } from '../BaseDialog';
import { SelectedType } from '../type';
import { BookDate } from './BookDate';

export type Props = {
  open: boolean;
  onClose: VoidFunction;
  onClick: (value: SelectedType) => void;
};

export const Dialog: FC<Props> = memo(({ open, onClose, onClick }) => {
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      onClick={onClick}
      testId='book-date-dialog'
    >
      {({ onChange }) => (
        <BookDate key={`change-book-date-${open}`} onChange={onChange} />
      )}
    </BaseDialog>
  );
});
Dialog.displayName = 'Dialog';
