import {
  FormInputIdMap,
  InputOption,
  InvoiceReportDetailFormInputsResponse,
  reportFormInputType,
  typeGuardReportFormInputType,
} from 'ap-openapi';
import { useMemo } from 'react';

const multipleValueComponents: string[] = [
  reportFormInputType.ApReportFormInputCheckBox,
  reportFormInputType.ApReportFormInputBusinessDocumentsField,
];

export const useFormInputIdMapping = (
  reportFormInputs: InvoiceReportDetailFormInputsResponse[]
) => {
  const formInputIdMap: FormInputIdMap = useMemo(() => {
    return (
      reportFormInputs.reduce((prev, cur) => {
        const type = typeGuardReportFormInputType(cur.type) ? cur.type : '';
        return {
          [cur.id]: {
            inputId: cur.input_values?.[0]?.id,
            inputValue: cur.input_values?.[0]?.value ?? cur.default_value,
            priority: cur.priority,
            type: type,
            isMultipleValue: multipleValueComponents.includes(cur.type),
            hasOther: cur.has_other_value,
            key: cur.id,
          } satisfies InputOption,
          ...prev,
        };
      }, {}) ?? {}
    );
  }, [reportFormInputs]);
  return formInputIdMap;
};
