import {
  InvoiceKindSelect,
  Props as InvoiceKindSelectProps,
} from '@/components/InvoiceKindSelect';
import { useSelectorExcise } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/hooks/useSelectorExcise';
import { memo, useMemo } from 'react';
import { Control, useFormContext } from 'react-hook-form';
import type { PaymentRequestForm } from '../../../../type';

export type Props = {
  name: string;
  control: Control<PaymentRequestForm, unknown>;
  inputId: string;
  key: string | undefined;
  index: number;
} & InvoiceKindSelectProps<PaymentRequestForm>;

export const InnerInvoiceKind = ({
  control,
  name,
  inputId,
  key,
  selectProps,
  index,
}: Props) => {
  const { getIsZeroPer } = useSelectorExcise();
  const { watch } = useFormContext();
  const exciseId = watch(`invoiceTransactions.${index}.drExciseId`);
  const isNotTarget = useMemo(() => {
    return getIsZeroPer(exciseId);
  }, [exciseId, getIsZeroPer]);

  return (
    <InvoiceKindSelect<PaymentRequestForm>
      key={key}
      control={control}
      inputId={inputId}
      name={name}
      isNotTarget={isNotTarget}
      selectProps={selectProps}
    />
  );
};

InnerInvoiceKind.displayName = 'InnerInvoiceKind';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const InvoiceKind = memo((props) => (
  <InnerInvoiceKind {...props} />
)) as typeof InnerInvoiceKind;
InvoiceKind.displayName = 'InvoiceKind';
