import { BaseOption } from '@/hooks/useTypeToOptions';
import { useGetApPayeesHook } from 'ap-openapi';
import { useEffect, useRef, useState } from 'react';

export const useApPayeeInitialData = (payeeIds: string[]) => {
  const [payeeOptions, setPayeeOptions] = useState<BaseOption[]>([]);
  const previousPayeeIdsRef = useRef<string[]>([]);
  const getApPayeesApi = useGetApPayeesHook();

  useEffect(() => {
    const isDifferent =
      payeeIds.length !== previousPayeeIdsRef.current.length ||
      payeeIds.some((id, index) => previousPayeeIdsRef.current[index] !== id);
    if (payeeIds.length > 0 && isDifferent) {
      previousPayeeIdsRef.current = [...payeeIds];
      const fetchSelectedPayees = async () => {
        const response = await getApPayeesApi({
          payee_ids: payeeIds,
          include_inactive: 'true',
          per: payeeIds.length.toString(),
        });

        if (response.data.ap_payees) {
          const options = response.data.ap_payees.map((payee) => ({
            label: payee.name ?? '',
            value: payee.id ?? '',
            caption: payee.code ?? '',
          }));
          setPayeeOptions(options);
        }
      };
      fetchSelectedPayees();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payeeIds]);

  return payeeOptions;
};
