import { useTranslation } from '@/i18n';
import { Layout } from '@/Layout';
import { Invoice } from '@features/invoices';
import { FC } from 'react';

const InvoicePage: FC = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('received_invoice')}>
      <Invoice />
    </Layout>
  );
};

export default InvoicePage;
