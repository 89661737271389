import {
  EDocMetadata,
  EDocMetadataProps,
} from '@/features/InvoiceReport/Components/EDocMetadata';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  ButtonV2,
  IconClose,
  Popover,
} from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import styles from './Details.module.scss';

const cx = classNames.bind(styles);

export type Props = EDocMetadataProps;

export const Details: FC<Props> = memo((rest) => {
  const container = useGlobalContainerRef();
  const memorizedContent = useCallback(
    ({ onClose }: { onClose: VoidFunction }) => {
      return <InnerDetails onClose={onClose} {...rest} />;
    },
    [rest]
  );

  const [open, setOpen] = useState(false);
  const onClick = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);
  const memorizedButton = useMemo(() => {
    return (
      <ButtonV2
        containerClassName={cx(styles['black-button'])}
        testId='btn-open'
        onClick={onClick}
      >
        詳細
      </ButtonV2>
    );
  }, [onClick]);

  return (
    <Popover
      open={open}
      onClose={onClose}
      placement='bottomLeft'
      openContent={memorizedButton}
      getPopupContainer={container}
      getTooltipContainer={container}
      content={memorizedContent}
      disableOutSideClick
    />
  );
});
Details.displayName = 'Details';

type InnerDetailsProps = {
  onClose: VoidFunction;
} & EDocMetadataProps;

const InnerDetails: FC<InnerDetailsProps> = memo(
  ({
    dpi,
    pixelNumber,
    imageDepth,
    type,
    is_satisfy_resolution_requirement,
    is_satisfy_depth_requirements,
    onClose,
  }) => {
    return (
      <div className={cx(styles['container'])}>
        <button onClick={onClose} className={cx(styles['icon'])}>
          <IconClose size={18} />
        </button>
        <div className={cx(styles['list'])}>
          <EDocMetadata
            dpi={dpi}
            pixelNumber={pixelNumber}
            imageDepth={imageDepth}
            type={type}
            is_satisfy_resolution_requirement={
              is_satisfy_resolution_requirement
            }
            is_satisfy_depth_requirements={is_satisfy_depth_requirements}
          />
        </div>
      </div>
    );
  }
);
InnerDetails.displayName = 'InnerDetails';
