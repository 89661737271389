import { useCallback } from 'react';
import type { InvoiceTransaction } from '../../../../type';
import { InvoiceTransactionHelper } from './useInvoiceTransactionHelper';

type UpdateInvoiceTransactionOptions = {
  isTaxCalculationOnly?: boolean;
};

export const useUpdateInvoiceTransaction = ({
  dealDateHelper,
  drExciseIdHelper,
  exciseValueHelper,
  exItemIdHelper,
  quantityHelper,
  totalValueHelper,
  unitHelper,
  currencyValueHelper,
}: InvoiceTransactionHelper) => {
  return useCallback(
    (
      items: InvoiceTransaction[],
      args: UpdateInvoiceTransactionOptions = { isTaxCalculationOnly: false }
    ) => {
      return items.map((invoice) => {
        if (args.isTaxCalculationOnly) {
          invoice = exciseValueHelper(invoice);
          invoice = quantityHelper(invoice);
          invoice = totalValueHelper(invoice);
          invoice = currencyValueHelper(invoice);
          invoice = unitHelper(invoice);
        } else {
          invoice = dealDateHelper(invoice);
          invoice = drExciseIdHelper(invoice);
          invoice = exciseValueHelper(invoice);
          invoice = exItemIdHelper(invoice);
          invoice = quantityHelper(invoice);
          invoice = totalValueHelper(invoice);
          invoice = currencyValueHelper(invoice);
          invoice = unitHelper(invoice);
        }
        return invoice;
      });
    },
    [
      currencyValueHelper,
      dealDateHelper,
      drExciseIdHelper,
      exItemIdHelper,
      exciseValueHelper,
      quantityHelper,
      totalValueHelper,
      unitHelper,
    ]
  );
};
