import { useTranslation } from '@/i18n';
import {
  Bottom,
  Caption,
  Left,
  ListView,
  Right,
  Top,
} from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export type Props = {
  dpi: string;
  pixelNumber: string;
  imageDepth: string;
  type?: 'warning' | 'error';
  is_satisfy_resolution_requirement: boolean;
  is_satisfy_depth_requirements: boolean;
};

export const EDocMetadata: FC<Props> = memo(
  ({
    dpi,
    pixelNumber,
    imageDepth,
    type,
    is_satisfy_resolution_requirement,
    is_satisfy_depth_requirements,
  }) => {
    const { t } = useTranslation();

    return (
      <ListView>
        <Top>
          <ListItem
            label={t('dpi_label')}
            text={dpi}
            type={type}
            message={
              is_satisfy_resolution_requirement
                ? undefined
                : t('e_doc_plan_satisfy_resolution_requirement_error_label')
            }
          />
        </Top>
        <Top>
          <ListItem
            label={t('pixel_number_label')}
            text={pixelNumber}
            type={type}
          />
        </Top>
        <Top>
          <ListItem
            label={t('image_depth_label')}
            text={imageDepth}
            type={type}
            message={
              is_satisfy_depth_requirements
                ? undefined
                : t('e_doc_plan_satisfy_depth_requirements_error_label')
            }
          />
        </Top>
        <Bottom>
          <ListItem label={t('timestamp')} text='付与済み' />
        </Bottom>
      </ListView>
    );
  }
);
EDocMetadata.displayName = 'EDocMetadata';

type ListItemProps = {
  label: string;
  text: string;
  type?: 'warning' | 'error';
  message?: string;
};

const ListItem: FC<ListItemProps> = memo(({ label, text, type, message }) => {
  const { t } = useTranslation();

  return (
    <>
      <Left>{label}</Left>
      <Right>
        <div>
          <div>{text}</div>
          <div>
            {((type === 'warning' && message) || type === 'error') && (
              <Caption icon type={type === 'warning' ? 'alert' : 'error'}>
                {type === 'warning' ? message : t('e_doc_plan_error_label')}
              </Caption>
            )}
          </div>
        </div>
      </Right>
    </>
  );
});
ListItem.displayName = 'ListItem';
