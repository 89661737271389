import classNames from 'classnames';
import { FC, ReactNode, memo } from 'react';
import styles from './Cell.module.scss';

const cx = classNames.bind(styles);

export const Kinds = ['th', 'tb'] as const;
export type Kind = (typeof Kinds)[number];

export const Sizes = ['', 'sm', 'md', 'lg'] as const;
export type Size = (typeof Sizes)[number];

export type ForeignCurrency = {
  value: number;
  unit: string;
  isMultipleCurrency: boolean;
};

export type Props = {
  kind: Kind;
  textRight?: boolean;
  size?: Size;
  className?: string;
  children?: ReactNode;
  foreignCurrencyValue?: ForeignCurrency;
  role?: string;
  addJpy?: boolean;
};

export const Cell: FC<Props> = memo(
  ({
    kind,
    textRight,
    children,
    className,
    role = 'cell',
    size = 'md',
    addJpy = false,
  }) => {
    const currency = addJpy ? '円' : '';
    return (
      <div
        className={cx(styles['container'], size && styles[size], className)}
        role={role}
      >
        <div
          className={cx(styles['content'], styles[kind], {
            [styles['text-right']]: textRight,
          })}
        >
          {typeof children === 'number'
            ? `${children.toLocaleString()}${currency}`
            : typeof children === 'string'
            ? `${children}${currency}`
            : children}
        </div>
      </div>
    );
  }
);
Cell.displayName = 'Cell';
