import { InlineNotificationProps } from '@moneyforward/ap-frontend-components';
import {
  CommonError,
  InvoiceReportDetailResponseFlashMessagesItemType,
} from 'ap-openapi';
import { FieldError } from 'react-hook-form';

export type ApiErrors = {
  invoiceReport: string | undefined;
  invoiceTransactions: string | undefined;
  invoiceTransactionsAlert: string | undefined;
  invoiceTransactionsRow: InvoiceTransactionErrorType | undefined;
};

export type ErrorField = Record<string, FieldError>;
export type ErrorFields = Record<string, FieldError | ErrorField>;
export const convertFlashMessageTypeToInlineNotificationType = (
  flashMessageType: InvoiceReportDetailResponseFlashMessagesItemType | undefined
): InlineNotificationProps['type'] => {
  switch (flashMessageType) {
    case 'error':
      return 'error';
    case 'warning':
      return 'alert';
    case 'success':
    case 'info':
      return 'success';
    default:
      return 'error';
  }
};

export type InvoiceTransactionErrorType = CommonError & { index?: number };
