import {
  useCrItems,
  useFindCrItemById,
  useIsFetchingCrItems,
} from '@/components/CrItemsSelect/Provider';
import {
  useCrSubItems,
  useFindCrSubItemById,
  useIsFetchingCrSubItems,
} from '@/components/CrSubItemsSelect/Provider';
import {
  useCurrencies,
  useFindCurrencyById,
  useIsFetchingCurrencies,
} from '@/components/CurrenciesSelect/Provider';
import {
  useDepts,
  useFindDeptById,
  useIsFetchingDepts,
} from '@/components/DeptSelect/Provider';
import {
  useExItems,
  useFindExItemById,
  useIsFetchingExItems,
} from '@/components/ExItemSelect/Provider';
import {
  useExcises,
  useFindExciseById,
  useIsFetchingExcises,
} from '@/components/ExciseSelect/Provider';
import {
  useFindProjectById,
  useIsFetchingProject,
  useProject,
} from '@/components/ProjectSelect/Provider';
import {
  InvoiceTransactionMapping,
  exportMappings,
} from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/importExportMapping';
import { InvoiceTransaction } from '@/features/InvoiceReport/Edit/type';
import { exportsUrl, exportsWorkBook } from 'csv-excel-imp-exp-util';
import { toISODateString } from 'date-util';
import { useCallback } from 'react';

export type ExportableCommonValue = {
  dealDate: string;
  name: string;
  jpyRate: string;
  quantity: string;
  withholdingIncomeTax: string;
  memo: string;
  currency: string;
};

export type InvoiceTransactionAdditionalValue = {
  exItemName: string;
  exciseName: string;
  deptName: string;
  projectName: string;
  crItemName: string;
  crSubItemName: string;
  tax: string; // correspond to exciseValue
  unitPriceExcludingTax: string; // correspond to unit
  unitPriceIncludingTax: string; // correspond to taxIncludedUnit
  totalValueExcludingTax: string; // correspond to totalValue
  totalValueIncludingTax: string; // correspond to taxIncludedTotalValue
};

export type ExportableInvoiceTransactionType = ExportableCommonValue &
  InvoiceTransactionAdditionalValue;

const exportableCommonValue: ExportableCommonValue = {
  dealDate: '',
  name: '',
  quantity: '',
  withholdingIncomeTax: '',
  memo: '',
  currency: '',
  jpyRate: '',
};

const additionalValue: InvoiceTransactionAdditionalValue = {
  exItemName: '',
  exciseName: '',
  deptName: '',
  projectName: '',
  crItemName: '',
  crSubItemName: '',
  tax: '',
  unitPriceExcludingTax: '',
  unitPriceIncludingTax: '',
  totalValueExcludingTax: '',
  totalValueIncludingTax: '',
};

export const exportableDefaultValue: ExportableInvoiceTransactionType =
  Object.assign({}, exportableCommonValue, additionalValue);

const useConvertFieldsToExportData = () => {
  const findExcise = useFindExciseById();
  const findDept = useFindDeptById();
  const findCrItem = useFindCrItemById();
  const findCrSubItem = useFindCrSubItemById();
  const findExItem = useFindExItemById();
  const findProject = useFindProjectById();
  const findCurrency = useFindCurrencyById();

  return useCallback(
    (
      items: InvoiceTransaction[],
      isEmpty?: boolean
    ): InvoiceTransactionMapping[] => {
      if (isEmpty) {
        return [exportableDefaultValue];
      }

      return items.map((item) => {
        const excise = findExcise(item.drExciseId);
        const dept = findDept(item.deptId);
        const crItem = findCrItem(item.crItemId);
        const crSubItem = findCrSubItem(item.crSubItemId);
        const project = findProject(item.projectCodeId);
        const currency = findCurrency(item.currency);
        const exItemName = findExItem(item.exItemId);

        return {
          ...item,
          name: item.name ?? '',
          dealDate: item.dealDate ? toISODateString(item.dealDate) : '',
          exItemName: exItemName ?? '',
          quantity: item.quantity?.toString() ?? '',
          unitPriceExcludingTax: item.unit?.toString() ?? '',
          unitPriceIncludingTax: item.taxIncludedUnit.toString() ?? '',
          totalValueExcludingTax: item.totalValue?.toString() ?? '',
          totalValueIncludingTax: item.taxIncludedTotalValue.toString() ?? '',
          exciseName: excise ?? '',
          tax: item.exciseValue?.toString() ?? '',
          withholdingIncomeTax: item.withholdingIncomeTax?.toString() ?? '',
          deptName: dept ?? '',
          projectName: project ?? '',
          crItemName: crItem ?? '',
          crSubItemName: crSubItem ?? '',
          memo: item.memo ?? '',
          currency: currency ?? '',
          jpyRate: item.jpyRate?.toString() ?? '',
        } satisfies InvoiceTransactionMapping;
      });
    },
    [
      findCrItem,
      findCrSubItem,
      findCurrency,
      findDept,
      findExItem,
      findExcise,
      findProject,
    ]
  );
};

export const useExport = (
  fields: InvoiceTransaction[],
  taxInclude: boolean,
  invoiceTransactionDetails: boolean,
  isAdminOrApproval: boolean
) => {
  const convertFieldsToExportData = useConvertFieldsToExportData();
  const onExportsCsv = useCallback(
    async (isEmpty: boolean | undefined, encoding: 'utf-8' | 'sjis') => {
      const url = await exportsUrl({
        data: convertFieldsToExportData(fields, isEmpty),
        mappingObject: exportMappings(
          isAdminOrApproval,
          taxInclude,
          invoiceTransactionDetails
        ),
        format: 'csv',
        encodeType: encoding,
      });
      const a = document.createElement('a');
      a.href = url;
      a.download = '支払依頼.csv';
      a.click();
      a.remove();
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 1000);
    },
    [
      convertFieldsToExportData,
      fields,
      invoiceTransactionDetails,
      isAdminOrApproval,
      taxInclude,
    ]
  );
  const onExportsUtf8Csv = useCallback(async () => {
    onExportsCsv(false, 'utf-8');
  }, [onExportsCsv]);

  // NOTE: sjis export
  const onExportsSjisCsv = useCallback(async () => {
    onExportsCsv(false, 'sjis');
  }, [onExportsCsv]);

  const onExportsUtf8CsvOnlyHeader = useCallback(async () => {
    onExportsCsv(true, 'utf-8');
  }, [onExportsCsv]);
  const onExportsSjisCsvOnlyHeader = useCallback(async () => {
    onExportsCsv(true, 'sjis');
  }, [onExportsCsv]);
  const exportDropdown = useExportDropdownData();
  const onWrapExportsXlsx = useCallback(
    async (isEmpty?: boolean) => {
      const mapping = exportMappings(
        isAdminOrApproval,
        invoiceTransactionDetails,
        taxInclude
      );
      const wb = exportsWorkBook(
        convertFieldsToExportData(fields, isEmpty),
        mapping
      );
      exportDropdown(wb, mapping);
      const url = await exportsUrl({
        data: wb,
        format: 'xlsx',
      });
      const a = document.createElement('a');
      a.href = url;
      a.download = '支払依頼.xlsx';
      a.click();
      a.remove();
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 1000);
    },
    [
      convertFieldsToExportData,
      exportDropdown,
      fields,
      invoiceTransactionDetails,
      isAdminOrApproval,
      taxInclude,
    ]
  );

  const onExportsXlsx = useCallback(
    async () => onWrapExportsXlsx(false),
    [onWrapExportsXlsx]
  );
  const onExportsXlsxWithOnlyHeader = useCallback(
    async () => onWrapExportsXlsx(true),
    [onWrapExportsXlsx]
  );

  return {
    onExportsUtf8Csv,
    onExportsUtf8CsvOnlyHeader,

    onExportsSjisCsv,
    onExportsSjisCsvOnlyHeader,

    onExportsXlsx,
    onExportsXlsxWithOnlyHeader,
  };
};

export const useIsFetchingWithTransactions = () => {
  const exItems = useIsFetchingExItems();
  const excisesItems = useIsFetchingExcises();
  const deptItems = useIsFetchingDepts();
  const prjItems = useIsFetchingProject();
  const crItems = useIsFetchingCrItems();
  const crSubItems = useIsFetchingCrSubItems();
  const currencyItems = useIsFetchingCurrencies();
  return (
    exItems ||
    excisesItems ||
    deptItems ||
    prjItems ||
    crItems ||
    crSubItems ||
    currencyItems
  );
};

const indexToExcelColumn = (index: number): string => {
  let column = '';
  // 内部計算のため1始まりに変換
  index++;

  while (index > 0) {
    // 26進数のように計算（ただしExcelは1から始まる）
    const remainder = (index - 1) % 26;
    column = String.fromCharCode(65 + remainder) + column;
    index = Math.floor((index - 1) / 26);
  }

  return column;
};

const useExportDropdownData = () => {
  const exItems = useExItems();
  const excisesItems = useExcises();
  const deptItems = useDepts();
  const prjItem = useProject();
  const crItems = useCrItems();
  const crSubItems = useCrSubItems();
  const currencyItems = useCurrencies();
  return useCallback(
    (
      wb: ReturnType<typeof exportsWorkBook>,
      mapping: Record<string, string>
    ) => {
      const mappingKeys = Object.keys(mapping);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const items: (Record<string, any> & { name?: string; cell: string })[] = [
        { exItems, name: 'exItemName', cell: 'C' },
        { excisesItems, name: 'exciseName', cell: 'I' },
        { deptItems, name: 'deptName', cell: 'M' },
        { prjItem, name: 'projectName', cell: 'N' },
        { crItems, name: 'crItemName', cell: 'O' },
        { crSubItems, name: 'crSubItemName', cell: 'P' },
        { currencyItems, name: 'currency', cell: 'Q' },
      ];
      items.forEach((item) => {
        const idx = mappingKeys.findIndex((key) => key === item.name);
        const cell = indexToExcelColumn(idx);
        item.cell = cell;
        delete item.name;
      });
      items.forEach((item) => {
        let sheetName: string = '';
        let values: unknown[] = [];
        let cell: string = '';
        Object.entries(item).forEach(([key, value]) => {
          if (key === 'cell') {
            cell = value;
          } else {
            sheetName = key;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            values = value.map((value: any) => ({
              name: value.name ?? value.display_name ?? value.long_name,
            }));
          }
        });
        const sh = wb.addWorksheet(sheetName);
        sh.columns = [
          {
            header: '名前',
            key: 'name',
          },
        ];
        sh.addRows(values);
        sh.state = 'veryHidden';
        //@ts-ignore
        wb.getWorksheet('sheet1')?.dataValidations.add(`${cell}2:${cell}9999`, {
          type: 'list',
          allowBlank: true,
          formulae: [`${sheetName}!$A$2:$A$${values.length + 1}`],
        });
      });
    },
    [
      crItems,
      crSubItems,
      currencyItems,
      deptItems,
      exItems,
      excisesItems,
      prjItem,
    ]
  );
};
