import { ReceivedInvoiceOperationStatus } from '@/context/services/invoice/type';
import { FC, PropsWithChildren, createContext, useContext } from 'react';

const Context = createContext<ReceivedInvoiceOperationStatus | undefined>(
  undefined
);

type Props = {
  value: ReceivedInvoiceOperationStatus | undefined;
};

export const OperationStatusProvider: FC<PropsWithChildren<Props>> = ({
  value,
  children,
}) => {
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useOperationStatusChecker = () => {
  const value = useContext(Context);
  const checker = {
    // isNotStated 未着手
    isNotStated: value === 'not_started',
    // OCR実行中
    isOcrProcessing: value === 'ocr_processing',
    // 編集中
    isEdit: value === 'applying',
    // 申請済み
    isApplied: value === 'applied',
    // ゴミ箱
    isArchived: value === 'archived',
  };
  return checker;
};

export const useOperationStatus = () => {
  return useContext(Context);
};
