import { useTranslation } from '@/i18n';
import { ModalWithoutPortal } from '@moneyforward/ap-frontend-components';
import { FC, memo, useCallback, useState } from 'react';
import { Footer } from './Footer';
import { SelectedType } from './type';

type Children<T> = {
  onChange: (value: SelectedType, args?: T | undefined) => void;
};

export type Props<T> = {
  open: boolean;
  onClose: VoidFunction;
  onClick: (value: SelectedType, args: T | undefined) => void;
  children: FC<Children<T>>;
  testId?: string;
};

const InnerDialog = <T,>({
  open,
  onClose,
  onClick,
  children,
  testId,
}: Props<T>) => {
  const [otherArgs, setOtherArgs] = useState<T | undefined>(undefined);
  const [selectedValue, setSelectedValue] = useState<SelectedType | undefined>(
    undefined
  );
  const onChange = useCallback((value: SelectedType, args: T | undefined) => {
    setSelectedValue(value);
    setOtherArgs(args);
  }, []);
  const onWrapClick = useCallback(() => {
    if (selectedValue) {
      onClick(selectedValue, otherArgs);
    }
  }, [onClick, selectedValue, otherArgs]);
  const { t } = useTranslation();
  return (
    <ModalWithoutPortal
      testId={`change-${testId}`}
      open={open}
      onClose={onClose}
      footer={
        <Footer
          onCancelClick={onClose}
          onPrimaryClick={onWrapClick}
          isDisabledPrimaryButton={selectedValue === undefined}
        />
      }
      title={t('invoice_reports.change_range_selection')}
      size='md'
    >
      {children({ onChange })}
    </ModalWithoutPortal>
  );
};
InnerDialog.displayName = 'ChangeXXX';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const Dialog = memo(InnerDialog) as typeof InnerDialog;
