export const isStorybook = () => {
  return (process.env.STORY_BOOK_BUILD || '').toLowerCase() === 'true';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isStrToNumber = (value: any): boolean => {
  return (
    (typeof value === 'string' &&
      value !== '' &&
      !Number.isNaN(Number(value))) ||
    typeof value === 'number' ||
    false
  );
};
