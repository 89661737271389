import { BusinessPersonDataProvider } from '@/context/BusinessPersonData';
import { BusinessPersonData } from '@/context/services/tpm/type';
import { Route } from '@/features/InvoiceReport/Edit/type';
import { SendErrorTracking } from '@/utils/errors';
import {
  InvoiceReportDetailResponse,
  InvoiceTransactionDetail,
} from 'ap-openapi';
import { FC, PropsWithChildren, createContext, memo, useContext } from 'react';

const formContext = createContext<InvoiceReportDetailResponse | undefined>(
  undefined
);
const detailContext = createContext<InvoiceTransactionDetail[] | undefined>(
  undefined
);
const invoiceFileContext = createContext<Blob | undefined>(undefined);
const routeContext = createContext<Route>('normal');

type Props = {
  formValue: InvoiceReportDetailResponse;
  detailValue: InvoiceTransactionDetail[];
  invoiceFile: Blob | undefined;
  businessPersonData: BusinessPersonData;
  route: Route;
};

export const ApiProvider: FC<PropsWithChildren<Props>> = memo(
  ({
    children,
    formValue,
    detailValue,
    invoiceFile,
    businessPersonData,
    route,
  }) => {
    return (
      <routeContext.Provider value={route}>
        <formContext.Provider value={formValue}>
          <detailContext.Provider value={detailValue}>
            <invoiceFileContext.Provider value={invoiceFile}>
              <BusinessPersonDataProvider
                businessPersonData={businessPersonData}
              >
                {children}
              </BusinessPersonDataProvider>
            </invoiceFileContext.Provider>
          </detailContext.Provider>
        </formContext.Provider>
      </routeContext.Provider>
    );
  }
);
ApiProvider.displayName = 'ApiProvider';

export const useFormValue = () => {
  const v = useContext(formContext);
  if (v === undefined) throw new SendErrorTracking('データ未取得'); // TODO
  return v;
};

export const useDetailValue = (): InvoiceTransactionDetail[] => {
  const v = useContext(detailContext);
  return v ?? [];
};

export const useInvoiceFileValue = () => useContext(invoiceFileContext);

export const useInvoiceReportsRoute = () => useContext(routeContext);
