import { UploadFile } from '@moneyforward/ap-frontend-components';
import {
  InvoiceReportDetailFormInputsResponseInputAdditionalValuesItem,
  InvoiceReportMfFile,
  ReportBusinessDocument,
  ReportFormInputType,
  ReportSpecialExceptionStatus,
} from 'ap-openapi';

export const typeGuardReportSpecialExceptionStatus = (
  input:
    | InvoiceReportDetailFormInputsResponseInputAdditionalValuesItem
    | undefined
): input is ReportSpecialExceptionStatus => {
  if (input === undefined) return false;
  if (input === null) return false;
  return (
    'ap_special_exception_status' in input && 'address_or_location' in input
  );
};

export const typeGuardInvoiceReportMfFile = (
  value:
    | InvoiceReportDetailFormInputsResponseInputAdditionalValuesItem
    | undefined
): value is InvoiceReportMfFile => {
  if (value === undefined) return false;
  if (
    Object.hasOwn(value, 'cache_content_url') &&
    Object.hasOwn(value, 'can_delete') &&
    Object.hasOwn(value, 'id') &&
    Object.hasOwn(value, 'name')
  ) {
    return true;
  }
  return false;
};

export const typeGuardReportBusinessDocument = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any | undefined
): value is Required<ReportBusinessDocument> => {
  if (value === undefined) return false;
  if (
    Object.hasOwn(value, 'document_title') &&
    Object.hasOwn(value, 'document_number') &&
    Object.hasOwn(value, 'id') &&
    Object.hasOwn(value, 'mf_file')
  ) {
    return true;
  }
  return false;
};

export const typeGuardUploadFile = (
  value: unknown | null | undefined
): value is UploadFile | UploadFile[] => {
  if (!value) return false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hasOriginFileObj = (value: any) =>
    Object.hasOwn(value || {}, 'originFileObj') &&
    value.originFileObj !== undefined;
  return (
    (value !== undefined && hasOriginFileObj(value)) ||
    (Array.isArray(value) && hasOriginFileObj(value[0]))
  );
};

export const typeGuardUploadFileAndReportFormInputType = (
  type: ReportFormInputType | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any | undefined
): value is UploadFile | UploadFile[] => {
  if (!value || !value?.length) return false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hasOriginFileObj = (value: any) =>
    Object.hasOwn(value ?? {}, 'originFileObj') &&
    value.originFileObj !== undefined;
  return (
    (type &&
      [
        'ApReportFormInputFileField',
        'ApReportFormInputInvoiceFileField',
      ].includes(type) &&
      typeGuardUploadFile(value)) ||
    (Array.isArray(value) && hasOriginFileObj(value[0]))
  );
};
