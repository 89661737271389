import { useInputOrParseUrl } from '@/components/RelationActionMenu/hooks/useInputOrParseUrl';
import { useAgentApplicantResourceHeaders } from '@/context/AgentApplicantOfficeMember';
import {
  FetchBusinessDocumentHooks,
  FetchBusinessDocumentsHooks,
  FetchBusinessDocumentsSearchWithMfFileHooks,
} from '@/context/services/business_document/type';
import { useConvertToModernPagination } from '@/context/services/hooks/usePagination';
import { isAllSuccess, isSuccess } from '@/context/services/type';
import {
  BusinessDocumentColumn,
  BusinessDocumentColumnWithMfFile,
} from '@/features/business_documents/List/type';
import { useTranslation } from '@/i18n';
import { isCommonError } from '@/libs/typeguard/isError';
import { ModernPaginationProps } from '@moneyforward/ap-frontend-components';
import { useQueryClient } from '@tanstack/react-query';
import {
  BulkUploadBusinessDocumentsResponse,
  BusinessDocumentDetail,
  CommonError,
  UpdateBusinessDocumentErrorResponse,
  getGetBusinessDocumentQueryKey,
  useArchiveBusinessDocument,
  useBulkUploadBusinessDocument,
  useGetBusinessDocument,
  useGetBusinessDocumentSuspense,
  useRestoreBusinessDocument,
  useSearchBusinessDocuments,
  useSearchBusinessDocumentsSuspense,
  useUpdateBusinessDocument,
} from 'ap-openapi';
import { useCallback, useMemo } from 'react';

type TBulkBusinessDocumentUploadMutateAsync = ReturnType<
  typeof useBulkUploadBusinessDocument
>['mutateAsync'];

type BulkUploadBusinessDocumentMutateArgs =
  Parameters<TBulkBusinessDocumentUploadMutateAsync>['0'];

type bulkUploadBusinessDocumentSuccess = {
  status: 'success';
};
type bulkUploadBusinessDocumentError = {
  status: 'error';
  errors: BulkUploadBusinessDocumentsResponse;
};
type bulkUploadBusinessDocumentErrorResponse = {
  status: 'error_response';
  errors: string;
};

type BulkUploadBusinessDocumentResult =
  | bulkUploadBusinessDocumentSuccess
  | bulkUploadBusinessDocumentError
  | bulkUploadBusinessDocumentErrorResponse;

export const useBulkUploadBusinessDocumentForm = () => {
  const query = useQueryClient();
  const { mutateAsync: _mutateAsync } = useBulkUploadBusinessDocument({
    mutation: {
      onSuccess: () =>
        query.invalidateQueries({ queryKey: ['businessDocuments'] }),
    },
  });

  const mutateAsync = useCallback(
    async (
      data: BulkUploadBusinessDocumentMutateArgs
    ): Promise<BulkUploadBusinessDocumentResult> => {
      const resp = await _mutateAsync(data);
      const statuses =
        resp.data.results?.map((item) => (item.status || '')?.toString()) ?? [];

      if (isSuccess(resp.status) && isAllSuccess(statuses)) {
        return {
          status: 'success',
        };
      } else {
        if (isCommonError(resp.data)) {
          return {
            status: 'error_response',
            errors: resp.data?.messages?.join('\n') ?? '',
          };
        } else {
          return {
            status: 'error',
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            errors: (resp.data ?? {}) as BulkUploadBusinessDocumentsResponse,
          };
        }
      }
    },
    [_mutateAsync]
  );
  return { mutateAsync };
};

export const useBusinessDocumentsSearch: FetchBusinessDocumentsHooks = (
  args
) => {
  const headers = useAgentApplicantResourceHeaders('business_document');
  const { page = 1, perPage = 25, query } = args;

  const { data, error, refetch, isLoading } =
    useSearchBusinessDocumentsSuspense(
      {
        pagination: {
          per_page: perPage,
          page,
        },
        query: query,
      },
      {
        query: {
          queryKey: ['businessDocuments', { page, perPage, query }],
        },
        request: {
          headers,
        },
      }
    );
  const pagination: ModernPaginationProps = useConvertToModernPagination(
    data?.data?.pagination
  );

  const result = useMemo(
    () =>
      data?.data.business_documents?.map(
        responseToBusinessDocumentColumn<BusinessDocumentColumnWithMfFile>
      ) ?? [],
    [data]
  );

  return {
    pagination,
    data: result,
    refetch,
    error,
    isLoading,
  };
};

export const useBusinessDocumentSearchWithMfFile: FetchBusinessDocumentsSearchWithMfFileHooks =
  (args) => {
    if (!args.query) {
      args.query = {};
    }
    args.query.with_mf_file = true;
    const headers = useAgentApplicantResourceHeaders('business_document');
    const { page = 1, perPage = 25, query, onPageChange } = args;
    const { data, error, refetch, isLoading } = useSearchBusinessDocuments(
      {
        pagination: {
          per_page: perPage,
          page: page,
        },
        query: query,
      },
      {
        request: {
          headers,
        },
      }
    );

    const values = useMemo(() => {
      return (
        data?.data.business_documents?.map(
          responseToBusinessDocumentColumn<BusinessDocumentColumnWithMfFile>
        ) ?? []
      );
    }, [data]);

    const pagination: ModernPaginationProps = useConvertToModernPagination(
      data?.data?.pagination,
      onPageChange
    );

    return useMemo(() => {
      return {
        data: values,
        pagination,
        refetch,
        isLoading,
        isError: Boolean(error),
      };
    }, [values, pagination, refetch, isLoading, error]);
  };

const responseToBusinessDocumentColumn = <T extends BusinessDocumentColumn>(
  item: BusinessDocumentDetail
) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    id: item.id,
    fileName: item.filename,
    archived: !!item.archived,
    document_title: item.document_title,
    document_type: item.document_type,
    receipt_type: item.receipt_type,
    pics:
      item.pics?.map((pic) => ({
        id: pic.id,
        name: pic.display_name || pic.name || '',
      })) ?? [],
    counterparty_name: item.counterparty_name,
    transaction_date: item.transaction_date,
    amount: item.amount ?? null,
    uploaded_at: item.uploaded_at,
    uploader: {
      id: item.uploader?.id,
      name: item.uploader?.name || '',
    },
    memo: item.memo,
    upload_type: item.upload_type,
    automatic_status: item.automatic_status,
    timestamp_status: item.timestamp_status,
    searchable_number: item.searchable_number,
    searchId: item.id,
    mfFile: {
      id: item.mf_file?.id,
      name: item.mf_file?.name,
      contentType: item.mf_file?.content_type,
      contentByteSize: item.mf_file?.byte_size,
    },
  } as unknown as T;
};

export const useExistBusinessDocument = (businessDocumentId: string) => {
  const parseUrl = useInputOrParseUrl('/business_documents', 'id');
  const headers = useAgentApplicantResourceHeaders('business_document');
  const { t } = useTranslation();
  const searchableId = useMemo(() => {
    const v = parseUrl(businessDocumentId);
    if (v) {
      const regex = /(^[dD][oO][cC])-/;
      return v.replace(regex, '');
    }
    return '';
  }, [businessDocumentId, parseUrl]);
  const { data, error, isFetching, isRefetching, isLoading } =
    useGetBusinessDocument(searchableId, {
      query: {
        enabled: Boolean(searchableId),
        queryKey: getGetBusinessDocumentQueryKey(businessDocumentId),
      },
      request: { headers },
    });

  return useMemo(() => {
    if (!searchableId || [isLoading, isFetching, isRefetching].some((v) => v)) {
      return undefined;
    }
    if (error) {
      return {
        data: undefined,
        error: t('system_error_input'),
      };
    } else {
      let error: string | undefined = undefined;
      const status = data?.status;
      let result: BusinessDocumentColumnWithMfFile | undefined = undefined;
      switch (status) {
        case 200:
          error = undefined;
          if (data?.data) {
            if (data?.data.archived) {
              error = t('archived_business_document_resource');
            } else {
              result =
                responseToBusinessDocumentColumn<BusinessDocumentColumnWithMfFile>(
                  data.data
                );
            }
          }
          break;
        case 400:
        case 404:
          error = t('not_found_resource', {
            resource: t('business_document'),
          });
          break;
        case 403:
          error = t('forbidden_resource', {
            resource: t('business_document'),
          });
          break;
        default:
          error = t('system_error_input');
          break;
      }
      return {
        data: result,
        error,
      };
    }
  }, [
    data?.data,
    data?.status,
    error,
    searchableId,
    isFetching,
    isLoading,
    isRefetching,
    t,
  ]);
};

export const useBusinessDocument: FetchBusinessDocumentHooks = (
  businessDocumentId: string
) => {
  const headers = useAgentApplicantResourceHeaders('business_document');
  const { data, error, refetch } = useGetBusinessDocumentSuspense(
    businessDocumentId,
    {
      query: {
        queryKey: ['businessDocument', businessDocumentId],
      },
      request: {
        headers,
      },
    }
  );

  if (error) {
    throw error;
  }
  const body = data.data;
  if (data.status !== 200) {
    if (isCommonError(body)) {
      throw new Error(body.messages?.join('\n'));
    } else {
      throw new Error(data.statusText);
    }
  }
  return {
    data: data.data,
    refetch,
  };
};

type TBusinessDocumentArchiveMutateAsync = ReturnType<
  typeof useArchiveBusinessDocument
>['mutateAsync'];
type BusinessDocumentArchiveMutateArgs =
  Parameters<TBusinessDocumentArchiveMutateAsync>['0'];

export const useBusinessDocumentArchive = () => {
  const query = useQueryClient();
  const { mutateAsync: _mutateAsync, error } = useArchiveBusinessDocument({
    mutation: {
      onSuccess: () =>
        query.invalidateQueries({
          queryKey: ['businessDocuments'],
        }),
    },
  });
  const mutateAsync = useCallback(
    async (variable: BusinessDocumentArchiveMutateArgs) => {
      const resp = await _mutateAsync(variable);
      if (!isSuccess(resp.status)) throw error ? error : resp.data;
    },

    [_mutateAsync, error]
  );

  return { mutateAsync };
};

type TBusinessDocumentRestoreMutateAsync = ReturnType<
  typeof useRestoreBusinessDocument
>['mutateAsync'];
type BusinessDocumentRestoreMutateArgs =
  Parameters<TBusinessDocumentRestoreMutateAsync>['0'];

export const useBusinessDocumentRestore = () => {
  const query = useQueryClient();
  const { mutateAsync: _mutateAsync, error } = useRestoreBusinessDocument({
    mutation: {
      onSuccess: async () => {
        await Promise.all([
          query.invalidateQueries({ queryKey: ['businessDocuments'] }),
          query.invalidateQueries({ queryKey: ['businessDocument'] }),
        ]);
      },
    },
  });
  const mutateAsync = useCallback(
    async (variable: BusinessDocumentRestoreMutateArgs) => {
      const resp = await _mutateAsync(variable);
      if (!isSuccess(resp.status)) throw error ? error : resp.data;
    },

    [_mutateAsync, error]
  );

  return { mutateAsync };
};

type TUpdateBusinessDocumentMutateAsync = ReturnType<
  typeof useUpdateBusinessDocument
>['mutateAsync'];
type UpdateBusinessDocumentMutateArgs =
  Parameters<TUpdateBusinessDocumentMutateAsync>['0'];

type UpdateBusinessDocumentDetailSuccess = {
  status: 'success';
};
type UpdateBusinessDocumentDetailError = {
  status: 'invalid';
  errors: UpdateBusinessDocumentErrorResponse;
};
type UpdateBusinessDocumentDetailCommonError = {
  status: 'error';
  errors: CommonError;
};

type UpdateBusinessDocumentDetailResult =
  | UpdateBusinessDocumentDetailSuccess
  | UpdateBusinessDocumentDetailError
  | UpdateBusinessDocumentDetailCommonError;
export const useUpdateBuesinessDocumentDetail = () => {
  const query = useQueryClient();
  const { mutateAsync: _mutateAsync } = useUpdateBusinessDocument({
    mutation: {
      onSuccess: async () => {
        await Promise.all([
          query.invalidateQueries({ queryKey: ['businessDocuments'] }),
          query.invalidateQueries({ queryKey: ['businessDocument'] }),
        ]);
      },
    },
  });

  const mutateAsync = useCallback(
    async (
      args: UpdateBusinessDocumentMutateArgs
    ): Promise<UpdateBusinessDocumentDetailResult> => {
      const resp = await _mutateAsync(args);
      if (isSuccess(resp.status)) {
        return {
          status: 'success',
        };
      } else {
        if (isCommonError(resp.data)) {
          return {
            status: 'error',
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            errors: (resp.data ?? {}) as CommonError,
          };
        } else {
          return {
            status: 'invalid',
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            errors: (resp.data ?? {}) as UpdateBusinessDocumentErrorResponse,
          };
        }
      }
    },
    [_mutateAsync]
  );

  return { mutateAsync };
};
