import {
  BaseOption,
  useModelsToOptionsWithData,
} from '@/hooks/useTypeToOptions';
import { useTranslation } from '@/i18n';
import { getPageData } from '@/utils/getPageData';
import {
  FormSelect,
  FormSelectProps,
  LabelProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import { ApPayeeDetail, useGetApPayeesSuspenseInfinite } from 'ap-openapi';
import {
  KeyboardEvent,
  RefObject,
  memo,
  startTransition,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { FieldValues } from 'react-hook-form';

export type ApPayeeRef = {
  findDetails: (
    payeeId: string | undefined
  ) => Promise<ApPayeeDetail | undefined>;
};

export type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<FormSelectProps<TFieldValues>['selectProps'], 'options'>;
  query?: string;
  per?: number;
  validateMaxCount?: number;
  defaultOptions?: BaseOption[];
  include_inactive?: boolean;
  include_payee_id?: string;
  label?: LabelProps;
  instantPayeeActivatedValue?: string;
  useDisplayName?: boolean;
  inputRef?: RefObject<ApPayeeRef>;
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

const _ApPayee = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  defaultOptions: _defaultOptions,
  control,
  name,
  inputRef,
  useDisplayName = false,
  per = 25,
  include_inactive = true,
  include_payee_id,
  instantPayeeActivatedValue = '',
  ...rest
}: Props<TFieldValues>) => {
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );
  const { t } = useTranslation();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const {
    data: apiData,
    error,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useGetApPayeesSuspenseInfinite(
    {
      query,
      per: per.toString(),
      include_inactive: include_inactive.toString(),
      include_payee_id: include_payee_id,
    },
    {
      query: {
        getNextPageParam: (page) => {
          const url = new URL(page.data.next ?? '/', location.href);
          if (!url.searchParams.has('page')) return undefined;
          return url.searchParams.get('page');
        },
      },
    }
  );
  if (error) throw error;
  const data = getPageData(apiData, 'ap_payees');
  const options = useModelsToOptionsWithData<ApPayeeDetail>(
    data,
    query,
    defaultOptions,
    {
      labelKeys: ['name'],
      caption: useDisplayName ? 'display_name' : 'code',
    }
  );

  const fetchMore = useCallback(
    async (callback: () => void) => {
      if (hasNextPage) {
        await fetchNextPage();
      }
      callback();
    },
    [fetchNextPage, hasNextPage]
  );
  const onSearch = useCallback((value: string) => {
    startTransition(() => {
      setQuery(value);
    });
  }, []);
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission on Enter key press
    }
  };

  const fixedInstantPayeeOptions = useMemo(() => {
    if (!instantPayeeActivatedValue) return options;

    return [
      {
        label: t('ap_payee_input_option_label_instant_payee'),
        value: instantPayeeActivatedValue,
      },
      {
        label: (
          <div>
            <span>{t('ap_payee_input_option_title_registered_master')}</span>
            <hr />
          </div>
        ),
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        options: options as BaseOption[],
      },
    ];
  }, [options, instantPayeeActivatedValue, t]);

  const selectProps: SelectProps = useMemo(() => {
    const common = {
      onSearchDebounceWait: 600,
      comboBox: true,
      inputPlaceholder: t('ap_payee_input_placeholder'),
      ..._selectProps,
      options: fixedInstantPayeeOptions,
      fetchMore: fetchMore,
      onSearch: onSearch,
      loading: isLoading,
      onKeyPress: handleKeyPress,
    };
    if (_selectProps?.mode === 'multiple') {
      return {
        ...common,
        mode: 'multiple',
      };
    } else {
      return {
        ...common,
        mode: 'single',
        selectAll: undefined,
        selectAllLabel: undefined,
      };
    }
  }, [
    _selectProps,
    fetchMore,
    isLoading,
    onSearch,
    t,
    fixedInstantPayeeOptions,
  ]);
  useImperativeHandle(inputRef, () => ({
    findDetails: (
      payeeId: string | undefined
    ): Promise<ApPayeeDetail | undefined> => {
      return Promise.resolve(data.find((item) => item.id === payeeId));
    },
  }));

  return (
    <FormSelect<TFieldValues>
      selectProps={selectProps}
      {...rest}
      control={control}
      name={name}
    />
  );
};
_ApPayee.displayName = '_ApPayee';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApPayee = memo((props) => (
  <_ApPayee {...props} />
)) as typeof _ApPayee;
ApPayee.displayName = 'ApPayee';
