import { AmountError } from '@/components/ReportForm/AmountTablesMenu/Error/AmountError';
import { useTranslation } from '@/i18n';
import classNames from 'classnames';
import { FC, memo, useMemo } from 'react';
import { ForeignCurrency } from '../Cell';
import { Header, HeaderProps } from '../Header';
import { Line } from '../Line';
import { Row, RowProps } from '../Rows/Row';
import { Result } from '../hooks/useMinusTransaction';
import styles from './Summary.module.scss';

const cx = classNames.bind(styles);

type Amount = {
  value: number;
  foreignCurrency?: ForeignCurrency;
};

export type Props = {
  // 明細合計金額
  detailsTotalAmount: Amount;
  // 消費税額
  taxAmount: Amount;
  // 源泉徴収税額
  withholdingIncomeTaxValue: Amount;
  // 支払合計金額
  totalAmount: Amount;
  minusResult?: Result;
  hasWithholdingIncomeTax?: boolean;
};

export const Summary: FC<Props> = memo(
  ({
    detailsTotalAmount,
    taxAmount,
    withholdingIncomeTaxValue,
    totalAmount,
    minusResult,
    hasWithholdingIncomeTax,
  }) => {
    const { t } = useTranslation();
    const isDetailsError = useMemo(
      () =>
        minusResult && minusResult.error === 'detailTotalAmountAndTotalAmount',
      [minusResult]
    );
    const isTotalAmountError = useMemo(() => minusResult, [minusResult]);
    const detailsTotalAmountHeader: HeaderProps['cells'] = useMemo(
      () => [
        {
          value: t('payment_request_detail_details_total_amount_label'),
          size: 'lg',
          role: 'rowheader',
        },
        {
          value: isDetailsError ? (
            <AmountError message={minusResult?.message ?? ''}>
              <span className={cx(styles['value'])}>
                {detailsTotalAmount.value.toLocaleString()}円
              </span>
            </AmountError>
          ) : (
            detailsTotalAmount.value
          ),
          key: isDetailsError ? 'detail-total-amount-value' : undefined,
          className: cx(styles['detail-total-amount-value']),
          size: '',
          textRight: true,
          foreignCurrencyValue: detailsTotalAmount.foreignCurrency,
          role: 'cell',
          addJpy: true,
        },
      ],
      [
        detailsTotalAmount.foreignCurrency,
        detailsTotalAmount.value,
        isDetailsError,
        minusResult?.message,
        t,
      ]
    );
    const taxAmountHeader: HeaderProps['cells'] = useMemo(
      () => [
        {
          value: t('payment_request_detail_tax_amount_label'),
          className: cx(styles['tax-value-header']),
          size: '',
          kind: 'tb',
          role: 'rowheader',
        },
        {
          value: taxAmount.value,
          size: '',
          textRight: true,
          kind: 'tb',
          foreignCurrencyValue: taxAmount.foreignCurrency,
          role: 'cell',
          addJpy: true,
        },
      ],
      [t, taxAmount]
    );
    const withholdingIncomeTaxValueHeader: HeaderProps['cells'] = useMemo(
      () => [
        {
          value: t('payment_request_detail_withholding_income_tax_value_label'),
          kind: 'tb',
          size: 'lg',
          role: 'rowheader',
        },
        {
          value: withholdingIncomeTaxValue.value,
          className: cx(styles['withholding-income-tax-value']),
          size: '',
          textRight: true,
          foreignCurrencyValue: withholdingIncomeTaxValue.foreignCurrency,
          role: 'cell',
          addJpy: true,
        },
      ],
      [t, withholdingIncomeTaxValue]
    );
    const totalAmountRow: RowProps['cells'] = useMemo(
      () => [
        {
          value: t('payment_request_detail_total_amount_label'),
          kind: 'th',
          size: 'lg',
          role: 'rowheader',
        },
        {
          value: isTotalAmountError ? (
            <AmountError message={minusResult?.message ?? ''}>
              <span className={cx(styles['value'])}>
                {totalAmount.value.toLocaleString()}円
              </span>
            </AmountError>
          ) : (
            totalAmount.value
          ),
          key: 'total-amount-value',
          kind: 'th',
          size: '',
          textRight: true,
          className: cx(styles['total-amount-value']),
          foreignCurrencyValue: totalAmount.foreignCurrency,
          role: 'cell',
          addJpy: true,
        },
      ],
      [
        isTotalAmountError,
        minusResult?.message,
        t,
        totalAmount.foreignCurrency,
        totalAmount.value,
      ]
    );
    return (
      <div className={cx(styles['container'])}>
        <div className={cx(styles['content'])} role='table'>
          <Header cells={detailsTotalAmountHeader} role='row' />
          <Header
            className={cx(styles['tax-value-header'])}
            cells={taxAmountHeader}
            role='row'
          />
          {hasWithholdingIncomeTax && (
            <Header cells={withholdingIncomeTaxValueHeader} role='row' />
          )}

          <Line />
          <Row cells={totalAmountRow} />
        </div>
      </div>
    );
  }
);
Summary.displayName = 'Summary';
