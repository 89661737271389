import { useTranslation } from '@/i18n';
import {
  CaptionProps,
  Radio,
  RadioProps,
} from '@moneyforward/ap-frontend-components';
import { RadioChangeEvent } from 'antd';
import { FC, memo, useCallback, useMemo } from 'react';
import { SelectedType } from '../type';

export type Props = {
  onChange: (value: SelectedType) => void;
};

export const BookDate: FC<Props> = memo(({ onChange }) => {
  const onChangeRadio = useCallback(
    (e: RadioChangeEvent) => {
      onChange(e.target.value);
    },
    [onChange]
  );
  const { t } = useTranslation();
  const caption: CaptionProps = useMemo(
    () => ({
      type: 'helper',
      icon: false,
      children: t('invoice_reports.book_date.radio_overwrite_sub_helper'),
    }),
    [t]
  );
  const options: RadioProps['options'] = [
    {
      testId: 'bookDate-all',
      id: 'bookDate-all',
      value: 'all',
      label: t('invoice_reports.book_date.radio_overwrite_sub'),
      caption,
    },
    {
      testId: 'bookDate-only',
      id: 'bookDate-only',
      value: 'only',
      label: t('invoice_reports.book_date.radio_not_overwrite'),
    },
  ];
  return (
    <Radio
      vertical
      onChange={onChangeRadio}
      options={options}
      testId='bookDate'
    />
  );
});
BookDate.displayName = 'BookDate';
