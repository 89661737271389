import {
  BaseOption,
  useModelsToOptionsWithData,
} from '@/hooks/useTypeToOptions';
import { useTranslation } from '@/i18n';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import {
  SpecialExceptionStatus,
  useGetSpecialExceptionStatuses,
} from 'ap-openapi';
import { memo, startTransition, useCallback, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';

export type InputType = {
  anyId: string | undefined;
};

export type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<FormSelectProps<TFieldValues>['selectProps'], 'options'>;
  defaultOptions?: BaseOption[];
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

const _SpecialExceptionStatusSelect = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  defaultOptions: _defaultOptions,
  ...rest
}: Props<TFieldValues>) => {
  const { t } = useTranslation();
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );
  const [query, setQuery] = useState<string | undefined>(undefined);
  const { data: apiData } = useGetSpecialExceptionStatuses();
  const statusNone: SpecialExceptionStatus = useMemo(
    () => ({
      id: '0',
      name: '特例なし',
    }),
    []
  );
  /**
   * NOTE: Since there are no results for BE, search for FE (you should probably be able to get the whole amount at once).
   */
  const data = useMemo(
    () =>
      [statusNone, ...(apiData?.data.special_exception_statuses ?? [])].filter(
        (item) => (query ? item.name?.indexOf(query) !== -1 : true)
      ),
    [statusNone, apiData?.data.special_exception_statuses, query]
  );
  const onSearch = useCallback((value: string) => {
    startTransition(() => {
      setQuery(value);
    });
  }, []);
  const options = useModelsToOptionsWithData<SpecialExceptionStatus>(
    data,
    undefined,
    defaultOptions,
    {
      labelKeys: ['name'],
      value: 'id',
    }
  );

  const selectProps: SelectProps = useMemo(() => {
    return {
      onSearchDebounceWait: 600,
      comboBox: true,
      placeholder: t('special_exception_status_select_placeholder'),
      inputPlaceholder: t('special_exception_status_select_input_placeholder'),
      ..._selectProps,
      options: options,
      mode: 'single',
      selectAll: undefined,
      selectAllLabel: undefined,
      onSearch: onSearch,
    };
  }, [t, _selectProps, options, onSearch]);

  return <FormSelect<TFieldValues> selectProps={selectProps} {...rest} />;
};
_SpecialExceptionStatusSelect.displayName = '_SpecialExceptionStatusSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const SpecialExceptionStatusSelect = memo((props) => (
  <_SpecialExceptionStatusSelect {...props} />
)) as typeof _SpecialExceptionStatusSelect;
SpecialExceptionStatusSelect.displayName = 'SpecialExceptionStatusSelect';
