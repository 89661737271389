import { useModelsToOptionsWithData } from '@/hooks/useTypeToOptions';
import { useTranslation } from '@/i18n';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import { BankBranch as BankBranchModel, useGetBankBranches } from 'ap-openapi';
import { memo, startTransition, useCallback, useMemo, useState } from 'react';
import { FieldPath, FieldValues, useWatch } from 'react-hook-form';

type Options = {
  label: string;
  value: string;
};

type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<FormSelectProps<TFieldValues>['selectProps'], 'options'>;
  bankIdName: FieldPath<TFieldValues>;
  defaultOptions?: Options[];
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

export const _BankBranchesSelect = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  defaultOptions: _defaultOptions,
  bankIdName,
  control,
  name,
  ...rest
}: Props<TFieldValues>) => {
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );
  const { t } = useTranslation();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const bankId = useWatch({ control, name: bankIdName });
  const {
    data: apiData,
    error,
    isLoading,
  } = useGetBankBranches(
    {
      bank_id: bankId,
    },
    {
      query: {
        enabled: Boolean(bankId),
      },
    }
  );
  if (error) throw error;
  const data = useMemo(
    () => apiData?.data.bank_branches ?? [],
    [apiData?.data.bank_branches]
  );
  const options = useModelsToOptionsWithData<BankBranchModel>(
    data,
    query,
    defaultOptions,
    {
      labelKeys: ['name'],
      value: 'id',
    }
  );

  const onSearch = useCallback((value: string) => {
    startTransition(() => {
      setQuery(value);
    });
  }, []);
  const selectProps: SelectProps = useMemo(
    () => ({
      comboBox: true,
      placeholder: t('bank_branches_select_placeholder'),
      inputPlaceholder: t('bank_branches_input_placeholder'),
      ..._selectProps,
      options: options,
      loading: isLoading,
      onSearch: onSearch,
      mode: 'single',
      selectAll: undefined,
      selectAllLabel: undefined,
      disabled: !bankId,
    }),
    [t, _selectProps, options, isLoading, onSearch, bankId]
  );

  return (
    <FormSelect
      control={control}
      name={name}
      selectProps={selectProps}
      {...rest}
    />
  );
};
_BankBranchesSelect.displayName = '_BankBranchesSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const BankBranchesSelect = memo((props) => (
  <_BankBranchesSelect {...props} />
)) as typeof _BankBranchesSelect;
BankBranchesSelect.displayName = 'BankBranchesSelect';
