import { FunctionHeader as BaseFunctionHeader } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, HTMLAttributes, PropsWithChildren, ReactNode, memo } from 'react';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  functionName: string;
  button?: ReactNode;
  testId?: string;
  tag?: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const FunctionHeader: FC<PropsWithChildren<Props>> = memo(
  ({ functionName, button, testId, children, className, tag, ...rest }) => {
    return (
      <div
        className={cx(styles['container'], className)}
        data-testid={testId}
        {...rest}
      >
        <div className={cx(styles['header'])}>
          <BaseFunctionHeader
            className={cx({ [styles['header-with-tag']]: !!tag })}
            name={functionName}
            testId={`${testId}-header`}
          >
            {tag}
            {button}
          </BaseFunctionHeader>
        </div>
        {children}
      </div>
    );
  }
);
FunctionHeader.displayName = 'FunctionHeader';
