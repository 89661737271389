import { BusinessPersonData, tpmData } from '@/context/services/tpm/type';
import { isSuccess } from '@/context/services/type';
import { isStrToNumber } from '@/utils/is';
import { QueryFunction } from '@tanstack/react-query';
import type { GetTpmInvoicesParams, GetTpmInvoicesResponse } from 'ap-openapi';
import {
  useGetTpmInvoicesSuspense as useBaseGetTpmInvoicesSuspense,
  useGetTpmInvoices,
  useGetTpmInvoicesHook,
  useLazyTpmInvoice,
} from 'ap-openapi';
import { useMemo } from 'react';

export const useTpmInvoices = (
  number: string
): GetTpmInvoicesResponse | null => {
  const { data, error } = useGetTpmInvoices(
    {
      number,
    },
    {
      query: {
        enabled: Boolean(number),
      },
    }
  );
  if (error) throw error;
  const body = data?.data;
  if (data && !isSuccess(data.status)) {
    return null;
  }
  return body ?? null;
};

export const useLazyTpmInvoices = () => {
  return useLazyTpmInvoice();
};

export const useGetTpmInvoicesSuspense = (
  number: GetTpmInvoicesParams['number'] | undefined
) => {
  const params = useMemo(() => ({ number: number ?? '' }), [number]);
  const isRequiredParam =
    (number && number.length === 13 && isStrToNumber(number)) || false;
  const getTpmInvoices = useGetTpmInvoicesHook();
  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetTpmInvoicesHook>>>
  > = ({ signal }) =>
    // @ts-ignore
    isRequiredParam ? getTpmInvoices(params, {}, signal) : null;
  const { data, error } = useBaseGetTpmInvoicesSuspense(params, {
    query: {
      queryFn: queryFn,
    },
  });
  if (error) throw error;
  return useMemo(() => (data ? data : { status: 404, data: null }), [data]);
};

export const defaultBusinessPersonData: BusinessPersonData = {
  disabled: true,
  parseValidityDate: () => ({ validityStartDate: '', validityEndDate: '' }),
};

type BusinessPersonDataResponse = {
  status: number;
  data: GetTpmInvoicesResponse | null;
};

export const useConvertTpmResponseToBusinessPersonData = (
  resp: BusinessPersonDataResponse
): BusinessPersonData => {
  return useMemo(() => {
    if (!isSuccess(resp.status)) {
      return defaultBusinessPersonData;
    }
    const { data } = resp;
    const firstPeriod = data?.qualified_period?.find(
      (item) => item.label === '登録' // 英訳されたらどうなるん？
    );
    const value: BusinessPersonData = setParseValidityDate({
      name: data?.name,
      companyType: data?.kind,
      address: data?.address,
      term: firstPeriod?.period,
      disabled: false,
      parseValidityDate: defaultBusinessPersonData.parseValidityDate,
    });
    return value;
  }, [resp]);
};

export const setParseValidityDate = (
  data: BusinessPersonData
): BusinessPersonData => {
  data.parseValidityDate = parseValidityDate(data);
  return data;
};

function parseValidityDate(data: BusinessPersonData): () => tpmData {
  if (!data?.term) {
    return () => ({ validityStartDate: '', validityEndDate: '' });
  }
  const [validityStartDate = '', validityEndDate = ''] = data.term
    .toLocaleString()
    .split('〜');
  return () => ({ validityStartDate, validityEndDate });
}
