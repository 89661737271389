import {
  useCurrencies,
  useCurrenciesCacheKey,
  useCurrencyPer,
} from '@/components/CurrenciesSelect/Provider';
import { useModelsToOptionsWithData } from '@/hooks/useTypeToOptions';
import { useTranslation } from '@/i18n';
import { getPageData } from '@/utils/getPageData';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import {
  Currencies as CurrenciesModel,
  useGetCurrencyUnitsSuspenseInfinite,
} from 'ap-openapi';
import { memo, startTransition, useCallback, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';

type Options = {
  label: string;
  value: string;
};

export type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<
    FormSelectProps<TFieldValues>['selectProps'],
    'options' | 'comboBox'
  >;
  query?: string;
  per?: number;
  validateMaxCount?: number;
  defaultOptions?: Options[];
  comboBox?: boolean;
  showLabel?: boolean;
  excludeJpy?: boolean;
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

export const _CurrencyUnitSelect = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  defaultOptions: _defaultOptions,
  per = 25,
  comboBox = true,
  showLabel,
  excludeJpy,
  ...rest
}: Props<TFieldValues>) => {
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );
  const { t } = useTranslation();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const ctxPer = useCurrencyPer();
  const ctxData = useCurrencies();
  const ctxCacheKey = useCurrenciesCacheKey(query);
  const {
    data: apiData,
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useGetCurrencyUnitsSuspenseInfinite(
    {
      query,
      per: ctxPer ?? per,
      exclude_jpy: excludeJpy,
    },
    {
      query: {
        getNextPageParam: (page) => {
          const url = new URL(page.data.next ?? '/', location.href);
          if (!url.searchParams.has('page')) return undefined;
          return Number(url.searchParams.get('page'));
        },
        queryKey: ctxCacheKey,
      },
    }
  );
  if (error) throw error;
  const data = useMemo(
    () => [
      ...(!query ? ctxData : []),
      ...getPageData(apiData, 'currencies').filter((item) =>
        query ? item.name?.toLowerCase()?.includes(query.toLowerCase()) : true
      ),
    ],
    [apiData, ctxData, query]
  );
  const options = useModelsToOptionsWithData<CurrenciesModel>(
    data,
    query,
    defaultOptions,
    {
      labelKeys: ['name'],
      value: 'id',
    }
  );
  const fetchMore = useCallback(
    async (callback: () => void) => {
      if (hasNextPage) {
        await fetchNextPage();
      }
      callback();
    },
    [fetchNextPage, hasNextPage]
  );
  const onSearch = useCallback((value: string) => {
    startTransition(() => {
      setQuery(value);
    });
  }, []);
  const label = useMemo(
    () =>
      showLabel
        ? {
            text: t('label_ap_currency'),
          }
        : undefined,
    [showLabel, t]
  );
  const selectProps: SelectProps = useMemo(
    () => ({
      onSearchDebounceWait: 600,
      comboBox: comboBox,
      placeholder: t('currencies_unit_placeholder'),
      inputPlaceholder: t('currencies_unit_input_placeholder'),
      ..._selectProps,
      options: options,
      onSearch: onSearch,
      loading: isLoading,
      mode: 'single',
      fetchMore: fetchMore,
      selectAll: undefined,
      selectAllLabel: undefined,
    }),
    [_selectProps, comboBox, fetchMore, isLoading, onSearch, options, t]
  );

  return <FormSelect selectProps={selectProps} label={label} {...rest} />;
};
_CurrencyUnitSelect.displayName = '_CurrencyUnitSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const CurrenciesSelect = memo((props) => (
  <_CurrencyUnitSelect {...props} />
)) as typeof _CurrencyUnitSelect;
CurrenciesSelect.displayName = 'CurrenciesSelect';
