import { useSelectedApPayee } from '@/components/ApPayee/useChangeApPayee';
import { CrItemsSelect } from '@/components/CrItemsSelect';
import { CrSubItemsSelect } from '@/components/CrSubItemsSelect';
import { CurrenciesSelect } from '@/components/CurrenciesSelect';
import { DeptSelect } from '@/components/DeptSelect';
import { ExItemSelectWithoutSuspense } from '@/components/ExItemSelect';
import { ExciseSelect } from '@/components/ExciseSelect';
import { ProjectSelect } from '@/components/ProjectSelect';
import { useTpmDataFromBusinessPersonData } from '@/context/BusinessPersonData';
import { useGetForeignCurrencyRates } from '@/context/services/foreign_currency/Detail/ApCurrencyRates.service';
import { INVOICE_NUMBER_UNSAVED } from '@/context/services/reportsType/invoiceReports/type';
import { DealDate } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/Columns/Cells/DealDate';
import { FormInputCell } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/Columns/Cells/FormInputCell';
import { FormInputNumberCell } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/Columns/Cells/FormInputNumberCell';
import { useInvoiceReportNavigation } from '@/features/InvoiceReport/Edit/components/hooks/useGetInvoiceReportPath';
import type { InvoiceTransactionErrorType } from '@/features/InvoiceReport/Edit/components/type';
import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  FormController,
  InputNumberProps,
  Loading,
  TableProps,
} from '@moneyforward/ap-frontend-components';
import { useBooleanFlagValue } from '@openfeature/react-sdk';
import { toISODateString } from 'date-util';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Control, useFormContext } from 'react-hook-form';
import type { InvoiceTransaction, PaymentRequestForm } from '../../../type';
import { Actions } from './Actions/Actions';
import { HasWithHoldingIncomeTaxCheckBox } from './Cells/HasWithHoldingIncomeTaxCheckBox';
import { InvoiceKind } from './Cells/InvoiceKind';
import { RequiredHeader } from './Cells/RequiredHeader';
import { TransactionError } from './Cells/TransactionError';
import {
  useInvoiceTransactionHelper,
  useInvoiceTransactionSupporters,
  useValidationRules,
} from './hooks';

export type UseColumnsOption = {
  isTaxIncluded: boolean;
  canDisplayTransactionDetail: boolean;
  canDisplayDrExcise: boolean;
  isPermitMinusTransaction: boolean;
  hasWithholdingIncomeTax: boolean;
  hasSpecialException: boolean;
  isDutyFree: boolean;
  invoiceTransactionRowErrors: InvoiceTransactionErrorType | undefined;
};

type Column = TableProps<InvoiceTransaction>['columns'][number];
export type Columns = Column[];

export const useColumns = (
  control: Control<PaymentRequestForm, unknown>,
  fields: InvoiceTransaction[],
  option: UseColumnsOption | undefined = undefined
): Columns => {
  const {
    isTaxIncluded,
    canDisplayTransactionDetail,
    canDisplayDrExcise,
    isPermitMinusTransaction,
    hasWithholdingIncomeTax,
    hasSpecialException,
    isDutyFree,
    invoiceTransactionRowErrors,
  } = useMemo(
    () =>
      option ?? {
        isTaxIncluded: false,
        canDisplayTransactionDetail: true,
        canDisplayDrExcise: true,
        isPermitMinusTransaction: false,
        hasWithholdingIncomeTax: false,
        hasSpecialException: false,
        isDutyFree: false,
        invoiceTransactionRowErrors: undefined,
      },
    [option]
  );
  const isForeignCurrencyFlag = useBooleanFlagValue(
    'foreign-currency-support',
    false
  );
  const { isApproverRoleFromPath } = useInvoiceReportNavigation();
  const { t } = useTranslation();
  const containerRef = useGlobalContainerRef();
  const selectedPayee = useSelectedApPayee();

  const {
    dealDateRule,
    nameRule,
    exItemIdRule,
    quantityRule,
    unitRule,
    taxIncludedUnitRule,
    taxIncludedTotalValueRule,
    totalValueRule,
    deptIdRule,
    projectCodeIdRule,
    memoRule,
    hasWithholdingIncomeTaxRule,
    withholdingIncomeTaxRule,
    crItemIdRule,
    currencyRule,
  } = useValidationRules(
    isTaxIncluded,
    isPermitMinusTransaction,
    canDisplayTransactionDetail,
    isForeignCurrencyFlag
  );
  const tpmData = useTpmDataFromBusinessPersonData();
  const helpers = useInvoiceTransactionHelper(
    isTaxIncluded,
    canDisplayTransactionDetail,
    canDisplayDrExcise,
    hasSpecialException,
    isDutyFree,
    tpmData,
    isForeignCurrencyFlag
  );
  const { getIsZeroPer } = helpers;
  const {
    dealDateSupporter,
    drExciseIdSupporter,
    exItemIdSupporter,
    exciseValueSupporter,
    quantitySupporter,
    totalValueSupporter,
    unitSupporter,
    withholdingIncomeTaxSupporter,
    currencyValueSupporter,
    rateValueSupporter,
  } = useInvoiceTransactionSupporters(control, helpers);

  const unitFormName: { label: string; name: 'taxIncludedUnit' | 'unit' } =
    useMemo(
      () => ({
        name: isTaxIncluded ? 'taxIncludedUnit' : 'unit',
        label: isTaxIncluded
          ? t('invoice_transaction_unit_tax_include')
          : t('invoice_transaction_unit'),
      }),
      [isTaxIncluded, t]
    );
  const totalValueFormName: {
    name: 'taxIncludedTotalValue' | 'totalValue';
    label: string;
  } = useMemo(
    () => ({
      name: isTaxIncluded ? 'taxIncludedTotalValue' : 'totalValue',
      label: isTaxIncluded
        ? t('invoice_transaction_total_value_tax_include')
        : t('invoice_transaction_total_value'),
    }),
    [isTaxIncluded, t]
  );

  const rateInputProps = useMemo(
    () => ({ format: 'unit' } satisfies InputNumberProps),
    []
  );
  const baseSelectProps = useMemo(
    () => ({ getPopupContainer: containerRef }),
    [containerRef]
  );
  const getIndex = useCallback(
    (row: InvoiceTransaction) =>
      fields.findIndex((field) => field.rowKey === row.rowKey),
    [fields]
  );

  const [changedInvoiceIndex, setChangedInvoiceIndex] = useState<number>(0);
  const [changedInvoice, setChangedInvoice] = useState<InvoiceTransaction>();

  const { isLoading, data } = useGetForeignCurrencyRates({
    currency: changedInvoice?.currency,
    transactionDate: changedInvoice?.dealDate
      ? toISODateString(changedInvoice?.dealDate)
      : undefined,
    onPageChange: () => {},
  });

  const { getValues, setValue } = useFormContext<PaymentRequestForm>();

  const triggerRateValueSupporter = useCallback(
    (changedInvoice: InvoiceTransaction) => {
      if (
        isForeignCurrencyFlag &&
        changedInvoice.currency !== 'JPY' &&
        changedInvoice.dealDate
      ) {
        setChangedInvoice(changedInvoice);
        setChangedInvoiceIndex(getIndex(changedInvoice));
      }
    },
    [getIndex, isForeignCurrencyFlag]
  );
  useEffect(() => {
    if (!selectedPayee?.is_withholding_tax) {
      const invoiceTransactions = getValues('invoiceTransactions');
      const updatedTransactions = invoiceTransactions.map((transaction) => ({
        ...transaction,
        hasWithholdingIncomeTax: false,
        withholdingIncomeTax: hasWithholdingIncomeTax
          ? transaction.withholdingIncomeTax
          : 0,
      }));
      setValue('invoiceTransactions', updatedTransactions);
    }

    if (data?.[0]) {
      rateValueSupporter(changedInvoiceIndex, data[0]);
      setChangedInvoice(undefined);
    }
  }, [
    selectedPayee?.is_withholding_tax,
    getValues,
    setValue,
    hasWithholdingIncomeTax,
    data,
    isLoading,
    rateValueSupporter,
    changedInvoiceIndex,
  ]);

  const columns = useMemo(
    () =>
      [
        {
          title: t('invoice_transaction_number'),
          dataIndex: 'number',
          onCell: (r: InvoiceTransaction) => ({
            className:
              invoiceTransactionRowErrors?.index === getIndex(r)
                ? 'error-cell'
                : '',
          }),
          render(value: string, r: InvoiceTransaction) {
            const index = getIndex(r);
            const errors = invoiceTransactionRowErrors?.index === index;
            if (errors) {
              return (
                <TransactionError
                  messages={invoiceTransactionRowErrors?.messages}
                />
              );
            }
            return value === INVOICE_NUMBER_UNSAVED ? '' : value;
          },
          width: 65,
          fixed: 'left',
        } satisfies Column,
        {
          title: t('invoice_transaction_action'),
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            return <Actions index={index} />;
          },
          width: 50,
          fixed: 'left',
        } satisfies Column,
        {
          title: (
            <RequiredHeader>
              {t('invoice_transaction_deal_date')}
            </RequiredHeader>
          ),
          dataIndex: 'dealDate',
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            return (
              <DealDate<PaymentRequestForm>
                row={r}
                index={index}
                control={control}
                inputId={`dealDate${index}`}
                name={`invoiceTransactions.${index}.dealDate`}
                rules={dealDateRule(index)}
                onChangeAfter={() => {
                  triggerRateValueSupporter(r);
                  dealDateSupporter(index, hasSpecialException, tpmData);
                }}
                containerRef={containerRef}
              />
            );
          },
          resizable: true,
          width: 150,
        } satisfies Column,
        {
          title: (
            <RequiredHeader>{t('invoice_transaction_name')}</RequiredHeader>
          ),
          dataIndex: 'name',
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            return (
              <FormInputCell<PaymentRequestForm>
                control={control}
                inputId={`name${index}`}
                name={`invoiceTransactions.${index}.name`}
                rules={nameRule(index)}
                row={r}
                index={index}
              />
            );
          },
          resizable: true,
          width: 220,
        } satisfies Column,
        {
          title: t('invoice_transaction_department'),
          dataIndex: 'deptId',
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            const defaultOption =
              r.deptId && r.deptName
                ? [
                    {
                      label: r.deptName ?? '',
                      value: r.deptId ?? '',
                    },
                  ]
                : undefined;
            return (
              <DeptSelect<PaymentRequestForm>
                key={`${r.id}_${v}_${index}`}
                control={control}
                inputId={`deptId${index}`}
                name={`invoiceTransactions.${index}.deptId`}
                rules={deptIdRule(index)}
                selectProps={baseSelectProps}
                defaultOptions={defaultOption}
              />
            );
          },
          resizable: true,
          width: 220,
        } satisfies Column,
        {
          title: (
            <RequiredHeader>
              {t('invoice_transaction_expense_account')}
            </RequiredHeader>
          ),
          dataIndex: 'exItem',
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            const defaultOption =
              r.exItemId && r.exItemName
                ? [
                    {
                      value: r.exItemId ?? '',
                      label: r.exItemName ?? '',
                    },
                  ]
                : undefined;
            return (
              <ExItemSelectWithoutSuspense<PaymentRequestForm>
                key={`${r.id}_${v}_${index}`}
                control={control}
                inputId={`exItem${index}`}
                name={`invoiceTransactions.${index}.exItemId`}
                rules={exItemIdRule(index)}
                deptId={r.deptId}
                onChangeAfter={() =>
                  exItemIdSupporter(index, hasSpecialException, tpmData)
                }
                selectProps={baseSelectProps}
                defaultOptions={defaultOption}
              />
            );
          },
          resizable: true,
          width: 220,
        } satisfies Column,
        canDisplayTransactionDetail
          ? ({
              title: <RequiredHeader>{unitFormName.label}</RequiredHeader>,
              dataIndex: unitFormName.name,
              render(v, r: InvoiceTransaction) {
                const index = getIndex(r);
                return (
                  <FormInputNumberCell<PaymentRequestForm>
                    control={control}
                    row={r}
                    index={index}
                    inputId={`${unitFormName.name}${index}`}
                    rules={
                      isTaxIncluded
                        ? taxIncludedUnitRule(index)
                        : unitRule(index)
                    }
                    name={`invoiceTransactions.${index}.${unitFormName.name}`}
                    onChangeAfter={() => unitSupporter(index)}
                  />
                );
              },
              width: 120,
            } satisfies Column)
          : {},
        // 数量 // amount
        canDisplayTransactionDetail
          ? ({
              title: (
                <RequiredHeader>
                  {t('invoice_transaction_quantity')}
                </RequiredHeader>
              ),
              dataIndex: 'quantity',
              render(v, r: InvoiceTransaction) {
                const index = getIndex(r);
                return (
                  <FormInputNumberCell<PaymentRequestForm>
                    control={control}
                    row={r}
                    index={index}
                    inputId={`quantity${index}`}
                    name={`invoiceTransactions.${index}.quantity`}
                    rules={quantityRule(index)}
                    onChangeAfter={() => quantitySupporter(index)}
                  />
                );
              },
              width: 110,
            } satisfies Column)
          : {},
        {
          title: <RequiredHeader>{totalValueFormName.label}</RequiredHeader>,
          dataIndex: totalValueFormName.name,
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            return (
              <FormInputNumberCell<PaymentRequestForm>
                control={control}
                row={r}
                index={index}
                inputId={`${totalValueFormName.name}${index}`}
                rules={
                  isTaxIncluded
                    ? taxIncludedTotalValueRule(index)
                    : totalValueRule(index)
                }
                name={`invoiceTransactions.${index}.${totalValueFormName.name}`}
                onChangeAfter={() => totalValueSupporter(index)}
              />
            );
          },
          width: 120,
        } satisfies Column,
        {
          // 税区分 // Excise
          title: t('invoice_transaction_dr_excise_id'),
          dataIndex: 'drExciseId',
          render(v: string, r: InvoiceTransaction) {
            const index = getIndex(r);
            const defaultOption =
              r.drExciseId && r.drExciseName
                ? [
                    {
                      value: r.drExciseId ?? '',
                      label: r.drExciseName ?? '',
                    },
                  ]
                : undefined;
            return (
              <ExciseSelect<PaymentRequestForm>
                key={`${r.id}_${v}_${index}`}
                control={control}
                inputId={`drExciseId${index}`}
                name={`invoiceTransactions.${index}.drExciseId`}
                onChangeAfter={() => {
                  drExciseIdSupporter(
                    index,
                    undefined,
                    hasSpecialException,
                    tpmData
                  );
                }}
                selectProps={baseSelectProps}
                defaultOptions={defaultOption}
              />
            );
          },
          width: 152,
        } satisfies Column,
        {
          // インボイス経過措置
          title: isApproverRoleFromPath
            ? t('invoice_transaction_invoice_kind')
            : '',
          dataIndex: 'invoiceKind',
          render(v, r, index) {
            return isApproverRoleFromPath ? (
              <InvoiceKind
                key={`${r.id}_${v}_${index}`}
                control={control}
                inputId={`invoiceKind${index}`}
                name={`invoiceTransactions.${index}.invoiceKind`}
                isNotTarget={getIsZeroPer(r.drExciseId)}
                selectProps={baseSelectProps}
                index={index}
              />
            ) : (
              <FormController
                control={control}
                name={`invoiceTransactions.${index}.invoiceKind`}
              >
                {(...rest) => <input {...rest} hidden />}
              </FormController>
            );
          },
          width: isApproverRoleFromPath ? 152 : 0,
        } satisfies Column,
        {
          // 消費税額 // excise_value
          title: t('invoice_transaction_excise_value'),
          dataIndex: 'exciseValue',
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            return (
              <FormInputNumberCell<PaymentRequestForm>
                key={`${r.id}_${r.totalValue}_${r.drExciseId}_${r.currency}_${index}`}
                control={control}
                row={r}
                index={index}
                inputId={`exciseValue${index}`}
                name={`invoiceTransactions.${index}.exciseValue`}
                // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
                inputProps={{
                  disabled: isForeignCurrencyFlag && r?.currency !== 'JPY',
                }}
                onChangeAfter={() => exciseValueSupporter(index)}
              />
            );
          },
          width: 80,
        } satisfies Column,
        hasWithholdingIncomeTax
          ? ({
              title: t('invoice_transaction_has_withholding_income_tax'),
              dataIndex: 'hasWithholdingIncomeTax',
              render(v, r: InvoiceTransaction) {
                const index = getIndex(r);
                return (
                  <HasWithHoldingIncomeTaxCheckBox
                    onChangeAfter={() => withholdingIncomeTaxSupporter(index)}
                    key={`${r.id}_${v}_${index}`}
                    control={control}
                    inputId={`hasWithholdingIncomeTax${index}`}
                    name={`invoiceTransactions.${index}.hasWithholdingIncomeTax`}
                    label={t(
                      'invoice_transaction_withholding_income_tax_label'
                    )}
                    rules={hasWithholdingIncomeTaxRule(index)}
                    disabled={isForeignCurrencyFlag && r?.currency !== 'JPY'}
                  />
                );
              },
              width: 80,
            } satisfies Column)
          : {},
        hasWithholdingIncomeTax
          ? ({
              title: t('invoice_transaction_withholding_income_tax_amount'),
              dataIndex: 'withholdingIncomeTax',
              render(v, r: InvoiceTransaction) {
                const index = getIndex(r);
                const invoiceTransactions = getValues('invoiceTransactions');
                const updatedTransaction = invoiceTransactions[index];

                return (
                  <FormInputNumberCell<PaymentRequestForm>
                    key={`${r.id}_${r.totalValue}_${r.hasWithholdingIncomeTax}_${r.currency}_${index}`}
                    control={control}
                    row={r}
                    index={index}
                    inputId={`withholdingIncomeTax${index}`}
                    name={`invoiceTransactions.${index}.withholdingIncomeTax`}
                    // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
                    inputProps={{
                      disabled:
                        !updatedTransaction?.hasWithholdingIncomeTax ||
                        (isForeignCurrencyFlag && r?.currency !== 'JPY'),
                    }}
                    rules={withholdingIncomeTaxRule(index)}
                  />
                );
              },
              resizable: true,
              width: 120,
            } satisfies Column)
          : {},
        canDisplayTransactionDetail
          ? ({
              title: t('invoice_transaction_memo'),
              dataIndex: 'memo',
              render(v, r: InvoiceTransaction) {
                const index = getIndex(r);
                return (
                  <FormInputCell<PaymentRequestForm>
                    control={control}
                    row={r}
                    index={index}
                    inputId={`memo${index}`}
                    name={`invoiceTransactions.${index}.memo`}
                    rules={memoRule(index)}
                  />
                );
              },
              resizable: true,
              width: 152,
            } satisfies Column)
          : {},
        {
          title: t('invoice_transaction_project'),
          dataIndex: 'projectCodeId',
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            const defaultOption =
              r.projectCodeId && r.projectCodeName
                ? [
                    {
                      value: r.projectCodeId ?? '',
                      label: r.projectCodeName ?? '',
                    },
                  ]
                : undefined;

            return (
              <ProjectSelect<PaymentRequestForm>
                key={`${r.id}_${v}_${index}`}
                control={control}
                inputId={`projectCodeId${index}`}
                name={`invoiceTransactions.${index}.projectCodeId`}
                rules={projectCodeIdRule(index)}
                selectProps={baseSelectProps}
                defaultOptions={defaultOption}
              />
            );
          },
          resizable: true,
          width: 220,
        } satisfies Column,
        {
          title: (
            <RequiredHeader>
              {t('invoice_transaction_credit_account')}
            </RequiredHeader>
          ),
          dataIndex: 'crItemId',
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            const defaultOption =
              r.crItemId && r.crItemName
                ? [
                    {
                      value: r.crItemId ?? '',
                      label: r.crItemName ?? '',
                    },
                  ]
                : undefined;
            return (
              <CrItemsSelect<PaymentRequestForm>
                key={`${r.id}_${v}_${index}`}
                control={control}
                inputId={`crItemId${index}`}
                name={`invoiceTransactions.${index}.crItemId`}
                rules={crItemIdRule(index)}
                selectProps={baseSelectProps}
                defaultOptions={defaultOption}
              />
            );
          },
          resizable: true,
          width: 150,
        } satisfies Column,
        {
          title: t('invoice_transaction_credit_sub_account'),
          dataIndex: 'crSubItemId',
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            const defaultOption =
              r.crSubItemId && r.crSubItemName
                ? [
                    {
                      value: r.crSubItemId ?? '',
                      label: r.crSubItemName ?? '',
                    },
                  ]
                : undefined;
            return (
              <CrSubItemsSelect<PaymentRequestForm>
                key={`${r.id}_${v}_${index}`}
                control={control}
                inputId={`crSubItemId${index}`}
                name={`invoiceTransactions.${index}.crSubItemId`}
                crItemId={r.crItemId}
                selectProps={baseSelectProps}
                defaultOptions={defaultOption}
              />
            );
          },
          resizable: true,
          width: 150,
        } satisfies Column,
        {
          title: t('invoice_transaction_currency'),
          dataIndex: 'currency',
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            const defaultOption = r.currency
              ? [
                  {
                    value: r.currency ?? '',
                    label: r.currency ?? '',
                  },
                ]
              : undefined;

            return (
              <CurrenciesSelect<PaymentRequestForm>
                key={`${r.id}_${v}_${index}`}
                control={control}
                inputId={`currency${index}`}
                name={`invoiceTransactions.${index}.currency`}
                rules={currencyRule(index)}
                selectProps={baseSelectProps}
                defaultOptions={defaultOption}
                onChangeAfter={() => {
                  triggerRateValueSupporter(r);
                  currencyValueSupporter(index);
                }}
              />
            );
          },
          resizable: true,
          width: 120,
        } satisfies Column,
        {
          // レード // jpyRate
          title: t('invoice_transaction_jpyRate'),
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            return isLoading && changedInvoiceIndex === index ? (
              <Loading size='small' />
            ) : (
              <FormInputNumberCell<PaymentRequestForm>
                control={control}
                row={r}
                index={index}
                inputId={`jpyRate${index}`}
                name={`invoiceTransactions.${index}.jpyRate`}
                inputProps={rateInputProps}
              />
            );
          },
          width: 128,
        } satisfies Column,
      ].filter((item: Column | {}): item is Column => 'title' in item),
    [
      t,
      canDisplayTransactionDetail,
      unitFormName.label,
      unitFormName.name,
      totalValueFormName.label,
      totalValueFormName.name,
      isApproverRoleFromPath,
      hasWithholdingIncomeTax,
      invoiceTransactionRowErrors?.index,
      invoiceTransactionRowErrors?.messages,
      getIndex,
      control,
      dealDateRule,
      containerRef,
      triggerRateValueSupporter,
      dealDateSupporter,
      nameRule,
      deptIdRule,
      baseSelectProps,
      exItemIdRule,
      exItemIdSupporter,
      isTaxIncluded,
      taxIncludedUnitRule,
      unitRule,
      unitSupporter,
      quantityRule,
      quantitySupporter,
      taxIncludedTotalValueRule,
      totalValueRule,
      totalValueSupporter,
      drExciseIdSupporter,
      getIsZeroPer,
      isForeignCurrencyFlag,
      exciseValueSupporter,
      hasWithholdingIncomeTaxRule,
      withholdingIncomeTaxSupporter,
      getValues,
      withholdingIncomeTaxRule,
      memoRule,
      projectCodeIdRule,
      crItemIdRule,
      currencyRule,
      currencyValueSupporter,
      isLoading,
      changedInvoiceIndex,
      rateInputProps,
      hasSpecialException,
      tpmData,
    ]
  );

  return columns;
};
