import { CustomHeaders } from 'ap-openapi/mutator/custom-instance';
import {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useContext,
  useMemo,
} from 'react';

const Resources = [
  'received_invoices',
  'business_document',
  'ap_payee',
  'mf_file',
] as const;

type TResources = (typeof Resources)[number];

type ResourceMap = {
  [key in TResources]: boolean;
};

type TContext = {
  officeMember?: string;
} & Partial<ResourceMap>;

const context = createContext<TContext>({});

type Props = TContext;

export const AgentApplicantOfficeMemberProvider: FC<PropsWithChildren<Props>> =
  memo(({ children, officeMember, ...rest }) => {
    const { officeMember: parentOfficeMember } = useContext(context);
    const wrapOfficeMember = useMemo(
      () => officeMember || parentOfficeMember || undefined,
      [officeMember, parentOfficeMember]
    );
    const memorizedRest = useMemo(
      () => ({ ...rest, officeMember: wrapOfficeMember }),
      [rest, wrapOfficeMember]
    );
    return (
      <context.Provider value={memorizedRest}>{children}</context.Provider>
    );
  });
AgentApplicantOfficeMemberProvider.displayName =
  'AgentApplicantOfficeMemberProvider';

export const useAgentApplicantOfficeMemberId = () => {
  const value = useContext(context);
  return useMemo(() => value.officeMember, [value.officeMember]);
};

export const useAgentApplicantResourceHeaders = (
  resource: TResources
): CustomHeaders => {
  const ctx = useContext(context);
  return useMemo(() => {
    const headers: CustomHeaders = {};
    if (ctx[resource]) {
      headers.OfficeMemberId = ctx.officeMember;
    }
    return headers;
  }, [ctx, resource]);
};
