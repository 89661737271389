import classNames from 'classnames';
import { FC, memo, PropsWithChildren } from 'react';
import styles from './Container.module.scss';

const cx = classNames.bind(styles);

export const Container: FC<PropsWithChildren> = memo(({ children }) => {
  return <div className={cx(styles['container'])}>{children}</div>;
});
Container.displayName = 'ForeignCurrencyContainer';
