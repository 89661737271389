import { FC, memo } from 'react';

export const IconUndo: FC = memo(() => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Undo'>
      <path
        id='Vector'
        d='M8.15535 6.59259C6.60186 6.59259 5.19492 7.17926 4.11041 8.13333L2 6V11.3333H7.27601L5.15388 9.18815C5.96873 8.50074 7.00635 8.07407 8.15535 8.07407C10.2306 8.07407 11.9951 9.44296 12.6107 11.3333L14 10.8711C13.1851 8.38815 10.8813 6.59259 8.15535 6.59259Z'
        fill='#0054AC'
      />
    </g>
  </svg>
));
IconUndo.displayName = 'IconUndo';
