import { OperationStatusLabel } from '@/context/services/invoice/type';
import {
  useOperationStatus,
  useOperationStatusChecker,
} from '@/features/invoices/components/Details/Components/Context/OperationStatus';
import { useGetFlags } from '@/hooks/useFeatureFlag';
import { Tag, TagProps } from '@moneyforward/ap-frontend-components';
import cx from 'classnames';
import { FC, memo, useMemo } from 'react';
import styles from '../columns.module.scss';

const InnerFileNameCell: FC<{
  name: string;
  ext: string | null | undefined;
}> = ({ name, ext }) => {
  const operationChecker = useOperationStatusChecker();
  const operationStatus = useOperationStatus();
  const flagEnhanceInvoiceListSearch = useGetFlags(
    'ENHANCE_INVOICE_LIST_SEARCH'
  );

  const statusTagInfo = useMemo(() => {
    if (!operationChecker || !operationStatus) return null;

    const status: TagProps['status'] = operationChecker.isOcrProcessing
      ? 'success'
      : 'default';
    const label = OperationStatusLabel[operationStatus] || '';

    return { status, label };
  }, [operationStatus, operationChecker]);

  return (
    <>
      <div className={cx(styles['fileNameContainer'])}>
        <div className={cx(styles['fileName'])}>{name}</div>
        {ext && <div className={cx(styles['fileExtension'])}>.{ext}</div>}
      </div>
      {statusTagInfo && flagEnhanceInvoiceListSearch && (
        <Tag
          status={statusTagInfo.status}
          closable={false}
          outline={false}
          icon={false}
          className={cx(styles['statusTag'])}
        >
          {statusTagInfo.label}
        </Tag>
      )}
    </>
  );
};

InnerFileNameCell.displayName = 'InnerFileNameCell';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const FileNameCell = memo((props) => (
  <InnerFileNameCell {...props} />
)) as typeof InnerFileNameCell;
FileNameCell.displayName = 'FileNameCell';
