import { FileNameSeparatorWithExtension } from '@/components/FileNameSeparatorWithExtension';
import { EDocMetadataProps } from '@/features/InvoiceReport/Components/EDocMetadata';
import {
  ElectronicTag,
  TimestampTag,
  type Status,
} from '@/features/InvoiceReport/Components/Tags';
import classNames from 'classnames';
import { FC, memo } from 'react';
import { Details } from './Details';
import styles from './Header.module.scss';

const cx = classNames.bind(styles);

type Scanner = Status;

export type Props = {
  title?: string;
  timestamp?: boolean;
  scanner?: Scanner;
  isEmpty?: boolean;
  eDocMeta?: EDocMetadataProps;
  zoom?: boolean;
};

export const Header: FC<Props> = memo(
  ({ title, timestamp, scanner, isEmpty, eDocMeta, zoom }) => {
    return (
      <div
        className={cx(styles['container'], {
          [styles['non-attachment']]: isEmpty,
        })}
      >
        {title && (
          <FileNameSeparatorWithExtension
            containerClassName={cx(styles['title'])}
          >
            {title}
          </FileNameSeparatorWithExtension>
        )}
        {!zoom && (
          <div className={cx(styles['e-doc-container'])}>
            {timestamp && <TimestampTag />}
            {scanner && !isEmpty && <ElectronicTag status={scanner} />}
            {eDocMeta && <Details {...eDocMeta} />}
          </div>
        )}
      </div>
    );
  }
);
Header.displayName = 'Header';
