import { useSelectedApPayee } from '@/components/ApPayee/useChangeApPayee';
import { useExcises } from '@/components/ExciseSelect/Provider';
import { TotalAmountPaid, TotalAmountPaidProps } from '@/components/ReportForm';
import { useIsLoadingWithAllApi } from '@/features/InvoiceReport/Edit/components/Context/ComponentAPI';
import { useCalcSummary } from '@/features/InvoiceReport/Edit/components/hooks/useCalcSummary';
import type { InvoiceTransactionErrorType } from '@/features/InvoiceReport/Edit/components/type';
import { PaymentRequestForm } from '@/features/InvoiceReport/Edit/type';
import { useDisplayInlineNotification } from '@/hooks/useDisplayInlineNotification';
import {
  IconInfo,
  InlineNotification,
  useWindowResize,
} from '@moneyforward/ap-frontend-components';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import classnames from 'classnames/bind';
import { Dayjs } from 'dayjs';
import parser from 'html-react-parser';
import {
  Dispatch,
  ForwardedRef,
  RefObject,
  SetStateAction,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  InvoiceTransactionHeader,
  InvoiceTransactionTable,
  Props as InvoiceTransactionTableProps,
} from './InvoiceTransaction';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

type DetailsProps = Omit<
  InvoiceTransactionTableProps,
  'yScroll' | 'maxHeight' | 'hasWithholdingIncomeTax'
> & {
  isDetailMinimum: boolean;
  setIsDetailMinimum: Dispatch<SetStateAction<boolean>>;
  isDetailZoom: boolean;
  setIsDetailZoom: Dispatch<SetStateAction<boolean>>;
  WindowIcon: JSX.Element;
  viewerContainerRef: RefObject<HTMLDivElement>;
  onZoom?: (value: boolean) => Promise<void>;
  bookDate: Dayjs | null;
  isPermitMinusTransaction: boolean;
  includeTax: boolean;
  onChangeIncludeTax: (e: CheckboxChangeEvent) => void;
  showColumnDetails: boolean;
  onChangeShowColumnDetails: (e: CheckboxChangeEvent) => void;
  invoiceTransactionErrors?: string;
  invoiceTransactionAlert?: string;
  invoiceTransactionRowErrors?: InvoiceTransactionErrorType;
};

const InnerDetails = forwardRef<HTMLDivElement, DetailsProps>(
  (
    {
      control,
      fields,
      append,
      remove,
      replace,
      insert,
      isDetailMinimum,
      setIsDetailMinimum,
      isDetailZoom,
      setIsDetailZoom,
      WindowIcon,
      viewerContainerRef,
      onZoom,
      bookDate,
      isPermitMinusTransaction,
      includeTax,
      onChangeIncludeTax,
      showColumnDetails,
      onChangeShowColumnDetails,
      canDisplayDrExcise,
      invoiceTransactionErrors,
      invoiceTransactionAlert,
      isDutyFree,
      virtualContainerRef,
      rowVirtualizer,
      invoiceTransactionRowErrors,
    },
    ref
  ) => {
    const isLoading = useIsLoadingWithAllApi();
    const exciseList = useExcises();
    const invoiceTransactions = useWatch<
      PaymentRequestForm,
      'invoiceTransactions'
    >({ control, name: 'invoiceTransactions' });
    const summaryAmount = useCalcSummary(invoiceTransactions, exciseList);
    const headerRef = useRef<HTMLDivElement>(null);
    const totalAmountPaidContainerRef = useRef<HTMLDivElement>(null);
    const [totalAmountPaidOpen, setTotalAmountPaidOpen] = useState(false);
    const onWrapZoom = useCallback(() => {
      setIsDetailZoom((prev) => {
        const returnValue = !prev;
        onZoom?.(returnValue);
        if (returnValue) {
          setIsDetailMinimum(false);
        }
        return returnValue;
      });
    }, [onZoom, setIsDetailMinimum, setIsDetailZoom]);
    const onMinimum = useCallback(() => {
      setIsDetailMinimum((prev) => {
        const returnValue = !prev;
        if (returnValue) {
          setIsDetailZoom(false);
          onZoom?.(false);
        }
        return !prev;
      });
    }, [onZoom, setIsDetailMinimum, setIsDetailZoom]);
    const { watch } = useFormContext<PaymentRequestForm>();
    const selectedPayee = useSelectedApPayee();
    const watchFields = watch('invoiceTransactions');
    const hasWithholdingIncomeTax =
      selectedPayee?.is_withholding_tax ||
      watchFields.some((field) => field.hasWithholdingIncomeTax);

    const memorizedTotalAmountPaidProps: TotalAmountPaidProps = useMemo(() => {
      if (isLoading) {
        return { isLoading: true };
      }
      return {
        ...summaryAmount,
        hasWithholdingIncomeTax,
        mode: isDetailMinimum ? 'small' : 'default',
        isPermitMinusTransaction,
        foreignCurrency: summaryAmount.summary.totalAmount.foreignCurrency,
      };
    }, [
      isDetailMinimum,
      isLoading,
      isPermitMinusTransaction,
      summaryAmount,
      hasWithholdingIncomeTax,
    ]);
    const { innerHeight } = useWindowResize();
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const bottomMargin = 0;
    const [tableContainerHeight, setTableContainerHeight] = useState(0);
    useEffect(() => {
      setTableContainerHeight(
        innerHeight -
          (tableContainerRef.current?.getBoundingClientRect().top ?? 0) -
          bottomMargin
      );
    }, [innerHeight, isDetailZoom, totalAmountPaidOpen]);
    const { visible, setVisible } = useDisplayInlineNotification(
      invoiceTransactionErrors
    );
    const { visible: alertVisible, setVisible: setAlertVisible } =
      useDisplayInlineNotification(invoiceTransactionAlert);

    return (
      <div
        className={cx({
          [styles['detail']]: !isDetailZoom,
          [styles['detail-zoom']]: isDetailZoom,
        })}
        ref={ref}
      >
        <InvoiceTransactionHeader
          ref={headerRef}
          zoom={isDetailZoom}
          onZoom={onWrapZoom}
          onMinimum={onMinimum}
          isMinimized={isDetailMinimum}
          Icon={WindowIcon}
        />
        {!!invoiceTransactionErrors && (
          <div className={cx(styles['errors-invoice-transaction'])}>
            <InlineNotification
              type='error'
              message={parser(invoiceTransactionErrors)}
              icon={<IconInfo size={19} />}
              visible={visible}
              onClick={() => setVisible(false)}
            />
          </div>
        )}
        {!!invoiceTransactionAlert && (
          <div className={cx(styles['errors-invoice-transaction'])}>
            <InlineNotification
              type='alert'
              message={parser(invoiceTransactionAlert)}
              icon={<IconInfo size={19} />}
              visible={alertVisible}
              onClick={() => setAlertVisible(false)}
            />
          </div>
        )}
        <div className={cx(styles['pdf-viewer-area'])} ref={viewerContainerRef}>
          <div className={cx(styles['invoice-transaction-container'])}>
            <div
              className={cx(styles['total-amount-paid-container'])}
              ref={totalAmountPaidContainerRef}
            >
              <TotalAmountPaid
                {...memorizedTotalAmountPaidProps}
                onOpenChange={setTotalAmountPaidOpen}
              />
            </div>
            <div
              ref={tableContainerRef}
              className={cx({ [styles['minimum']]: isDetailMinimum })}
              /* eslint-disable-next-line @moneyforward/account-payable/literals-in-props */
              style={{
                width: '100%',
                maxHeight: isDetailZoom ? tableContainerHeight : undefined,
              }}
            >
              <InvoiceTransactionTable
                control={control}
                fields={fields}
                append={append}
                remove={remove}
                replace={replace}
                insert={insert}
                yScroll={!isDetailZoom && !isDetailMinimum}
                maxHeight={tableContainerHeight - 28 - 8 - 31}
                bookDate={bookDate}
                isPermitMinusTransaction={isPermitMinusTransaction}
                includeTax={includeTax}
                onChangeIncludeTax={onChangeIncludeTax}
                showColumnDetails={showColumnDetails}
                onChangeShowColumnDetails={onChangeShowColumnDetails}
                canDisplayDrExcise={canDisplayDrExcise}
                virtualContainerRef={virtualContainerRef}
                rowVirtualizer={rowVirtualizer}
                hasWithholdingIncomeTax={hasWithholdingIncomeTax}
                isDutyFree={isDutyFree}
                invoiceTransactionRowErrors={invoiceTransactionRowErrors}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
InnerDetails.displayName = 'InnerDetails';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const Details = memo(InnerDetails) as (
  props: DetailsProps & { ref?: ForwardedRef<DetailsProps> }
) => ReturnType<typeof InnerDetails>;
