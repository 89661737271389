import { FunctionHeader } from '@/components/FunctionHeader';
import { ListViewBody, ListViewLabel } from '@/components/ListView';
import { ReceivedInvoiceDetail } from '@/context/services/invoice/type';
import { useTpmInvoices } from '@/context/services/tpm/hooks';
import { useGetFlags } from '@/hooks/useFeatureFlag';
import { useTranslation } from '@/i18n';
import { isNumber } from '@/libs/typeguard/number';
import {
  Bottom,
  DATE_FORMAT,
  ListView,
  RegistrationBusinessPersonDataPopover,
  Tag,
  Top,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import dayjs from 'dayjs';
import { FC, memo, useMemo } from 'react';
import styles from './OCRResult.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  data: ReceivedInvoiceDetail;
};

export const OCRResult: FC<Props> = memo(({ data }) => {
  const { t } = useTranslation();
  const tpmData = useTpmInvoices(data.registration_number || '');
  const flagEnhanceInvoiceListSearch = useGetFlags(
    'ENHANCE_INVOICE_LIST_SEARCH'
  );
  const dueDate = useMemo(() => {
    if (data.due_date === null || typeof data.due_date === 'undefined')
      return '';
    return dayjs(data.due_date).format(DATE_FORMAT);
  }, [data.due_date]);
  const totalAmount = useMemo(() => {
    if (isNumber(data.total_amount)) {
      return `${parseInt(data.total_amount!).toLocaleString()}円`;
    }
    return '';
  }, [data.total_amount]);

  const ocrTag = useMemo(() => {
    if (!flagEnhanceInvoiceListSearch || data.upload_type === 'peppol_gateway')
      return null;
    return (
      <Tag closable={false} outline={false} icon={false}>
        {t('invoice_ocr_read_tag')}
      </Tag>
    );
  }, [data.upload_type, t, flagEnhanceInvoiceListSearch]);

  return (
    <FunctionHeader
      functionName={t('billing_information')}
      tag={ocrTag}
      testId='ocr-header'
    >
      <div className={cx(styles['contentContainer'])}>
        <ListView>
          <Top>
            <ListViewLabel key='left' testId='ocr-payee-name'>
              {t('取引先名')}
            </ListViewLabel>
            <ListViewBody key='right'>{data.ocr_payee_name ?? ''}</ListViewBody>
          </Top>
          <Top>
            <ListViewLabel key='left' testId='due-date'>
              {t('支払期日')}
            </ListViewLabel>
            <ListViewBody key='right'>{dueDate}</ListViewBody>
          </Top>
          <Top>
            <ListViewLabel key='left' testId='total-amount'>
              {t('請求書金額')}
            </ListViewLabel>
            <ListViewBody key='right'>{totalAmount}</ListViewBody>
          </Top>
          <Bottom>
            <ListViewLabel key='left' testId='registration-number'>
              <p>適格請求書発行事業者登録番号</p>
            </ListViewLabel>
            <ListViewBody key='right'>
              <div className={cx(styles.registrationNumberContent)}>
                {data.registration_number && (
                  <>
                    {`T${data.registration_number}`}
                    <RegistrationBusinessPersonDataPopover
                      disabled={!tpmData}
                      name={tpmData?.name}
                      companyType={tpmData?.kind}
                      term={tpmData?.qualified_period?.[0]?.period}
                      address={tpmData?.address}
                      // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
                      termLabelSettings={{
                        label: t('registration_business_person_data_term'),
                      }}
                      // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
                      companyTypeLabelSettings={{
                        label: t(
                          'registration_business_person_data_company_type'
                        ),
                      }}
                    />
                  </>
                )}
              </div>
            </ListViewBody>
          </Bottom>
        </ListView>
      </div>
    </FunctionHeader>
  );
});
OCRResult.displayName = 'OCRResult';
