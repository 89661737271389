import { CurrencyRate } from '@/context/services/foreign_currency/Detail/ApCurrencyRates.service';
import { tpmData } from '@/context/services/tpm/type';
import { useFloorOnAbsolute } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/hooks';
import { useReportForm } from '@/features/InvoiceReport/Edit/components/hooks/hooks';
import { ApCurrencyRate } from 'ap-openapi';
import { Big } from 'big.js';
import { useCallback } from 'react';
import type { InvoiceTransaction } from '../../../../type';
import { useGetExItem } from '../../hooks/useGetExItem';
import { useHasWithHoldingIncomeTax } from '../../hooks/useHasWithHoldingIncomeTax';
import { useSelectorExcise } from '../../hooks/useSelectorExcise';

type UpdateInvoiceTransaction = (
  invoiceTransaction: InvoiceTransaction
) => InvoiceTransaction;

type UpdateInvoiceKindAndInvoiceTransaction = (
  invoice: InvoiceTransaction,
  dynamicalHasSpecialException?: boolean,
  dynamicalTpmData?: tpmData
) => InvoiceTransaction;

export type InvoiceTransactionHelper = {
  getIsZeroPer: (exciseId: string | undefined) => boolean;
  dealDateHelper: UpdateInvoiceKindAndInvoiceTransaction;
  drExciseIdHelper: (
    invoice: InvoiceTransaction,
    value?: string,
    dynamicalHasSpecialException?: boolean,
    dynamicalTpmData?: tpmData
  ) => InvoiceTransaction;
  exciseValueHelper: UpdateInvoiceTransaction;
  exItemIdHelper: UpdateInvoiceKindAndInvoiceTransaction;
  quantityHelper: UpdateInvoiceTransaction;
  totalValueHelper: UpdateInvoiceTransaction;
  unitHelper: UpdateInvoiceTransaction;
  withholdingIncomeTaxHelper: UpdateInvoiceTransaction;
  currencyValueHelper: UpdateInvoiceTransaction;
  rateValueHelper: (
    invoiceTransaction: InvoiceTransaction,
    rate?: CurrencyRate
  ) => InvoiceTransaction;
};

/*
 * isTaxIncluded, canDisplayTransactionDetail, hasSpecialException, tpmDataは
 * ユーザーの操作で動的に変わる値なのでそれぞれのsupporterは引数として受け取れるようにする
 * isTaxIncluded, canDisplayTransactionDetail, hasSpecialException and tpmData are values that change dynamically
 * with user interaction, so the respective supporter should accept them as arguments.
 */

export const useInvoiceTransactionHelper = (
  isTaxIncluded: boolean,
  canDisplayTransactionDetail: boolean,
  canDisplayDrExcise: boolean,
  hasSpecialException: boolean,
  isDutyFree: boolean,
  tpmData: tpmData,
  isForeignCurrencyFlag?: boolean
): InvoiceTransactionHelper => {
  const floorOnAbsolute = useFloorOnAbsolute();
  // SEE: https://github.com/moneyforward/ex_web/blob/release/client/stores/invoice_reports/InvoiceTransactionInputSupporter/supporter/module.ts
  const { updateInvoiceKind } = useReportForm();
  const { getExciseRate, getIsZeroPer } = useSelectorExcise();
  const updateExciseValue = useCallback(
    (invoice: InvoiceTransaction, value: string | undefined = undefined) => {
      const drExciseId = value ?? invoice.drExciseId;
      const exciseRate = getExciseRate(drExciseId);
      const isForeignCurrency = invoice.currency !== 'JPY';
      let exciseValue: number;
      if (
        exciseRate === undefined ||
        !canDisplayDrExcise ||
        (isForeignCurrencyFlag && isForeignCurrency)
      ) {
        exciseValue = 0;
      } else {
        if (isTaxIncluded) {
          const taxIncludedTotalValue = invoice.taxIncludedTotalValue ?? 0;
          // taxIncludedTotalValue - taxIncludedTotalValue / (1 + exciseRate)
          exciseValue = Number(
            Big(taxIncludedTotalValue).minus(
              Big(taxIncludedTotalValue).div(Big(1).add(exciseRate))
            )
          );
        } else {
          const totalValue = invoice.totalValue ?? 0;
          exciseValue = Number(Big(totalValue).mul(exciseRate));
        }
      }
      invoice.exciseValue = floorOnAbsolute(exciseValue);
      return invoice;
    },
    [
      canDisplayDrExcise,
      floorOnAbsolute,
      getExciseRate,
      isForeignCurrencyFlag,
      isTaxIncluded,
    ]
  );
  const { getDrExciseIdFromExItem } = useGetExItem();
  const updateDrExciseId = useCallback(
    (
      invoice: InvoiceTransaction,
      dynamicalHasSpecialException?: boolean,
      dynamicalTpmData?: tpmData
    ) => {
      const exItemsId = invoice.exItemId;
      const drExciseId = getDrExciseIdFromExItem(exItemsId);
      invoice.drExciseId = drExciseId;
      invoice = updateExciseValue(invoice);

      // Update InvoiceKind because exciseId is changed
      invoice = updateInvoiceKind(
        invoice,
        dynamicalHasSpecialException || hasSpecialException,
        isDutyFree,
        dynamicalTpmData || tpmData
      );
      return invoice;
    },
    [
      getDrExciseIdFromExItem,
      updateExciseValue,
      updateInvoiceKind,
      hasSpecialException,
      isDutyFree,
      tpmData,
    ]
  );
  const { getWithholdingIncomeTax } = useHasWithHoldingIncomeTax();
  const updateTotalValue = useCallback(
    (invoice: InvoiceTransaction) => {
      const isCurrencyJPY = invoice.currency === 'JPY';
      const quantity = invoice.quantity ?? 0;
      if (isTaxIncluded) {
        const unit = invoice.taxIncludedUnit ?? 0;
        if (isCurrencyJPY || !isForeignCurrencyFlag) {
          invoice.taxIncludedTotalValue = Math.round(
            Number(Big(unit).mul(quantity))
          );
        } else {
          invoice.taxIncludedTotalValue = Number(Big(unit).mul(quantity));
        }
      } else {
        const unit = invoice.unit ?? 0;
        if (isCurrencyJPY || !isForeignCurrencyFlag) {
          invoice.totalValue = Math.round(Number(Big(unit).mul(quantity)));
        } else {
          invoice.totalValue = Number(Big(unit).mul(quantity));
        }
      }
      return invoice;
    },
    [isTaxIncluded, isForeignCurrencyFlag]
  );
  const updateAnotherUnitAndTotalValue = useCallback(
    (invoice: InvoiceTransaction) => {
      const zero = Big(0);
      const quantity = invoice.quantity ?? 0;

      if (isTaxIncluded) {
        const taxIncludedTotalValue = invoice.taxIncludedTotalValue ?? 0;
        const exciseValue = invoice.exciseValue ?? 0;
        const totalValue = Number(
          Big(taxIncludedTotalValue).minus(exciseValue)
        );
        const unit = Number(
          (quantity !== 0 &&
            totalValue !== 0 &&
            Big(totalValue).div(quantity)) ||
            zero
        );
        invoice.totalValue = totalValue;
        invoice.unit = unit;
      } else {
        const taxIncludedTotalValue = Number(
          Big(invoice.totalValue ?? 0).add(invoice.exciseValue ?? 0)
        );
        const taxIncludedUnit = Number(
          (quantity !== 0 &&
            taxIncludedTotalValue !== 0 &&
            Big(taxIncludedTotalValue).div(quantity)) ||
            zero
        );
        invoice.taxIncludedTotalValue = taxIncludedTotalValue;
        invoice.taxIncludedUnit = floorOnAbsolute(taxIncludedUnit);
      }
      return invoice;
    },
    [floorOnAbsolute, isTaxIncluded]
  );
  const equalizeTotalAndUnit = useCallback(
    (invoice: InvoiceTransaction) => {
      if (canDisplayTransactionDetail) {
        return invoice;
      }
      if (isTaxIncluded) {
        const taxIncludedTotalValue = invoice.taxIncludedTotalValue ?? 0;
        invoice.quantity = 1;
        invoice.taxIncludedUnit = taxIncludedTotalValue;
      } else {
        const totalValue = invoice.totalValue ?? 0;
        invoice.quantity = 1;
        invoice.unit = totalValue;
      }
      return invoice;
    },
    [canDisplayTransactionDetail, isTaxIncluded]
  );

  const updateRateForJpy = useCallback(
    (invoice: InvoiceTransaction): InvoiceTransaction => {
      if (!isForeignCurrencyFlag) return invoice;
      const rate = invoice.currency === 'JPY' ? 1 : invoice.jpyRate;
      invoice.jpyRate = rate;
      return invoice;
    },
    [isForeignCurrencyFlag]
  );

  const updateRate = useCallback(
    (
      invoice: InvoiceTransaction,
      currencyRate?: ApCurrencyRate
    ): InvoiceTransaction => {
      if (!isForeignCurrencyFlag) return invoice;
      invoice.jpyRate = currencyRate?.value ?? invoice.jpyRate;
      return invoice;
    },
    [isForeignCurrencyFlag]
  );

  const withholdingIncomeTaxHelper = useCallback(
    (invoice: InvoiceTransaction): InvoiceTransaction => {
      // https://github.com/moneyforward/ex_web/blob/release/client/stores/invoice_reports/InvoiceTransactionInputSupporter/supporter/HasWithholdingIncomeTax.ts#L5
      const hasWithholdingIncomeTax = invoice.hasWithholdingIncomeTax;
      if (!hasWithholdingIncomeTax) return invoice;
      const totalValue =
        (isTaxIncluded ? invoice.taxIncludedTotalValue : invoice.totalValue) ??
        0;
      invoice.withholdingIncomeTax = getWithholdingIncomeTax(totalValue);
      return invoice;
    },
    [isTaxIncluded, getWithholdingIncomeTax]
  );

  const dealDateHelper = useCallback(
    (
      invoice: InvoiceTransaction,
      dynamicalHasSpecialException?: boolean,
      dynamicalTpmData?: tpmData
    ) => {
      // https://github.com/moneyforward/ex_web/blob/release/client/stores/invoice_reports/InvoiceTransactionInputSupporter/supporter/DealDate.ts#L6
      return updateInvoiceKind(
        invoice,
        dynamicalHasSpecialException || hasSpecialException,
        isDutyFree,
        dynamicalTpmData || tpmData
      );
    },
    [updateInvoiceKind, hasSpecialException, isDutyFree, tpmData]
  );
  const drExciseIdHelper = useCallback(
    (
      invoice: InvoiceTransaction,
      value: string | undefined = undefined,
      dynamicalHasSpecialException?: boolean,
      dynamicalTpmData?: tpmData
    ) => {
      // https://github.com/moneyforward/ex_web/blob/release/client/stores/invoice_reports/InvoiceTransactionInputSupporter/supporter/DrExciseId.ts#L6
      invoice = updateExciseValue(invoice, value);
      invoice = updateAnotherUnitAndTotalValue(invoice);
      invoice = updateInvoiceKind(
        invoice,
        dynamicalHasSpecialException || hasSpecialException,
        isDutyFree,
        dynamicalTpmData || tpmData
      );
      return invoice;
    },
    [
      updateExciseValue,
      updateAnotherUnitAndTotalValue,
      updateInvoiceKind,
      hasSpecialException,
      isDutyFree,
      tpmData,
    ]
  );
  const exciseValueHelper = useCallback(
    (invoice: InvoiceTransaction) => {
      // https://github.com/moneyforward/ex_web/blob/release/client/stores/invoice_reports/InvoiceTransactionInputSupporter/supporter/ExciseValue.ts#L5
      return updateAnotherUnitAndTotalValue(invoice);
    },
    [updateAnotherUnitAndTotalValue]
  );
  const exItemIdHelper = useCallback(
    (
      invoice: InvoiceTransaction,
      dynamicalHasSpecialException?: boolean,
      dynamicalTpmData?: tpmData
    ) => {
      invoice = updateDrExciseId(invoice);
      invoice = updateExciseValue(invoice);
      invoice = withholdingIncomeTaxHelper(invoice);
      invoice = updateAnotherUnitAndTotalValue(invoice);
      return updateInvoiceKind(
        invoice,
        dynamicalHasSpecialException || hasSpecialException,
        isDutyFree,
        dynamicalTpmData || tpmData
      );
    },
    [
      updateDrExciseId,
      updateExciseValue,
      withholdingIncomeTaxHelper,
      updateAnotherUnitAndTotalValue,
      updateInvoiceKind,
      hasSpecialException,
      isDutyFree,
      tpmData,
    ]
  );
  const quantityHelper = useCallback(
    (invoice: InvoiceTransaction) => {
      // https://github.com/moneyforward/ex_web/blob/release/client/stores/invoice_reports/InvoiceTransactionInputSupporter/supporter/Quantity.ts#L6
      invoice = updateTotalValue(invoice);
      invoice = updateExciseValue(invoice);
      invoice = withholdingIncomeTaxHelper(invoice);
      return updateAnotherUnitAndTotalValue(invoice);
    },
    [
      updateAnotherUnitAndTotalValue,
      updateExciseValue,
      updateTotalValue,
      withholdingIncomeTaxHelper,
    ]
  );
  const totalValueHelper = useCallback(
    (invoice: InvoiceTransaction) => {
      // https://github.com/moneyforward/ex_web/blob/release/client/stores/invoice_reports/InvoiceTransactionInputSupporter/supporter/TotalValue.ts#L6
      invoice = updateExciseValue(invoice);
      invoice = withholdingIncomeTaxHelper(invoice);
      invoice = updateAnotherUnitAndTotalValue(invoice);
      return equalizeTotalAndUnit(invoice);
    },
    [
      equalizeTotalAndUnit,
      updateAnotherUnitAndTotalValue,
      updateExciseValue,
      withholdingIncomeTaxHelper,
    ]
  );

  const currencyValueHelper = useCallback(
    (invoice: InvoiceTransaction) => {
      invoice = updateRateForJpy(invoice);
      invoice = exciseValueHelper(invoice);
      invoice = updateTotalValue(invoice);
      invoice = updateExciseValue(invoice);
      invoice = withholdingIncomeTaxHelper(invoice);
      return updateAnotherUnitAndTotalValue(invoice);
    },
    [
      exciseValueHelper,
      updateAnotherUnitAndTotalValue,
      updateExciseValue,
      updateRateForJpy,
      updateTotalValue,
      withholdingIncomeTaxHelper,
    ]
  );

  const rateValueHelper = useCallback(
    (invoice: InvoiceTransaction, rateData?: ApCurrencyRate) => {
      invoice = updateRate(invoice, rateData);
      return updateAnotherUnitAndTotalValue(invoice);
    },
    [updateAnotherUnitAndTotalValue, updateRate]
  );

  const unitHelper = useCallback(
    (invoice: InvoiceTransaction) => {
      // https://github.com/moneyforward/ex_web/blob/release/client/stores/invoice_reports/InvoiceTransactionInputSupporter/supporter/Unit.ts#L6
      invoice = updateTotalValue(invoice);
      invoice = updateExciseValue(invoice);
      invoice = withholdingIncomeTaxHelper(invoice);
      return updateAnotherUnitAndTotalValue(invoice);
    },
    [
      updateAnotherUnitAndTotalValue,
      updateExciseValue,
      updateTotalValue,
      withholdingIncomeTaxHelper,
    ]
  );

  return {
    getIsZeroPer,
    dealDateHelper,
    drExciseIdHelper,
    exciseValueHelper,
    exItemIdHelper,
    quantityHelper,
    totalValueHelper,
    unitHelper,
    withholdingIncomeTaxHelper,
    currencyValueHelper,
    rateValueHelper,
  };
};
