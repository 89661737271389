import { useIsActiveInstantPayee } from '@/components/ReportForm/InstantPayeeSwitcher/hooks/useIsActiveInstantPayee';
import {
  SelectedType,
  useSetApPayeeSelectedValue,
} from '@/components/ReportForm/Modals/ChangeXXX';
import { ApPayeeDetail } from 'ap-openapi';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Control,
  FieldPath,
  FieldValues,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useUpdatePayeeRelatedInfo } from './useChangePayeeValue';
import { IsDateEditArgs, useIsDateEdit } from './useIsDateEdit';

type ChangePayeeActionsArgs<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  bookDateName: IsDateEditArgs<TFieldValues>['bookDateName'];
  dueDateName: IsDateEditArgs<TFieldValues>['dueDateName'];
  findDetails: () =>
    | ((payeeId: string | undefined) => Promise<ApPayeeDetail | undefined>)
    | undefined;
};

export const useChangePayeeActions = <TFieldValues extends FieldValues>({
  control,
  name,
  bookDateName,
  dueDateName,
  findDetails,
}: ChangePayeeActionsArgs<TFieldValues>) => {
  const isActiveInstantPayee = useIsActiveInstantPayee<TFieldValues>({
    control,
    name,
  });
  const { setValue, getValues } = useFormContext<TFieldValues>();
  const value = useWatch<TFieldValues>({ control, name });
  const isDateEditing = useIsDateEdit<TFieldValues>({
    control,
    bookDateName,
    dueDateName,
  });
  const [initialValue] = useState(getValues(name));
  const nowPayee = useRef(getValues(name));
  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => {
    setOpen(false);
    setValue(name, nowPayee.current);
  }, [name, setValue]);
  const onSelectedValue = useSetApPayeeSelectedValue();
  const onDialogClick = useCallback(
    (selectedValue: SelectedType, args?: object) => {
      onSelectedValue({ type: selectedValue, args });
      setOpen(false);
      nowPayee.current = value;
    },
    [onSelectedValue, value]
  );
  const updatePayeeRelatedInfo = useUpdatePayeeRelatedInfo(
    bookDateName,
    dueDateName
  );
  useEffect(() => {
    // 初期選択がされていない場合のみ自動的に日付を設定する
    if (!initialValue && !nowPayee.current) {
      if (value) {
        // TODO: Refで無理矢理検索出来るようにしているので関数にしている
        findDetails()?.(value).then((payee) => {
          updatePayeeRelatedInfo(payee);
        });
        nowPayee.current = value;
      }
      return;
    }
    if (
      value &&
      nowPayee.current !== value &&
      !isActiveInstantPayee &&
      isDateEditing
    ) {
      setOpen(true);
    }
  }, [
    findDetails,
    initialValue,
    isActiveInstantPayee,
    isDateEditing,
    updatePayeeRelatedInfo,
    value,
  ]);
  return useMemo(
    () => ({
      open,
      onClose,
      onDialogClick,
    }),
    [onClose, onDialogClick, open]
  );
};
