import { ErrorBoundary } from '@/components/Rollbar';
import { DrawerCloseProvider } from '@/context/Drawer';
import { useReceivedInvoicesSearch } from '@/context/services/invoice/ReceivedInvoice.service';
import { ReceivedInvoicesSearchRefetchProvider } from '@/context/services/invoice/refetchContext';
import {
  InvoiceColumn,
  ReceivedInvoicesSearchQuery,
} from '@/context/services/invoice/type';
import { useReceivedInvoiceList } from '@/features/invoices/components/List/Table/hook';
import { TableProps } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, Suspense, memo, useCallback, useEffect, useMemo } from 'react';
import { Drawer } from './Drawer';
import styles from './List.module.scss';
import { ErrorInvoiceTable, InvoiceTable, LoadingInvoiceTable } from './Table';

const cx = classnames.bind(styles);

export type ReceivedInvoiceTableProps = Omit<
  TableProps<InvoiceColumn>,
  'columns' | 'pagination' | 'emptyType'
>;

export type ReceivedInvoiceTableViewProps = {
  searchQuery: ReceivedInvoicesSearchQuery;
  onSearch: (value: ReceivedInvoicesSearchQuery) => void;
};

const InnerList: FC<ReceivedInvoiceTableViewProps> = memo(
  ({ searchQuery: _searchQuery, onSearch }) => {
    const { onDrawerClose, onDrawerOpen, selectedId } =
      useReceivedInvoiceList();

    const query = useMemo(() => {
      const { page, ...searchQuery } = _searchQuery;
      return { query: { ...searchQuery }, page };
    }, [_searchQuery]);

    const {
      data,
      pagination: _pagination,
      refetch,
    } = useReceivedInvoicesSearch(query);

    const table: ReceivedInvoiceTableProps = useMemo(() => {
      return {
        data,
        onRow: (data) => {
          return {
            onClick: () => {
              onDrawerOpen(data.searchId);
            },
          };
        },
      };
    }, [data, onDrawerOpen]);

    const onPageChange = useCallback(
      (page: number) => {
        onSearch({ page });
      },
      [onSearch]
    );

    const pagination = useMemo(() => {
      return {
        ..._pagination,
        onChange: onPageChange,
      };
    }, [_pagination, onPageChange]);

    useEffect(() => {
      const currentPage = _pagination?.currentPage;
      if (currentPage && currentPage !== _searchQuery.page) {
        onPageChange(currentPage);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_pagination?.currentPage]);

    const isSearchEmpty = useMemo(() => {
      const { query: _query } = query;
      return data.length === 0 && Object.keys(_query).length !== 0;
    }, [data.length, query]);

    return (
      <ReceivedInvoicesSearchRefetchProvider value={refetch}>
        <div className={cx(styles['container'])}>
          <DrawerCloseProvider value={onDrawerClose}>
            <InvoiceTable
              pagination={pagination}
              table={table}
              isSearchEmpty={isSearchEmpty}
            />
            <Drawer
              open={!!selectedId}
              selectedId={selectedId}
              onClose={onDrawerClose}
            />
          </DrawerCloseProvider>
        </div>
      </ReceivedInvoicesSearchRefetchProvider>
    );
  }
);
InnerList.displayName = 'InnerList';

export const List: FC<ReceivedInvoiceTableViewProps> = memo((props) => {
  return (
    <ErrorBoundary fallback={ErrorInvoiceTable}>
      <Suspense fallback={<LoadingInvoiceTable />}>
        <InnerList {...props} />
      </Suspense>
    </ErrorBoundary>
  );
});
List.displayName = 'List';
