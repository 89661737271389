import { useFindCrItemByName } from '@/components/CrItemsSelect/Provider';
import { useFindCrSubItemByName } from '@/components/CrSubItemsSelect/Provider';
import { useFindCurrencyByName } from '@/components/CurrenciesSelect/Provider';
import { useFindDeptByName } from '@/components/DeptSelect/Provider';
import { useFindExItemByName } from '@/components/ExItemSelect/Provider';
import { useFindExciseByName } from '@/components/ExciseSelect/Provider';
import { useFindProjectByName } from '@/components/ProjectSelect/Provider';
import { INVOICE_NUMBER_UNSAVED } from '@/context/services/reportsType/invoiceReports/type';
import { InvoiceTransaction } from '@/features/InvoiceReport/Edit/type';
import { toFloat } from '@/utils/convert/toNumber';
import { UploadFile, randomId } from '@moneyforward/ap-frontend-components';
import {
  NotFoundRecord,
  UnMatchHeader,
  importExcel,
  parse,
} from 'csv-excel-imp-exp-util';
import { stringToDayjs } from 'date-util';
import { useCallback } from 'react';
import {
  CustomInvoiceTransactionMapping,
  importMappings,
} from '../importExportMapping';
type ImportArgs = {
  file: UploadFile | undefined;
};

export { NotFoundRecord, UnMatchHeader };

const useConvertInvoiceTransactionMappingToInvoiceTransaction = (
  taxInclude: boolean,
  invoiceTransactionDetails: boolean
) => {
  const findExcise = useFindExciseByName();
  const findDept = useFindDeptByName();
  const findCrItem = useFindCrItemByName();
  const findCrSubItem = useFindCrSubItemByName();
  const findExItem = useFindExItemByName();
  const findProject = useFindProjectByName();
  const findCurrency = useFindCurrencyByName();
  return useCallback(
    (data: CustomInvoiceTransactionMapping[]): InvoiceTransaction[] => {
      return data.map((item): InvoiceTransaction => {
        const excise = findExcise(item.exciseName);
        const dept = findDept(item.deptName);
        const crItem = findCrItem(item.crItemName);
        const crSubItem = findCrSubItem(item.crSubItemName);
        const exItem = findExItem(item.exItemName);
        const project = findProject(item.projectName);
        const currency = findCurrency(item.currency);
        const value = {
          id: String(Math.floor(Math.random() * 10000)),
          number: INVOICE_NUMBER_UNSAVED,
          dealDate: stringToDayjs(item.dealDate) ?? null,
          name: item.name,
          exItemId: exItem,
          unit: toFloat(item.unitPriceExcludingTax),
          taxIncludedUnit: toFloat(item.unitPriceIncludingTax),
          quantity: toFloat(item.quantity),
          totalValue: toFloat(item.totalValueExcludingTax),
          taxIncludedTotalValue: toFloat(item.totalValueIncludingTax),
          drExciseId: excise,
          invoiceKind: item.invoiceKind ? item.invoiceKind : '',
          exciseValue: toFloat(item.tax),
          withholdingIncomeTax: toFloat(item.withholdingIncomeTax),
          hasWithholdingIncomeTax: item.withholdingIncomeTax !== '',
          memo: item.memo,
          deptId: dept,
          projectCodeId: project,
          currency: currency,
          jpyRate: toFloat(item.jpyRate),
          crItemId: crItem,
          crSubItemId: crSubItem,
          rowKey: randomId('invoice-transaction', 8),
        } satisfies InvoiceTransaction;
        if (!invoiceTransactionDetails) {
          value.quantity = 1;
          const totalValue = taxInclude
            ? value.taxIncludedTotalValue
            : value.totalValue;
          value.unit = totalValue;
          value.taxIncludedUnit = totalValue;
        }
        return value;
      });
    },
    [
      findCrItem,
      findCrSubItem,
      findCurrency,
      findDept,
      findExItem,
      findExcise,
      findProject,
      invoiceTransactionDetails,
      taxInclude,
    ]
  );
};

export const useImport = (
  taxInclude: boolean,
  invoiceTransactionDetails: boolean,
  isAdminOrApproval: boolean
) => {
  const convertInvoiceTransaction =
    useConvertInvoiceTransactionMappingToInvoiceTransaction(
      taxInclude,
      invoiceTransactionDetails
    );
  const importInvoiceTransaction = useCallback(
    async ({ file }: ImportArgs): Promise<InvoiceTransaction[]> => {
      return new Promise((resolve, reject) => {
        if (!file) {
          return [];
        }
        const isCsv = file.type === 'text/csv';
        const parseFn = isCsv
          ? parse<CustomInvoiceTransactionMapping>
          : importExcel<CustomInvoiceTransactionMapping>;
        const reader: FileReader = new FileReader();
        reader.onloadend = async (e) => {
          try {
            if (!e.target?.result) {
              return resolve([]);
            }
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const input = Buffer.from(e.target.result as ArrayBuffer);
            const data: CustomInvoiceTransactionMapping[] = await parseFn(
              input,
              importMappings(
                isAdminOrApproval,
                invoiceTransactionDetails,
                taxInclude
              )
            );
            resolve(convertInvoiceTransaction(data));
          } catch (error) {
            reject(error);
          }
        };
        reader.onerror = (e) => {
          reject(e);
        };
        if (file.originFileObj) {
          const blob = new Blob([file.originFileObj]);
          reader.readAsArrayBuffer(blob);
        }
      });
    },
    [
      convertInvoiceTransaction,
      invoiceTransactionDetails,
      isAdminOrApproval,
      taxInclude,
    ]
  );
  return { importInvoiceTransaction };
};
