import {
  InvoiceColumn,
  OperationStatus,
  OperationStatusLabel,
  UploadLabel,
  UploadType,
} from '@/context/services/invoice/type';
import { OperationStatusProvider } from '@/features/invoices/components/Details/Components/Context/OperationStatus';
import { FileNameCell } from '@/features/invoices/components/List/Table/Columns/Cells/FileNameCell';
import { useGetFlags } from '@/hooks/useFeatureFlag';
import { useTranslation } from '@/i18n';
import { splitFileNameExtension } from '@/libs/files/split';
import type { ColumnType } from 'antd/es/table/interface';
import classnames from 'classnames/bind';
import { formatDateWithTime } from 'date-util';
import { Dayjs } from 'dayjs';
import { MemoCell } from './Cells/MemoCell';
import styles from './columns.module.scss';

const cx = classnames.bind(styles);

export const useColumns = (): ColumnType<InvoiceColumn>[] => {
  const { t } = useTranslation();
  const flagEnhanceInvoiceListSearch = useGetFlags(
    'ENHANCE_INVOICE_LIST_SEARCH'
  );

  const allColumns: (ColumnType<InvoiceColumn> & { hidden?: boolean })[] = [
    {
      title: t('received_invoice_id'),
      dataIndex: 'id',
      key: 'id',
      render(value) {
        return `IV-${value}`;
      },
      width: '70px',
    },
    {
      title: t('ファイル名'),
      dataIndex: 'fileName',
      key: 'fileName',
      render(value: string, r: InvoiceColumn) {
        const { name, ext } = splitFileNameExtension(value ?? '');
        return (
          <OperationStatusProvider value={r.operationStatus}>
            <FileNameCell name={name} ext={ext} />
          </OperationStatusProvider>
        );
      },
      className: cx(styles['fileTd']),
    },
    {
      title: (
        <>
          <div>{t('支払先コード')}</div>
          <div>{t('label_ap_payee')}</div>
        </>
      ),
      key: 'payeeCode',
      dataIndex: 'payeeCode',
      render(value, record) {
        return (
          <div className={cx(styles['payeeContainer'])}>
            <div className={cx(styles['payee'])}>{value}</div>
            <div className={cx(styles['payee'])}>{record.payeeName}</div>
          </div>
        );
      },
      width: '220px',
    },
    {
      title: t('請求書担当者'),
      key: 'pics',
      render(_, record) {
        return <div>{record.pics.map((pic) => pic.name).join(',')}</div>;
      },
      width: '140px',
    },
    {
      title: t('アップロード時刻'),
      dataIndex: 'uploadedAt',
      render(value: Dayjs) {
        return formatDateWithTime(value);
      },
      width: '140px',
    },
    {
      title: t('受領方法'),
      dataIndex: 'uploadType',
      render(value: UploadType) {
        return <div>{UploadLabel[value]}</div>;
      },
      width: '90px',
    },
    {
      title: (
        <>
          <div>{t('作業')}</div>
          <div>{t('ステータス')}</div>
        </>
      ),
      dataIndex: 'operationStatus',
      render(value: OperationStatus) {
        return OperationStatusLabel[value];
      },
      width: '110px',
      hidden: flagEnhanceInvoiceListSearch,
    },
    {
      title: t('メモ'),
      dataIndex: 'memo',
      render(value) {
        return <MemoCell value={value} />;
      },
      width: flagEnhanceInvoiceListSearch ? '320px' : '50px',
    },
  ];

  return allColumns.filter((column) => !column.hidden);
};
