/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */

export type EDocTimestampStatus =
  (typeof EDocTimestampStatus)[keyof typeof EDocTimestampStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EDocTimestampStatus = {
  creating: 'creating',
  done: 'done',
  need_retry: 'need_retry',
  failed: 'failed',
  unsupported: 'unsupported',
} as const;
