import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  ButtonGroup,
  ButtonV2,
  Modal,
} from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export type Props = {
  onClose: () => void;
  open: boolean;
  onDelete: () => void;
  title: string;
  description: string;
};

export const DeleteModal: FC<Props> = memo(
  ({ onClose, open, onDelete, title, description }) => {
    const { t } = useTranslation();
    const containerRef = useGlobalContainerRef();

    return (
      <Modal
        getContainer={containerRef}
        mask
        onClose={onClose}
        open={open}
        title={title}
        testId='delete'
        footer={
          <ButtonGroup>
            <ButtonV2
              size='sm'
              onClick={onClose}
              isSecondary
              color='primary'
              data-testid='cancel-button'
            >
              {t('cancel')}
            </ButtonV2>
            <ButtonV2
              size='sm'
              onClick={onDelete}
              color='danger'
              data-testid='delete-button'
            >
              {t('delete')}
            </ButtonV2>
          </ButtonGroup>
        }
      >
        <div>{description}</div>
      </Modal>
    );
  }
);
DeleteModal.displayName = 'DeleteModal';
