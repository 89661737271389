import {
  BankBranchesSelect,
  BanksSelect,
} from '@/components/BankAccountSelect';
import { FormSelectProps } from '@moneyforward/ap-frontend-components';
import { memo, useMemo } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> = {
  bankAccountNameInputId: string;
  bankAccountName: FieldPath<TFieldValues>;
  bankAccountBranchInputId: string;
  bankAccountBranchName: FieldPath<TFieldValues>;
  getPopupContainer?: FormSelectProps<TFieldValues>['selectProps']['getPopupContainer'];
} & Omit<
  FormSelectProps<TFieldValues>,
  'selectProps' | 'label' | 'inputId' | 'name'
>;

export const _BankAccountSelect = <TFieldValues extends FieldValues>({
  bankAccountNameInputId,
  bankAccountName,
  bankAccountBranchInputId,
  bankAccountBranchName,
  rules,
  control,
  getPopupContainer,
  ...rest
}: Props<TFieldValues>) => {
  const memorizedSelectProps: {
    getPopupContainer: FormSelectProps<TFieldValues>['selectProps']['getPopupContainer'];
  } = useMemo(() => ({ getPopupContainer }), [getPopupContainer]);
  return (
    <>
      <BanksSelect<TFieldValues>
        inputId={bankAccountNameInputId}
        rules={rules}
        name={bankAccountName}
        bankBranchName={bankAccountBranchName}
        control={control}
        selectProps={memorizedSelectProps}
        {...rest}
      />
      <BankBranchesSelect<TFieldValues>
        inputId={bankAccountBranchInputId}
        rules={rules}
        name={bankAccountBranchName}
        control={control}
        bankIdName={bankAccountName}
        selectProps={memorizedSelectProps}
      />
    </>
  );
};
_BankAccountSelect.displayName = '_BankAccountSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const BankAccountSelect = memo((props) => (
  <_BankAccountSelect {...props} />
)) as typeof _BankAccountSelect;
BankAccountSelect.displayName = 'BankAccountSelect';
