import {
  OfficeMemberPositionDeptsSelect,
  Props as OfficeMemberPositionDeptsSelectProps,
} from '@/components/OfficeMemberPositionDeptsSelect';
import { useGetPopupContainer } from '@/components/ReportForm/Container/PopupContainer';
import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import {
  InputBaseType,
  SelectType,
} from '@/components/ReportForm/InputBase/types';
import { useTranslation } from '@/i18n';
import { FormSelectProps } from '@moneyforward/ap-frontend-components';
import { ReportDept } from 'ap-openapi';
import { memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues> &
    OfficeMemberPositionDeptsSelectProps<TFieldValues> & {
      reportDept?: ReportDept;
    } & SelectType;

const InnerApReportFormInputOfficeMemberPositionDeptsSelect = <
  TFieldValues extends FieldValues
>({
  control,
  name,
  inputId,
  label,
  tooltip,
  caption,
  hidden,
  officeMemberId,
  reportDept,
  defaultOptions,
  required = false,
}: Props<TFieldValues>) => {
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [required, label, tooltip]
  );

  const containerRef = useGetPopupContainer();
  const { t } = useTranslation();
  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );

  const memorizedSelectProps = useMemo(
    (): Omit<
      FormSelectProps<TFieldValues>['selectProps'],
      'options' | 'comboBox'
    > => ({
      mode: 'single',
      getPopupContainer: containerRef,
    }),
    [containerRef]
  );

  if (!reportDept) {
    return null;
  }
  return (
    <InputBase labelProps={labelProps} description={caption} hidden={hidden}>
      {reportDept.is_changeable ? (
        <OfficeMemberPositionDeptsSelect<TFieldValues>
          control={control}
          name={name}
          inputId={inputId}
          rules={rules}
          selectProps={memorizedSelectProps}
          officeMemberId={officeMemberId}
          defaultOptions={defaultOptions}
        />
      ) : (
        <p>{reportDept.fixed_dept_and_position_name}</p>
      )}
    </InputBase>
  );
};

InnerApReportFormInputOfficeMemberPositionDeptsSelect.displayName =
  'InnerApReportFormInputOfficeMemberSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputOfficeMemberPositionDeptsSelect = memo(
  (props) => (
    <InnerApReportFormInputOfficeMemberPositionDeptsSelect {...props} />
  )
) as typeof InnerApReportFormInputOfficeMemberPositionDeptsSelect;
ApReportFormInputOfficeMemberPositionDeptsSelect.displayName =
  'ApReportFormInputOfficeMemberPositionDeptsSelect';
