import { useCellWatch } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/Columns/Cells/hooks/useCellWatch';
import {
  FormInput,
  FormInputProps,
} from '@moneyforward/ap-frontend-components';
import { memo } from 'react';
import { FieldValues } from 'react-hook-form';
import { CellProps } from './type';

export type Props<TFieldValues extends FieldValues> =
  {} & FormInputProps<TFieldValues> & CellProps;

const InnerFormInputCell = <TFieldValues extends FieldValues>({
  control,
  name,
  row,
  index,
  onBlur,
  ...rest
}: Props<TFieldValues>) => {
  const {
    key,
    componentProps,
    onBlur: onWrapBlur,
  } = useCellWatch(control, name, row.id, index, onBlur);
  return (
    <FormInput<TFieldValues>
      key={key}
      control={control}
      name={name}
      inputProps={componentProps}
      onBlur={onWrapBlur}
      {...rest}
    />
  );
};
InnerFormInputCell.displayName = 'FormInputCell';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const FormInputCell = memo(
  InnerFormInputCell
) as typeof InnerFormInputCell;
