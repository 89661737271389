import { isIframe } from '@moneyforward/ap-frontend-components';
import { useCallback } from 'react';

export const emitDisabledBeforeUnload = (w?: Window) => {
  const _win = w ?? isIframe() ? window.parent : window;
  _win.onbeforeunload = null;
  _win.dispatchEvent(new CustomEvent('disableBeforeUnload', { bubbles: true }));
};

export const useReloadWindow = (disabledBeforeUnload?: boolean) =>
  useCallback(() => {
    const w = isIframe() ? window.parent : window;
    if (disabledBeforeUnload) {
      emitDisabledBeforeUnload(w);
    }
    w.location.reload();
  }, [disabledBeforeUnload]);
