import {
  useProject,
  useProjectCacheKey,
  useProjectPer,
} from '@/components/ProjectSelect/Provider';
import {
  BaseOption,
  useModelsToOptionsWithData,
} from '@/hooks/useTypeToOptions';
import { useTranslation } from '@/i18n';
import { getPageData } from '@/utils/getPageData';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import { ProjectCode, useGetProjectCodesSuspenseInfinite } from 'ap-openapi';
import { memo, startTransition, useCallback, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<
    FormSelectProps<TFieldValues>['selectProps'],
    'options' | 'comboBox'
  >;
  query?: string;
  per?: number;
  validateMaxCount?: number;
  defaultOptions?: BaseOption[];
  comboBox?: boolean;
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

const _ProjectSelect = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  defaultOptions: _defaultOptions,
  per = 25,
  comboBox = true,
  ...rest
}: Props<TFieldValues>) => {
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );
  const { t } = useTranslation();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const ctxPer = useProjectPer();
  const ctxData = useProject();
  const ctxCacheKey = useProjectCacheKey(query);
  const {
    data: apiData,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useGetProjectCodesSuspenseInfinite(
    {
      query,
      per: ctxPer ?? per,
    },
    {
      query: {
        getNextPageParam: (page) => {
          const url = new URL(page.data.next ?? '/', location.href);
          if (!url.searchParams.has('page')) return undefined;
          return Number(url.searchParams.get('page'));
        },
        queryKey: ctxCacheKey,
      },
    }
  );
  if (error) throw error;
  const data = useMemo(
    () => [
      ...(!query ? ctxData : []),
      ...getPageData(apiData, 'project_codes'),
    ],
    [apiData, ctxData, query]
  );
  const options = useModelsToOptionsWithData<ProjectCode>(
    data,
    query,
    defaultOptions
  );
  const onSearch = useCallback((value: string) => {
    startTransition(() => {
      setQuery(value);
    });
  }, []);
  const fetchMore = useCallback(
    async (callback: () => void) => {
      if (hasNextPage) {
        await fetchNextPage();
      }
      callback();
    },
    [fetchNextPage, hasNextPage]
  );
  const selectProps: SelectProps = useMemo(() => {
    return {
      onSearchDebounceWait: 600,
      comboBox: comboBox,
      placeholder: t('project_placeholder'),
      inputPlaceholder: t('project_input_placeholder'),
      ..._selectProps,
      options: options,
      onSearch: onSearch,
      loading: isLoading,
      fetchMore: fetchMore,
      mode: 'single',
      selectAll: undefined,
      selectAllLabel: undefined,
    };
  }, [comboBox, t, _selectProps, options, onSearch, isLoading, fetchMore]);

  return <FormSelect<TFieldValues> selectProps={selectProps} {...rest} />;
};
_ProjectSelect.displayName = '_ProjectSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ProjectSelect = memo((props) => (
  <_ProjectSelect {...props} />
)) as typeof _ProjectSelect;
ProjectSelect.displayName = 'ProjectSelect';
