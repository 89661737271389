import { FunctionHeader } from '@/components/FunctionHeader';
import {
  EDocMetadata as BaseEDocMetadata,
  EDocMetadataProps as BaseProps,
} from '@/features/InvoiceReport/Components/EDocMetadata';
import { useGetFlags } from '@/hooks/useFeatureFlag';
import { useTranslation } from '@/i18n';
import type { EDocMetaDatum } from 'ap-openapi/openapi/model/eDocMetaDatum';
import classnames from 'classnames/bind';
import { FC, memo, useMemo } from 'react';
import styles from './EDocMetadata.module.scss';

type Props = {
  eDocMetaDatum?: EDocMetaDatum;
};

export const EDocMetadata: FC<Props> = memo(({ eDocMetaDatum }) => {
  const { t } = useTranslation();
  const cx = classnames.bind(styles);
  const flagEdocMetadata = useGetFlags('E_DOC_METADATA');

  const metadata = useMemo(() => {
    if (!flagEdocMetadata || !eDocMetaDatum) return null;

    return {
      dpi: eDocMetaDatum.dpi_text ?? '',
      pixelNumber: eDocMetaDatum.pixel_number_text ?? '',
      imageDepth: eDocMetaDatum.rgb_depth_text ?? '',
      type: 'warning',
      is_satisfy_resolution_requirement:
        eDocMetaDatum.is_satisfy_resolution_requirement ?? false,
      is_satisfy_depth_requirements:
        eDocMetaDatum.is_satisfy_depth_requirements ?? false,
    } satisfies BaseProps;
  }, [eDocMetaDatum, flagEdocMetadata]);

  if (!flagEdocMetadata || !eDocMetaDatum || !metadata) return null;

  return (
    <FunctionHeader
      functionName={t('e_doc_infomation')}
      testId='e_doc-metadata-header'
    >
      <div className={cx(styles['contentContainer'])}>
        <BaseEDocMetadata
          dpi={metadata.dpi}
          pixelNumber={metadata.pixelNumber}
          imageDepth={metadata.imageDepth}
          type={metadata.type}
          is_satisfy_resolution_requirement={
            metadata.is_satisfy_resolution_requirement
          }
          is_satisfy_depth_requirements={metadata.is_satisfy_depth_requirements}
        />
      </div>
    </FunctionHeader>
  );
});

EDocMetadata.displayName = 'EDocMetadata';
