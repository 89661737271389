import {
  CrItemsProvider,
  useIsLoadingCrItems,
} from '@/components/CrItemsSelect/Provider';
import {
  CrSubItemsProvider,
  useIsLoadingCrSubItems,
} from '@/components/CrSubItemsSelect/Provider';
import {
  CurrenciesProvider,
  useIsLoadingCurrencies,
} from '@/components/CurrenciesSelect/Provider';
import {
  DeptProvider,
  useIsLoadingDepts,
} from '@/components/DeptSelect/Provider';
import {
  ExItemProvider,
  useIsLoadingExItems,
} from '@/components/ExItemSelect/Provider';
import {
  ExciseProvider,
  useIsLoadingExcises,
} from '@/components/ExciseSelect/Provider';
import {
  ProjectProvider,
  useIsLoadingProject,
} from '@/components/ProjectSelect/Provider';
import {
  ApPayeeSelectedValueProvider,
  BookDateSelectedValueProvider,
} from '@/components/ReportForm';
import {
  FC,
  PropsWithChildren,
  RefObject,
  createContext,
  memo,
  useContext,
  useSyncExternalStore,
} from 'react';

const apiContext = createContext(false);
const detailTaxCalcApiContext = createContext(false);
export const useIsLoadingWithAllApi = () => useContext(apiContext);
export const useIsLoadingWithTaxCalcApiContext = () =>
  useContext(detailTaxCalcApiContext);

export const ComponentAPI: FC<PropsWithChildren> = memo(({ children }) => {
  return (
    <ApPayeeSelectedValueProvider>
      <BookDateSelectedValueProvider>
        <ExciseProvider>
          <ExItemProvider>
            <DeptProvider>
              <ProjectProvider>
                <CrItemsProvider>
                  <CrSubItemsProvider>
                    <CurrenciesProvider>
                      <InnerComponentAPI>{children}</InnerComponentAPI>
                    </CurrenciesProvider>
                  </CrSubItemsProvider>
                </CrItemsProvider>
              </ProjectProvider>
            </DeptProvider>
          </ExItemProvider>
        </ExciseProvider>
      </BookDateSelectedValueProvider>
    </ApPayeeSelectedValueProvider>
  );
});
ComponentAPI.displayName = 'ComponentAPI';

let value: boolean = true;
let promise: Promise<void> | null = null;

const listeners: (() => void)[] = [];

const subscribe = (cb: () => void) => {
  listeners.push(cb);

  return () => {
    const index = listeners.indexOf(cb);

    if (index !== -1) {
      listeners.splice(index, 1);
    }
  };
};

const triggerUpdate = () => {
  for (const cb of listeners) {
    cb();
  }
};

export const updateValue = (newValue: boolean) => {
  value = newValue;
  triggerUpdate();
};

export const useValue = () => useSyncExternalStore(subscribe, () => value);

const fetchValue = (ref: RefObject<boolean>) =>
  new Promise<boolean>((resolve) => {
    if (!ref.current) {
      resolve(false);
    }
  });

export const revalidateValue = (ref: RefObject<boolean>) =>
  fetchValue(ref).then((v) => updateValue(v));

export const useAsyncValue = (ref: RefObject<boolean>) =>
  useSyncExternalStore(subscribe, () => {
    if (value !== null) {
      return value;
    }

    if (promise !== null) {
      throw promise;
    }

    promise = revalidateValue(ref);

    throw promise;
  });

const InnerComponentAPI: FC<PropsWithChildren> = memo(({ children }) => {
  const crItemLoading = useIsLoadingCrItems();
  const crSubItemsLoading = useIsLoadingCrSubItems();
  const deptsLoading = useIsLoadingDepts();
  const exciseLoading = useIsLoadingExcises();
  const currenciesLoading = useIsLoadingCurrencies();
  const exItemsLoading = useIsLoadingExItems();
  const projectLoading = useIsLoadingProject();
  const currencyLoading = useIsLoadingCurrencies();
  const isLoading =
    crItemLoading ||
    crSubItemsLoading ||
    deptsLoading ||
    currenciesLoading ||
    exItemsLoading ||
    exciseLoading ||
    projectLoading;
  const isTaxCalcLoading = exciseLoading || exItemsLoading || currencyLoading;
  return (
    <apiContext.Provider value={isLoading}>
      <detailTaxCalcApiContext.Provider value={isTaxCalcLoading}>
        {children}
      </detailTaxCalcApiContext.Provider>
    </apiContext.Provider>
  );
});
InnerComponentAPI.displayName = 'InnerComponentAPI';
