import {
  BusinessDocumentActionMenu,
  BusinessDocumentActionMenuProps,
} from '@/components/RelationActionMenu';
import { SelectedBusinessDocument } from '@/components/RelationActionMenu/BusinessDocument/type';
import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { CardFileList } from '@/components/ReportForm/InputBase/CardFileList/CardFileList';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { useGetFormValue } from '@/components/ReportForm/hooks/useFormValue';
import { SuspenseReactErrorBoundary } from '@/components/SuspenseErrorBoundary/ReactDefault';
import { useBusinessDocument } from '@/context/services/business_document/BusinessDocument.service';
import { typeGuardReportBusinessDocument } from '@/context/services/reportsType/invoiceReports/type-guard';
import { findReportFormInputType } from '@/context/services/reportsType/invoiceReports/useConvertApiDataAndPayeeForm';
import { useFormValue } from '@/features/InvoiceReport/Edit/components/Context/API';
import { useTranslation } from '@/i18n';
import {
  FormController,
  useToastAPI,
} from '@moneyforward/ap-frontend-components';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';
import { Drawer } from './Drawer';

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues>;

const InnerApReportFormInputBusinessDocumentsField = <
  TFieldValues extends FieldValues
>({
  control,
  name,
  inputId,
  label,
  tooltip,
  caption,
  required = false,
  hidden,
  ...rest
}: Props<TFieldValues>) => {
  const apiData = useFormValue();
  const { onChange } = useGetFormValue<TFieldValues, string[]>(name);
  const { t } = useTranslation();
  const notify = useToastAPI();
  const memorizedLabel: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [label, required, tooltip]
  );
  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );
  const additionalValues = findReportFormInputType(
    apiData.report_form_inputs,
    'ApReportFormInputBusinessDocumentsField'
  )?.input_additional_values;
  const [files, setFiles] = useState<SelectedBusinessDocument[]>(() => {
    return (
      additionalValues
        ?.filter(typeGuardReportBusinessDocument)
        .map((value): SelectedBusinessDocument => {
          return {
            businessDocumentId: value.id,
            searchableNumber: Number(value.document_number.replace('Doc-', '')),
            documentTitle: value.document_title,
            mfFileId: value.mf_file?.id ?? '',
          };
        }) ?? []
    );
  });

  const onChangeFiles = useCallback(
    (
      files:
        | SelectedBusinessDocument[]
        | ((value: SelectedBusinessDocument[]) => SelectedBusinessDocument[])
    ) => {
      setFiles((values) => {
        const results = typeof files === 'function' ? files(values) : files;
        const inputValues = results.map((v) => v.businessDocumentId);
        onChange(inputValues);
        return results;
      });
    },
    [onChange]
  );
  const [selectedId, setSelectedId] = useState<string>('');
  const onDeleteLink = useCallback(
    (id: string) => {
      let nextPreviewFile: SelectedBusinessDocument | undefined;
      onChangeFiles((files) => {
        const nowIdx = files.findIndex(
          (file) => file.businessDocumentId === id
        );
        if (nowIdx === files.length - 1) {
          nextPreviewFile = files.find((_, idx) => idx === nowIdx - 1);
        } else {
          nextPreviewFile = files.find((_, idx) => idx === nowIdx + 1);
        }
        return files.filter((file) => file.businessDocumentId !== id);
      });
      setSelectedId((prev) => {
        if (prev) {
          return nextPreviewFile?.businessDocumentId ?? '';
        }
        return prev;
      });
      notify.success({
        title: t('business_document_unlink'),
        duration: 5,
      });
    },
    [notify, onChangeFiles, t]
  );
  const onDeleteClick = useCallback(
    (id: string) => {
      return () => {
        onDeleteLink(id);
      };
    },
    [onDeleteLink]
  );
  const onUnlink = useCallback(() => {
    onDeleteLink(selectedId);
  }, [onDeleteLink, selectedId]);
  const onSelectedFile = useCallback((id: string) => {
    return () => {
      setSelectedId(id);
    };
  }, []);
  const onCloseDrawer = useCallback(() => {
    setSelectedId('');
  }, []);

  return (
    <>
      <InputBase
        hidden={hidden}
        labelProps={memorizedLabel}
        description={caption}
        fileList={files.map((file) => {
          // TODO:
          /**
           * 権限が不足していて見れないケースがあるのでErrorBoundaryで防いでおくが、BEの対応が完了したら起きないと思う
           * There are cases where you can't see it because you don't have enough permissions, so I'm preventing it with ErrorBoundary, but I don't think it will happen once BE is complete.
           */
          return (
            <SuspenseReactErrorBoundary
              key={`${file.businessDocumentId}-${file.mfFileId}`}
              errorFallback={() => <></>}
            >
              <WrapCardFileList
                file={file}
                selected={selectedId === file.businessDocumentId}
                onDeleteClick={onDeleteClick(file.businessDocumentId)}
                onCardClick={onSelectedFile(file.businessDocumentId)}
              />
            </SuspenseReactErrorBoundary>
          );
        })}
      >
        <FormController
          control={control}
          name={name}
          inputId={inputId}
          rules={rules}
          {...rest}
        >
          {(fields) => {
            return (
              <WrapBizDocActionMenu<TFieldValues>
                fields={fields}
                inputId={inputId}
                onSelectedItems={onChangeFiles}
                selectedItems={files}
              />
            );
          }}
        </FormController>
      </InputBase>
      {selectedId && (
        // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
        <div style={{ position: 'absolute' }}>
          <Drawer
            open={!!selectedId}
            id={selectedId}
            onClose={onCloseDrawer}
            onUnlink={onUnlink}
          />
        </div>
      )}
    </>
  );
};
InnerApReportFormInputBusinessDocumentsField.displayName =
  'InnerApReportFormInputBusinessDocumentsField';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputBusinessDocumentsField = memo(
  InnerApReportFormInputBusinessDocumentsField
) as typeof InnerApReportFormInputBusinessDocumentsField;

type WrapBizDocProps<TFieldValues extends FieldValues> = {
  fields: ControllerRenderProps<TFieldValues, Path<TFieldValues>>;
  inputId: string;
} & Omit<BusinessDocumentActionMenuProps, 'container'>;

const WrapBizDocActionMenu = <TFieldValues extends FieldValues>({
  fields,
  inputId,
  onSelectedItems,
  selectedItems,
}: WrapBizDocProps<TFieldValues>) => {
  const children = useMemo(() => {
    return (
      <>
        <BusinessDocumentActionMenu
          container={null}
          onSelectedItems={onSelectedItems}
          selectedItems={selectedItems}
        />
        <input hidden id={inputId} {...fields} />
      </>
    );
  }, [fields, inputId, onSelectedItems, selectedItems]);
  return children;
};

type WrapCardFileListProps = {
  file: SelectedBusinessDocument;
  selected: boolean;
  onDeleteClick: VoidFunction;
  onCardClick: VoidFunction;
};

const WrapCardFileList: FC<WrapCardFileListProps> = memo(
  ({ file, selected, onDeleteClick, onCardClick }) => {
    const { t } = useTranslation();
    const { documentTitle, businessDocumentId } = file;
    const {
      data: { document_type },
    } = useBusinessDocument(businessDocumentId);
    const tagLabel = t(`document_type_${document_type}`);
    return (
      <CardFileList
        size='xs'
        tag={tagLabel}
        title={documentTitle}
        selected={selected}
        onButtonClick={onDeleteClick}
        onCardClick={onCardClick}
      />
    );
  }
);
WrapCardFileList.displayName = 'WrapCardFileList';
