import { useTranslation } from '@/i18n';
import {
  ButtonV2,
  IconCollapsed,
  IconExpanded,
  Label,
  Skeleton,
} from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import {
  CSSProperties,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  AmountError,
  AmountTablesMenu,
  Details,
  DetailsProps,
  ForeignCurrency,
  Summary,
  SummaryProps,
  useMinusTransaction,
} from '../AmountTablesMenu';
import styles from './TotalAmountPaid.module.scss';

const cx = classNames.bind(styles);

export type BaseProps = {
  summary: SummaryProps;
  details: DetailsProps;
  isLoading?: false;
  foreignCurrency?: ForeignCurrency;
  isPermitMinusTransaction?: boolean;
  hasWithholdingIncomeTax?: boolean;
};

type Mode = 'default' | 'small';

export type LoadingProps = {
  isLoading: true;
  summary?: never;
  details?: never;
  foreignCurrency?: never;
  isPermitMinusTransaction?: never;
  hasWithholdingIncomeTax?: false;
};

export type Props = (BaseProps | LoadingProps) & {
  mode?: Mode;
  onOpenChange?: (value: boolean) => void;
};

export const TotalAmountPaid: FC<Props> = memo(
  ({
    summary,
    details,
    foreignCurrency,
    isLoading,
    onOpenChange,
    isPermitMinusTransaction,
    mode = 'default',
    hasWithholdingIncomeTax,
  }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const label = useMemo(
      () =>
        open
          ? t('amount_tables_menu_close_label')
          : t('amount_tables_menu_open_label'),
      [open, t]
    );
    const icon = useMemo(
      () => (open ? <IconExpanded size={16} /> : <IconCollapsed size={16} />),
      [open]
    );
    const onClick = useCallback(() => {
      if (isLoading) return;
      setOpen((prev) => !prev);
    }, [isLoading]);
    const onEntered = useCallback(() => {
      onOpenChange?.(open);
    }, [onOpenChange, open]);
    const onExited = useCallback(() => {
      onOpenChange?.(open);
    }, [onOpenChange, open]);
    const memorizedButton = useMemo(() => {
      return (
        <ButtonV2 onClick={onClick} isTransparent isTertiary rightIcon={icon}>
          {label}
        </ButtonV2>
      );
    }, [icon, label, onClick]);

    const [height, setHeight] = useState('0px');
    const variableStyle = useMemo(
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      () => ({ '--content-height': height } as CSSProperties),
      [height]
    );
    const contentRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (contentRef.current) {
        setHeight(open ? `${contentRef.current.scrollHeight}px` : '0px');
      }
    }, [open]);
    const transitionStyles = useMemo(
      () => ({
        enter: styles['transition-enter'],
        enterActive: styles['transition-enter-active'],
        enterDone: styles['transition-enter-done'],
        exit: styles['transition-exit'],
        exitActive: styles['transition-exit-active'],
        exitDone: styles['transition-exit-done'],
      }),
      []
    );
    const minusResult = useMinusTransaction(isPermitMinusTransaction, summary);
    const memorizedSummary = useMemo(
      () =>
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        ({
          ...summary,
          minusResult: minusResult,
          hasWithholdingIncomeTax,
        } as Required<SummaryProps>),
      [minusResult, summary, hasWithholdingIncomeTax]
    );

    return (
      <div className={cx(styles['parent-container'])} style={variableStyle}>
        <div
          className={cx(styles['container'], {
            [styles['loading']]: isLoading,
          })}
        >
          {isLoading && (
            <span data-testid='skelton'>
              <Skeleton.Input
                active
                // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
                style={{ width: '268px', height: '16px' }}
                size='default'
              />
            </span>
          )}
          <div
            className={cx(styles['amount-container'], {
              [styles['hidden']]: isLoading,
            })}
          >
            <div className={cx(styles['label'])}>
              <Label labelClassName={cx(styles['bold'])}>
                {t('payment_request_detail_total_amount_label')}
              </Label>
            </div>
            <div className={cx(styles['amount'])}>
              <div className={cx(styles['jpy'])}>
                <div className={cx(styles['value'])}>
                  <div className={cx(styles['content'])}>
                    {!isLoading &&
                      (minusResult ? (
                        <AmountError message={minusResult.message}>
                          {summary.totalAmount.value.toLocaleString()}
                        </AmountError>
                      ) : (
                        summary.totalAmount.value.toLocaleString()
                      ))}
                  </div>
                </div>
                <div className={cx(styles['unit'])}>
                  <div className={cx(styles['content'])}>円</div>
                </div>
              </div>
              {foreignCurrency && foreignCurrency.value !== 0 && (
                <div className={cx(styles['foreign-currency'])}>
                  <span className={cx(styles['content'])}>(</span>
                  <span className={cx(styles['value'])}>
                    <span className={cx(styles['content'])}>
                      {foreignCurrency.value.toLocaleString()}
                    </span>
                  </span>
                  <span className={cx(styles['unit'])}>
                    <span className={cx(styles['content'])}>
                      {foreignCurrency.unit}
                    </span>
                  </span>
                  {foreignCurrency.isMultipleCurrency && (
                    <span className={cx(styles['content'])}>
                      {t(
                        'payment_request_detail_details_total_amount_multiple_currency'
                      )}
                    </span>
                  )}
                  <span className={cx(styles['content'])}>)</span>
                </div>
              )}
            </div>
          </div>
          {mode === 'default'
            ? memorizedButton
            : !isLoading && (
                <AmountTablesMenu
                  details={details}
                  summary={memorizedSummary}
                />
              )}
        </div>
        {!isLoading && mode === 'default' ? (
          <CSSTransition
            in={open}
            classNames={transitionStyles}
            timeout={1000}
            mountOnEnter
            nodeRef={contentRef}
            onEntered={onEntered}
            onExited={onExited}
          >
            <div className={cx(styles['table-container'])} ref={contentRef}>
              {minusResult && (
                <div className={cx(styles['error-message'])}>
                  {minusResult.message}
                </div>
              )}
              <div role='grid' className={cx(styles['amount-tables'])}>
                <div role='gridcell'>
                  <Details {...details} />
                </div>
                <div role='gridcell'>
                  <Summary {...memorizedSummary} />
                </div>
              </div>
            </div>
          </CSSTransition>
        ) : null}
      </div>
    );
  }
);
TotalAmountPaid.displayName = 'TotalAmountPaid';
