/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { BadRequestResponse } from '../model/badRequestResponse';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { NotFoundResponse } from '../model/notFoundResponse';
import type { OfficeMemberPositionDeptsResponse } from '../model/officeMemberPositionDeptsResponse';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import type { UnprocessableEntityResponse } from '../model/unprocessableEntityResponse';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Get office member position and depts
 */
export const useGetOfficeMemberPositionDeptsHook = () => {
  const getOfficeMemberPositionDepts =
    useCustomClient<OfficeMemberPositionDeptsResponse>();

  return (
    officeMemberId: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return getOfficeMemberPositionDepts(
      {
        url: `/api/js/office_members/${officeMemberId}/position_depts`,
        method: 'GET',
      },
      options
    );
  };
};

export const getGetOfficeMemberPositionDeptsQueryKey = (
  officeMemberId: string
) => {
  return [`/api/js/office_members/${officeMemberId}/position_depts`] as const;
};

export const useGetOfficeMemberPositionDeptsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetOfficeMemberPositionDeptsHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  officeMemberId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useGetOfficeMemberPositionDeptsHook>>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetOfficeMemberPositionDeptsQueryKey(officeMemberId);

  const getOfficeMemberPositionDepts = useGetOfficeMemberPositionDeptsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetOfficeMemberPositionDeptsHook>>>
  > = () => getOfficeMemberPositionDepts(officeMemberId, requestOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!officeMemberId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetOfficeMemberPositionDeptsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOfficeMemberPositionDeptsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetOfficeMemberPositionDeptsHook>>>
>;
export type GetOfficeMemberPositionDeptsQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get office member position and depts
 */
export const useGetOfficeMemberPositionDepts = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetOfficeMemberPositionDeptsHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  officeMemberId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useGetOfficeMemberPositionDeptsHook>>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetOfficeMemberPositionDeptsQueryOptions(
    officeMemberId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetOfficeMemberPositionDeptsSuspenseQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetOfficeMemberPositionDeptsHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  officeMemberId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useGetOfficeMemberPositionDeptsHook>>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetOfficeMemberPositionDeptsQueryKey(officeMemberId);

  const getOfficeMemberPositionDepts = useGetOfficeMemberPositionDeptsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetOfficeMemberPositionDeptsHook>>>
  > = () => getOfficeMemberPositionDepts(officeMemberId, requestOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!officeMemberId,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetOfficeMemberPositionDeptsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOfficeMemberPositionDeptsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetOfficeMemberPositionDeptsHook>>>
>;
export type GetOfficeMemberPositionDeptsSuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get office member position and depts
 */
export const useGetOfficeMemberPositionDeptsSuspense = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetOfficeMemberPositionDeptsHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  officeMemberId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useGetOfficeMemberPositionDeptsHook>>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetOfficeMemberPositionDeptsSuspenseQueryOptions(
    officeMemberId,
    options
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
