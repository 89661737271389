import { useRoundSettingForm } from '@/features/foreign_currency_settings/components/RoundSetting/hooks/useRoundSettingForm';
import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  ButtonGroup,
  ButtonV2,
  Modal,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './RoundSettingModal.module.scss';

export type Props = {
  open: boolean;
  onClose: () => void;
  formMethods: ReturnType<typeof useRoundSettingForm>;
};

const cx = classnames.bind(styles);

export const RoundSettingModal: FC<Props> = memo(
  ({ open, onClose, formMethods }: Props) => {
    const { t } = useTranslation();
    const containerRef = useGlobalContainerRef();
    const roundSetting = formMethods.getValues().rounding_mode;

    return (
      <Modal
        getContainer={containerRef}
        mask
        onClose={onClose}
        open={open}
        title={t('foreign_currency.round_setting.modal.title')}
        testId='round-setting'
        footer={
          <ButtonGroup>
            <ButtonV2 size='sm' onClick={onClose} isSecondary color='primary'>
              {t('cancel')}
            </ButtonV2>
            <ButtonV2 size='sm' type='submit' form='round_setting_form'>
              {t('change')}
            </ButtonV2>
          </ButtonGroup>
        }
      >
        <div className={cx(styles['modal-body'])}>
          {t('foreign_currency.round_setting.modal.description', {
            round_setting: t(
              `foreign_currency.round_setting.values.${roundSetting}`
            ),
          })}
        </div>
      </Modal>
    );
  }
);
RoundSettingModal.displayName = 'RoundSettingModal';
