import { PaymentRequestSettingFormType } from '@/context/services/notification_setting//type';
import { useUpdateNotificationSetting } from '@/context/services/notification_setting/notification_setting.service';
import { useTranslation } from '@/i18n';
import { useNotification } from '@moneyforward/ap-frontend-components';
import { useCallback, useState } from 'react';
export const useSubmit = (
  setError: (error: Error) => void,
  _onCallback: () => void | undefined
) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const { mutateAsync } = useUpdateNotificationSetting();
  const [isLoading, setLoading] = useState(false);
  const onSubmit = useCallback(
    async (data: PaymentRequestSettingFormType) => {
      setLoading(true);
      try {
        const paymentRequestSetting = {
          updateNotificationSettingsInput: {
            mail: {
              approvableReport: { isActive: data.approvableReport },
              workflow: workflowRequestValue(data.workflow),
              reportTimeline: { isActive: data.reportTimeline },
            },
          },
        };
        const res = await mutateAsync(paymentRequestSetting);
        if (res) {
          if (_onCallback) {
            notify.success({
              title: t('update_notification_setting_success_message'),
              opts: {
                duration: 3000,
                style: {
                  position: 'fixed',
                },
              },
              bodyProps: {
                disabledSuffix: true,
              },
            });
            _onCallback();
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          error.message = t('update_notification_setting_error_message');
          setError(error);
        }
      } finally {
        setLoading(false);
      }
    },
    [_onCallback, setError, t, mutateAsync, notify]
  );
  return {
    onSubmit,
    isLoading,
  };
};

const workflowRequestValue = (
  workflow: 'every_step' | 'only_final_approval' | 'not_notify'
) => {
  if (workflow === 'only_final_approval')
    return { isActive: true, onlyFinalApproval: true };
  if (workflow === 'every_step')
    return { isActive: true, onlyFinalApproval: false };
  if (workflow === 'not_notify')
    return { isActive: false, onlyFinalApproval: false };

  throw new Error();
};
