import { useTranslation } from '@/i18n';
import { IconInfo, Tooltip } from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

type Props = {
  messages: string[] | undefined;
};

export const TransactionError: FC<Props> = memo(({ messages }) => {
  const { t } = useTranslation();

  if (!messages?.length) return null;

  return (
    <Tooltip
      placement='rightTop'
      title={Array.from(new Set(messages)).join('<br />')}
    >
      <div className='error-container'>
        <IconInfo size={16} />
        <span>{t('error_text')}</span>
      </div>
    </Tooltip>
  );
});

TransactionError.displayName = 'TransactionError';
