import { useTranslation } from '@/i18n';
import classNames from 'classnames';
import { FC, PropsWithChildren, memo } from 'react';
import styles from './RequiredHeader.module.scss';

const cx = classNames.bind(styles);

export type Props = {};

export const RequiredHeader: FC<PropsWithChildren<Props>> = memo(
  ({ children }) => {
    const { t } = useTranslation();
    return (
      <div className={cx(styles['container'])}>
        <div className={cx(styles['content'])}>
          {children}
          <span className={cx(styles['required'])}>{t('required_label')}</span>
        </div>
      </div>
    );
  }
);
RequiredHeader.displayName = 'RequiredHeader';
