import { EmptyAndDragAndDropPdf } from '@/assets';
import { PdfViewer as Viewer } from '@/components/pdfViewer';
import { DraggablePaymentRequest } from '@/components/pdfViewer/feature/DraggablePaymentRequest';
import { Loading, useWindowResize } from '@moneyforward/ap-frontend-components';
import classNames from 'classnames/bind';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import { Header, Props as HeaderProps } from './Header';
import styles from './PdfViewer.module.scss';

const cx = classNames.bind(styles);

export type Props = {
  title: string;
  onChangeFile: (file: File) => void;
  file?: File;
  draggable?: boolean;
  bounds?: string | Element;
  timestamp?: HeaderProps['timestamp'];
  scanner?: HeaderProps['scanner'];
  eDocMeta?: HeaderProps['eDocMeta'];
  zoom?: boolean;
};

export const PdfViewer: FC<Props> = memo(
  ({
    title,
    onChangeFile,
    file,
    draggable,
    bounds,
    timestamp,
    scanner,
    eDocMeta,
    zoom,
  }) => {
    const [src, setSrc] = useState('');
    useEffect(() => {
      let url: string = '';
      if (file) {
        const blob = new Blob([file], { type: file?.type });
        url = URL.createObjectURL(blob);
      }
      setSrc(url);
      return () => {
        if (url) {
          URL.revokeObjectURL(url);
        }
      };
    }, [file]);
    return !draggable ? (
      <>
        <Header
          isEmpty={!src}
          title={title}
          timestamp={timestamp}
          scanner={scanner}
          eDocMeta={eDocMeta}
          zoom={zoom}
        />
        <Viewer
          src={src}
          onChangeFile={onChangeFile}
          defaultPdfUrl={EmptyAndDragAndDropPdf}
        />
      </>
    ) : (
      <DraggablePdfViewer
        title={title}
        onChangeFile={onChangeFile}
        src={src}
        bounds={bounds}
      />
    );
  }
);
PdfViewer.displayName = 'PdfViewer';

type DraggablePdfViewerProps = {
  title: Props['title'];
  onChangeFile: Props['onChangeFile'];
  src?: string;
  bounds?: string | Element;
};

const DraggablePdfViewer: FC<DraggablePdfViewerProps> = memo(
  ({ title, onChangeFile, src, bounds }) => {
    const { innerWidth } = useWindowResize();
    const position = useMemo(
      () => ({
        y: 190,
        x: innerWidth - 10 - 444 - 16,
      }),
      [innerWidth]
    );
    const size = useMemo(() => ({ width: 444, height: 694 }), []);
    return (
      <DraggablePaymentRequest
        title={title}
        src={src}
        bounds={bounds}
        position={position}
        size={size}
        onChangeFile={onChangeFile}
      />
    );
  }
);
DraggablePdfViewer.displayName = 'DraggablePdfViewer';

export const LoadingPdfViewer: FC = memo(({}) => {
  return (
    <div className={cx(styles['loading-container'])}>
      <Loading className={cx(styles['spinner'])} size='large' />
    </div>
  );
});
LoadingPdfViewer.displayName = 'LoadingPdfViewer';
