import { ApPayee, ApPayeeRef } from '@/components/ApPayee';
import { FunctionHeader } from '@/components/FunctionHeader';
import { ListViewBody, ListViewLabel } from '@/components/ListView';
import { SuspenseErrorBoundary } from '@/components/SuspenseErrorBoundary';
import { SuspenseLoading } from '@/components/SuspenseErrorBoundary/fallback';
import { useIsView } from '@/features/invoices/components/Details/Components/Context/ViewEdit';
import { OfficeMemberEdit } from '@/features/invoices/components/Details/Components/ReceivedInvoice/Edit/OfficeMemberEdit';
import { UploadTypeValue } from '@/features/invoices/components/Details/Components/ReceivedInvoice/hooks/UploadType';
import {
  useReceiptTypes,
  useReceivedInvoiceLabel,
} from '@/features/invoices/components/Details/Components/ReceivedInvoice/hooks/useReceivedInvoice';
import { ReceivedInvoiceData } from '@/features/invoices/components/Details/Components/ReceivedInvoice/type';
import { ReceivedInvoiceForm } from '@/features/invoices/components/Details/Components/ReceivedInvoice/types';
import { useGetFlags } from '@/hooks/useFeatureFlag';
import { useTranslation } from '@/i18n';
import {
  Bottom,
  ButtonGroup,
  ButtonV2,
  Form,
  FormRadioButton,
  FormTextArea,
  ListView,
  Top,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSubmit } from './hooks';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  data: ReceivedInvoiceData;
};

export const Edit: FC<Props> = memo(({ data }) => {
  const flag = useGetFlags('RECEIVED_INVOICE_EDIT_PAYEE');
  const { onView } = useIsView();
  const { t } = useTranslation();
  const isAllowEditPayee = useMemo(() => {
    const allowedTypes: UploadTypeValue[] = [
      'manual',
      'peppol_gateway',
      'external_api',
    ];
    return data.uploadType ? allowedTypes.includes(data.uploadType) : false;
  }, [data.uploadType]);
  const methods = useForm<ReceivedInvoiceForm>({
    defaultValues: {
      ...data,
      formPics: data.pics?.toFormValues() ?? [],
      apPayeeId: flag && isAllowEditPayee ? data.apPayeeId : undefined,
    },
    mode: 'all',
  });
  const { setError, resetField } = methods;
  const receiptTypes = useReceiptTypes();
  const {
    operation: operationLabel,
    updateAt: updateAtLabel,
    updateType: updateTypeLabel,
    payee: payeeLabel,
  } = useReceivedInvoiceLabel(data);
  const [picErrors, setPicError] = useState<string[]>([]);
  const { onSubmit, isLoading } = useSubmit(data.id, setError, setPicError);
  const apPayeeRef = useRef<ApPayeeRef | null>(null);

  return (
    <Form<ReceivedInvoiceForm>
      className={cx(styles['container'])}
      formMethod={methods}
      onSubmit={onSubmit}
      testId='received-invoice-edit'
    >
      <FunctionHeader
        functionName={t('received_invoice_info')}
        testId='edit-received-invoice-header'
        button={
          <ButtonGroup>
            <ButtonV2
              color='primary'
              size='xs'
              isSecondary
              onClick={onView}
              isDisabled={isLoading}
              testId='cancel-button'
            >
              {t('cancel')}
            </ButtonV2>
            <ButtonV2
              color='primary'
              size='xs'
              type='submit'
              isLoading={isLoading}
              testId='save-button'
            >
              {t('save')}
            </ButtonV2>
          </ButtonGroup>
        }
      />
      <ListView testId='received-invoice-edit'>
        {flag && (
          <Top>
            <ListViewLabel key='left' testId='payee-label'>
              {t('received_invoice_payee')}
            </ListViewLabel>
            {isAllowEditPayee ? (
              <SuspenseErrorBoundary fallback={<SuspenseLoading />}>
                <ApPayee
                  key='right'
                  name='apPayeeId'
                  control={methods.control}
                  inputId='ap-payee-id'
                  testId='ap-payee-id'
                  inputRef={apPayeeRef}
                  include_inactive={false}
                  include_payee_id={data.apPayeeId}
                  defaultOptions={data.apPayee}
                />
              </SuspenseErrorBoundary>
            ) : (
              <ListViewBody key='right'>{payeeLabel}</ListViewBody>
            )}
          </Top>
        )}
        <Top>
          <ListViewLabel key='left' testId='pic-select-label'>
            {t('received_invoice_pics')}
          </ListViewLabel>
          <OfficeMemberEdit
            control={methods.control}
            setValue={methods.setValue}
            clearErrors={methods.clearErrors}
            picErrors={picErrors}
            setPicError={setPicError}
            resetField={resetField}
            enableFeature={flag}
            apPayeeRef={apPayeeRef}
            originalPics={data.pics}
            originalPayeeId={data.apPayeeId}
          />
        </Top>
        <Top>
          <ListViewLabel key='left' testId='operation-label'>
            {t('received_invoice_operation_status')}
          </ListViewLabel>
          <ListViewBody key='right'>{operationLabel}</ListViewBody>
        </Top>
        <Top>
          <ListViewLabel key='left' testId='update-at-label'>
            {t('received_invoice_update_at')}
          </ListViewLabel>
          <ListViewBody key='right'>{updateAtLabel}</ListViewBody>
        </Top>
        <Top>
          <ListViewLabel key='left' testId='update-type-label'>
            {t('received_invoice_update_type')}
          </ListViewLabel>
          <ListViewBody key='right'>{updateTypeLabel}</ListViewBody>
        </Top>
        <Top>
          <ListViewLabel key='left' testId='receipt-type-label'>
            {t('received_invoice_receipt_type')}
          </ListViewLabel>
          <FormRadioButton<ReceivedInvoiceForm>
            key='right'
            options={receiptTypes}
            control={methods.control}
            name='receiptType'
            inputId='receipt-type'
            // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
            rules={{
              required: { value: true, message: t('required') },
            }}
          />
        </Top>
        {!flag && (
          <Top>
            <ListViewLabel key='left' testId='payee-label'>
              {t('received_invoice_payee')}
            </ListViewLabel>
            <ListViewBody key='right'>{payeeLabel}</ListViewBody>
          </Top>
        )}
        <Bottom>
          <ListViewLabel key='left' testId='memo-label'>
            {t('memo')}
          </ListViewLabel>
          <div key='right' className={cx(styles['memoContainer'])}>
            <FormTextArea<ReceivedInvoiceForm>
              // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
              textAreaProps={{
                style: {
                  height: '58px',
                  minHeight: '58px',
                  maxHeight: '126px',
                  width: '100%',
                },
              }}
              name='memo'
              control={methods.control}
              inputId='memo'
              // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
              rules={{
                maxLength: {
                  value: 2000,
                  message: t('memo_too_long_error'),
                },
              }}
            />
          </div>
        </Bottom>
      </ListView>
    </Form>
  );
});
Edit.displayName = 'ReceivedInvoiceEdit';
