import { useUpdateReceivedInvoiceNotificationSetting } from '@/context/services/notification_setting/notification_setting.service';
import { ReceivedInvoiceNotificationSettingType } from '@/context/services/notification_setting/type';
import { useTranslation } from '@/i18n';
import { useNotification } from '@moneyforward/ap-frontend-components';
import { useCallback, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

export const useSubmit = (
  setError: (error: Error) => void,
  onSubmitCallback: () => void
) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const [isLoading, setLoading] = useState(false);
  const { mutateAsync } = useUpdateReceivedInvoiceNotificationSetting();
  const onSubmit: SubmitHandler<ReceivedInvoiceNotificationSettingType> =
    useCallback(
      async (form) => {
        setLoading(true);
        try {
          const { status, errors } = await mutateAsync({ data: form });
          if (status === 'success') {
            notify.success({
              title: t('received_invoice_setting.update.success'),
              opts: {
                duration: 3000,
                style: {
                  position: 'fixed',
                },
              },
              bodyProps: {
                disabledSuffix: true,
              },
            });
            onSubmitCallback();
          } else {
            throw new Error(errors?.messages?.join('\n'));
          }
        } catch (error) {
          if (error instanceof Error) {
            setError(error);
          }
        } finally {
          setLoading(false);
        }
      },
      [mutateAsync, notify, onSubmitCallback, setError, t]
    );
  return {
    onSubmit,
    isLoading,
  };
};
