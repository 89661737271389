import { useOfficeMemberPositionDeptData } from '@/context/services/invoice_report/OfficeMemberPositionDeptData.service';
import {
  BaseOption,
  useModelsToOptionsWithData,
} from '@/hooks/useTypeToOptions';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import { OfficeMemberDept } from 'ap-openapi';
import { memo, startTransition, useCallback, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<
    FormSelectProps<TFieldValues>['selectProps'],
    'options' | 'comboBox'
  >;
  officeMemberId: string;
  defaultOptions?: BaseOption[];
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

const _OfficeMemberPositionDeptsSelect = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  defaultOptions: _defaultOptions,
  officeMemberId,
  ...rest
}: Props<TFieldValues>) => {
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );
  const [query, setQuery] = useState<string | undefined>(undefined);
  const deptData = useOfficeMemberPositionDeptData(officeMemberId, query);
  const options = useModelsToOptionsWithData<OfficeMemberDept>(
    deptData,
    undefined,
    defaultOptions,
    {
      labelKeys: ['name'],
      value: 'id',
    }
  );

  const onSearch = useCallback((value: string) => {
    startTransition(() => {
      setQuery(value);
    });
  }, []);

  const selectProps: SelectProps = useMemo(() => {
    return {
      onSearchDebounceWait: 600,
      comboBox: true,
      ..._selectProps,
      options: options,
      mode: 'single',
      selectAll: undefined,
      selectAllLabel: undefined,
      onSearch: onSearch,
    };
  }, [_selectProps, onSearch, options]);

  return <FormSelect<TFieldValues> selectProps={selectProps} {...rest} />;
};
_OfficeMemberPositionDeptsSelect.displayName =
  '_OfficeMemberPositionDeptsSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const OfficeMemberPositionDeptsSelect = memo((props) => (
  <_OfficeMemberPositionDeptsSelect {...props} />
)) as typeof _OfficeMemberPositionDeptsSelect;
OfficeMemberPositionDeptsSelect.displayName = 'OfficeMemberPositionDeptsSelect';
