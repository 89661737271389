import {
  defaultBusinessPersonData,
  setParseValidityDate,
  useLazyTpmInvoices,
} from '@/context/services/tpm/hooks';
import { isSuccess } from '@/context/services/type';
import { tpmResponseHasMessage } from '@/libs/typeguard/isError';
import { useCallback } from 'react';
import { useBusinessPersonData } from './Provider';

export const useInvoiceRegistrationNumberHooks = () => {
  const { setBusinessPersonData } = useBusinessPersonData();
  const { fetch, isLoading } = useLazyTpmInvoices();

  const callBackAfterInput13text = useCallback(
    async (number: string) => {
      if (!number || number.length !== 13) {
        setBusinessPersonData(defaultBusinessPersonData);
        return true;
      }
      const resp = await fetch({ number: number });
      if (!isSuccess(resp.status)) {
        setBusinessPersonData(defaultBusinessPersonData);
        if (tpmResponseHasMessage(resp.data)) {
          return {
            error: true,
            message:
              resp.data.message ||
              '国税庁のデータベースに存在しない登録番号です',
          };
        } else {
          return '国税庁のデータベースに存在しない登録番号です';
        }
      } else {
        const data = resp.data;
        const value = setParseValidityDate({
          name: data?.name,
          companyType: data?.kind,
          address: data?.address,
          term: data?.qualified_period?.[0]?.period,
          disabled: false,
          parseValidityDate: defaultBusinessPersonData.parseValidityDate,
        });

        setBusinessPersonData(value);
        return true;
      }
    },
    [fetch, setBusinessPersonData]
  );
  const callBackAfterInput13TextWarningType = useCallback(
    async (number: string) => {
      const result = await callBackAfterInput13text(number);
      if (typeof result === 'string') {
        return result;
      } else if (typeof result === 'boolean' && result) {
        return true;
      } else {
        return result.message;
      }
    },
    [callBackAfterInput13text]
  );

  return {
    callBackAfterInput13text,
    callBackAfterInput13TextWarningType,
    isLoading,
  };
};
