/**
 * NOTE: Since using context would have too wide an impact, we are using singleton to implement it so that it only affects specific hooks.
 * contextを利用すると影響する範囲が大きすぎるのでsingletonを利用して、特定のhooksにだけ影響する様に実装している。
 */
import { useGetForeignCurrencyRates } from '@/context/services/foreign_currency/Detail/ApCurrencyRates.service';
import { useForeignCurrencySupportFlag } from '@/hooks/feature_flag';
import { ApPayeeDetail as ApPayeeModel } from 'ap-openapi';
import { useEffect, useMemo, useState, useSyncExternalStore } from 'react';
import { ForeignCurrency } from './type';

const singletonSelectedApPayee: Map<string, ApPayeeModel | undefined> = new Map<
  string,
  ApPayeeModel | undefined
>();
const listeners = new Set<Function>();

const getSnapshot = () =>
  singletonSelectedApPayee.has(location.href)
    ? singletonSelectedApPayee.get(location.href)
    : undefined;
const subscribe = (callback: Function) => {
  listeners.add(callback);
  return () => listeners.delete(callback);
};

export const updateSelectedApPayee = (newValue: ApPayeeModel | undefined) => {
  singletonSelectedApPayee.set(location.href, newValue);
  listeners.forEach((listener) => listener());
};

export const useSelectedApPayee = () => {
  return useSyncExternalStore(subscribe, getSnapshot);
};

export const useSelectedApPayeeForeignCurrency = (): ForeignCurrency => {
  const payee = useSelectedApPayee();
  const [defaultCurrency, setDefaultCurrency] = useState('JPY');
  const { data, isLoading } = useGetForeignCurrencyRates({
    currency: defaultCurrency,
    transactionDate: payee?.book_date,
    onPageChange: () => {},
  });
  const flag = useForeignCurrencySupportFlag();
  useEffect(() => {
    setDefaultCurrency(payee?.foreign_currency?.currency ?? 'JPY');
  }, [payee?.foreign_currency?.currency]);
  return useMemo(() => {
    const foreignCurrency = payee?.foreign_currency;
    if (!foreignCurrency || !flag)
      return {
        currency: 'JPY',
        rate: 1,
        isLoading: false,
      };

    return {
      currency: foreignCurrency.currency!,
      rate:
        payee.foreign_currency?.currency === 'JPY' ? 1 : data?.[0]?.value ?? 1,
      isLoading: isLoading,
    };
  }, [data, flag, isLoading, payee?.foreign_currency]);
};
