import {
  IconInfo,
  InlineNotification,
  InlineNotificationProps,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import parser from 'html-react-parser';
import { useCallback, useEffect, useState } from 'react';
import styles from './index.module.scss';
const cx = classnames.bind(styles);
type InlineNotificationType = {
  isVisible: boolean;
  key?: string;
  type: InlineNotificationProps['type'];
  message: string;
};
export const WrapInlineNotification = ({
  isVisible,
  key = '1',
  type,
  message,
}: InlineNotificationType) => {
  const [visible, setVisible] = useState<boolean>(isVisible);
  const onClose = useCallback(() => {
    setVisible(false);
  }, []);
  useEffect(() => {
    setVisible(isVisible);
  }, [isVisible]);
  return (
    <div className={cx(styles['errors-invoice-report'])}>
      <InlineNotification
        key={key}
        type={type}
        message={parser(message)}
        icon={<IconInfo size={19} />}
        visible={visible}
        onClick={onClose}
      />
    </div>
  );
};

WrapInlineNotification.displayName = 'WrapInlineNotification';
