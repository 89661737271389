import { createSelectItemContext } from '@/context/SelectComponent/Base';
import {
  Currencies,
  GetCurrenciesListResponse,
  getGetCurrencyUnitsQueryKey,
  useGetCurrencyUnitsInfinite,
} from 'ap-openapi';
import { FC, PropsWithChildren, memo, useCallback } from 'react';

const {
  useFetchPer: useCurrencyPer,
  useItems: useCurrencies,
  useSetItems: useSetCurrencies,
  useCacheKey: useCurrenciesCacheKey,
  useIsLoading: useIsLoadingCurrencies,
  useIsFetching: useIsFetchingCurrencies,
  useFindById: useFindCurrencyById,
  useFindByName,
  Provider: BaseCurrenciesProvider,
} = createSelectItemContext<
  Currencies,
  GetCurrenciesListResponse,
  'id',
  'name',
  'name',
  'id'
>(
  100,
  useGetCurrencyUnitsInfinite,
  'currencies',
  'id',
  {
    key: 'id',
    return: 'name',
    defaultVal: '',
  },
  {
    key: 'name',
    return: 'id',
    defaultVal: '',
  },
  getGetCurrencyUnitsQueryKey
);

export const CurrenciesProvider: FC<PropsWithChildren> = memo(
  ({ children }) => {
    return <BaseCurrenciesProvider>{children}</BaseCurrenciesProvider>;
  }
);

export const useFindCurrencyByName = () => {
  const findByName = useFindByName();

  return useCallback(
    (value: Parameters<typeof findByName>[0]) => {
      return findByName(value?.toUpperCase());
    },
    [findByName]
  );
};

export {
  BaseCurrenciesProvider,
  useCurrencies,
  useCurrenciesCacheKey,
  useCurrencyPer,
  useFindCurrencyById,
  useIsFetchingCurrencies,
  useIsLoadingCurrencies,
  useSetCurrencies,
};

CurrenciesProvider.displayName = 'CurrenciesProvider';
