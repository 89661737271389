import { IconUndo } from '@/features/invoices/components/Details/Components/ReceivedInvoice/Edit/IconUndo';

import { useTranslation } from '@/i18n';
import { IconApproval } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from '../index.module.scss';

const cx = classnames.bind(styles);
export const ChangeNotification: FC<{
  isShowNotification: boolean;
  onUndo: () => void;
}> = memo(({ isShowNotification, onUndo }) => {
  const { t } = useTranslation();

  if (!isShowNotification) return null;

  return (
    <div data-testid='payee-change-notification'>
      <div className={cx(styles['payeeChange'])} aria-live='polite'>
        <IconApproval size={14} />
        <span className={cx(styles['text'])}>
          {t('received_invoice_payee_change')}
        </span>
      </div>
      <div
        className={cx(styles['payeeChange'], styles['undoButton'])}
        onClick={onUndo}
        role='button'
        tabIndex={0}
        aria-label={t('received_invoice_undo_payee_change')}
        data-testid='undo-payee-change'
      >
        <IconUndo />
        <span className={cx(styles['text'])}>
          {t('received_invoice_undo_payee_change')}
        </span>
      </div>
    </div>
  );
});

ChangeNotification.displayName = 'ChangeNotification';
