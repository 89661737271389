import { useLoginInfo } from '@/components/LoginInfo/provider';
import { useInvoiceReportsRoute } from '@/features/InvoiceReport/Edit/components/Context/API';
import { InvoiceTransaction } from '@/features/InvoiceReport/Edit/type';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useCheckEvent = (defaultValue: boolean = false) => {
  const [checked, setChecked] = useState(defaultValue);
  useEffect(() => {
    setChecked(defaultValue);
  }, [defaultValue]);
  const onChange = useCallback((e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  }, []);
  return {
    checked,
    onChange,
  };
};

// https://github.com/moneyforward/ex_web/blob/38a56f45b0f1fe05bcb57dfdcf203a12293d4aa6/client/components/other/invoice_reports/edit.tsx#L156-L166
export const useCheckEventForInvoiceTransaction = (
  invoiceTransaction: InvoiceTransaction[],
  defaultValue: boolean = false
) => {
  const route = useInvoiceReportsRoute();
  const { currentOffice } = useLoginInfo();
  const canDisplayTransactionDetail = invoiceTransaction.some(
    (item) => item.memo !== '' || item.quantity !== 1
  );
  const isCheckedTransactionDetail = useIsCheckedTransactionDetail(
    currentOffice?.id ?? ''
  );
  const [checked, setChecked] = useState(
    canDisplayTransactionDetail ||
      (route === 'normal' && isCheckedTransactionDetail) ||
      defaultValue
  );
  const onChange = useCallback(
    (e: CheckboxChangeEvent) => {
      if (route === 'normal' && currentOffice?.id) {
        updateLocalStorage(e.target.checked, currentOffice.id);
      }
      setChecked(e.target.checked);
    },
    [currentOffice?.id, route]
  );
  return {
    checked,
    onChange,
  };
};

// ref: https://github.com/moneyforward/ex_web/blob/5f7bb77a1f7daf3bbac9535317320ac70b5aac6d/client/components/other/invoice_reports/lib/InvoiceTransactionDetailHelper.ts

const LOCALSTORAGE_KEY = 'showInvoiceTransactionDetail';

enum showInvoiceTransactionDetail {
  OK = 'OK',
  NG = 'NG',
}

export const useIsCheckedTransactionDetail = (officeId: string) => {
  return useMemo(() => {
    const localStorageValue = window.localStorage.getItem(
      `${LOCALSTORAGE_KEY}_${officeId}`
    );
    return localStorageValue === showInvoiceTransactionDetail.OK;
  }, [officeId]);
};

export const updateLocalStorage = (
  hasCheckedTransactionDetail: boolean,
  officeId: string
) => {
  window.localStorage.setItem(
    `${LOCALSTORAGE_KEY}_${officeId}`,
    hasCheckedTransactionDetail
      ? showInvoiceTransactionDetail.OK
      : showInvoiceTransactionDetail.NG
  );
};
