import { ErrorBoundary } from '@/components/Rollbar';
import { DeleteModal } from '@/features/foreign_currency_settings/components/DeleteModal';
import { useColumns } from '@/features/foreign_currency_settings/components/Detail/CurrencyRateTable/Columns/columns';
import { useDeleteCurrencyRate } from '@/features/foreign_currency_settings/components/Detail/CurrencyRateTable/hooks/useDeleteCurrencyRate';
import { useUpdateCurrencyRateSubmit } from '@/features/foreign_currency_settings/components/Detail/CurrencyRateTable/hooks/useUpdateCurrencyRate';
import { ForeignCurrencyRatesDrawer } from '@/features/foreign_currency_settings/components/Drawer';
import { CurrencyRateFormType } from '@/features/foreign_currency_settings/components/Drawer/Drawer';
import { useSubmitCreateCurrencyRate } from '@/features/foreign_currency_settings/components/List/hooks/useSubmitCreateCurrencyRate';
import { ForeignCurrencyDrawerMode } from '@/features/foreign_currency_settings/type';
import { useTranslation } from '@/i18n';
import {
  ButtonV2,
  ModernPaginationProps,
  Table,
} from '@moneyforward/ap-frontend-components';
import { getDayjsDate } from 'date-util';
import { FC, memo, Suspense, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DataTableCurrencyRate } from './type';

export type Props = {
  currency: string | undefined;
  data: DataTableCurrencyRate[];
  onCallbackRefetch: () => void;
  pagination: ModernPaginationProps;
  isLoading: boolean;
};

export const CurrencyRateTableError: FC = memo(() => {
  const columns = useColumns(
    () => {},
    () => {},
    true
  );

  const data: DataTableCurrencyRate[] = [];

  const pagination = useMemo(
    () => ({
      currentPage: 1,
      maxPage: 1,
      perPage: 1,
      maxRecord: 0,
    }),
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      legacy={false}
      emptyType='error'
      pagination={pagination}
    />
  );
});

CurrencyRateTableError.displayName = 'CurrencyRateTableError';

export const CurrencyRateTableLoading: FC = memo(() => {
  const columns = useColumns(
    () => {},
    () => {},
    true
  );

  const data: DataTableCurrencyRate[] = [];

  const pagination = useMemo(
    () => ({
      currentPage: 1,
      maxPage: 1,
      perPage: 1,
      maxRecord: 0,
    }),
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      legacy={false}
      emptyType='object'
      pagination={pagination}
      hideFooterPagination
      loading
    />
  );
});

CurrencyRateTableLoading.displayName = 'CurrencyRateTableLoading';

export const CurrencyRateTable: FC<Props> = memo(
  ({ data, currency, onCallbackRefetch, pagination, isLoading }) => {
    const { t } = useTranslation();
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<DataTableCurrencyRate>({
      id: '',
      rate: undefined,
      start_date: '',
      end_date: '',
      period: '',
      updated_at: '',
    });
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [isDisabled] = useState(false);
    const [drawerMode, setDrawerMode] = useState<ForeignCurrencyDrawerMode>(
      ForeignCurrencyDrawerMode.ADD_MODE
    );
    const [errorMsg, setErrorMsg] = useState<string>('');
    const methods = useForm<CurrencyRateFormType>({
      defaultValues: {
        currency: currency,
        value: undefined,
        date: [],
      },
      mode: 'all',
    });
    const { setError, reset } = methods;

    const setErrorNotificationMsg = useCallback((error: string) => {
      setErrorMsg(error);
    }, []);

    const closeErrorNotificationMsg = useCallback(() => {
      setErrorMsg('');
    }, []);

    const onCloseDeleteModal = useCallback(() => {
      setErrorMsg('');
      reset();
      setIsOpenDeleteModal(false);
    }, [reset]);

    const resetForm = useCallback(
      (value?: DataTableCurrencyRate) => {
        reset({
          currency: currency,
          value: value?.rate,
          date: value
            ? [getDayjsDate(value?.start_date), getDayjsDate(value?.end_date)]
            : [],
        });
      },
      [currency, reset]
    );

    const onClickAddRate = useCallback(() => {
      resetForm();
      setIsDrawerVisible(true);
      setDrawerMode(ForeignCurrencyDrawerMode.ADD_MODE);
    }, [resetForm]);

    const onClickShowDeleteModal = useCallback(
      (value: DataTableCurrencyRate) => {
        setIsOpenDeleteModal(true);
        setSelectedItem(value);
      },
      []
    );

    const onClickEditRate = useCallback(
      (value: DataTableCurrencyRate) => {
        setDrawerMode(ForeignCurrencyDrawerMode.EDIT_MODE);
        resetForm(value);
        setSelectedItem(value);
        setIsDrawerVisible(true);
      },
      [resetForm]
    );

    const onCloseDrawer = useCallback(() => {
      setIsDrawerVisible(false);
      reset();
    }, [reset]);

    const onDeleteCurrencyRate = useDeleteCurrencyRate(onCallbackRefetch);

    const onDeleteItem = useCallback(async () => {
      if (selectedItem) {
        await onDeleteCurrencyRate(selectedItem.id);
      }
      onCloseDeleteModal();
    }, [onCloseDeleteModal, onDeleteCurrencyRate, selectedItem]);

    const setInlineErrorMsg = useCallback((error: string) => {
      setErrorMsg(error);
    }, []);

    const closeInlineErrorMsg = useCallback(() => {
      setErrorMsg('');
    }, []);

    const columns = useColumns(
      onClickEditRate,
      onClickShowDeleteModal,
      isDisabled
    );

    const onUpdateCallback = useCallback(() => {
      onCallbackRefetch();
      reset();
      setIsDrawerVisible(false);
    }, [onCallbackRefetch, reset]);

    const { onSubmit } = useUpdateCurrencyRateSubmit({
      id: selectedItem?.id,
      setErrorNotification: setErrorNotificationMsg,
      setValidationError: setError,
      onUpdateCallback: onUpdateCallback,
    });

    const { onSubmitCreate } = useSubmitCreateCurrencyRate({
      setInlineErrorMsg,
      setValidationError: setError,
      closeInlineErrorMsg,
      closeDrawer: onCloseDrawer,
      currency,
    });

    return (
      <ErrorBoundary fallback={CurrencyRateTableError}>
        <Suspense fallback={<CurrencyRateTableLoading />}>
          <Table
            columns={columns}
            data={data}
            legacy={false}
            emptyType='object'
            pagination={pagination}
            hideFooterPagination
            loading={isLoading}
            tableAction={
              <ButtonV2
                isSecondary
                isDisabled={isDisabled}
                onClick={onClickAddRate}
              >
                {t('add')}
              </ButtonV2>
            }
          />
          <DeleteModal
            title={t('foreign_currency.detail.modal.delete.title')}
            description={t('foreign_currency.detail.modal.delete.description')}
            onClose={onCloseDeleteModal}
            open={isOpenDeleteModal}
            onDelete={onDeleteItem}
          />
          <ForeignCurrencyRatesDrawer
            mode={drawerMode}
            visible={isDrawerVisible}
            onClose={onCloseDrawer}
            onSave={
              drawerMode === ForeignCurrencyDrawerMode.EDIT_MODE
                ? onSubmit
                : onSubmitCreate
            }
            methods={methods}
            closeErrorMsg={closeErrorNotificationMsg}
            errorMsg={errorMsg}
          />
        </Suspense>
      </ErrorBoundary>
    );
  }
);
CurrencyRateTable.displayName = 'CurrencyRateTable';
