import { useImportCurrency } from '@/features/foreign_currency_settings/components/List/hooks/useImportCurrency';
import { SampleCSV } from '@/features/foreign_currency_settings/components/List/ImportCSV/SampleCSV';
import { UPLOAD_SINGLE_FILE } from '@/features/foreign_currency_settings/constant';
import { useTranslation } from '@/i18n';
import {
  ButtonGroup,
  ButtonV2,
  Form,
  InlineNotification,
  UploadDrawerLeadingFiles,
  UploadFiles,
} from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styles from './ImportCSV.module.scss';

const cx = classNames.bind(styles);

export type Props = {
  isDisabled?: boolean;
};

export type ImportCurrencyForm = {
  file: UploadFiles;
};

export const ImportCSV: FC<Props> = memo(({ isDisabled }) => {
  const { t } = useTranslation();
  const [errorMsg, setErrorMsg] = useState<string>('');

  const methods = useForm<ImportCurrencyForm>({
    defaultValues: {
      file: [],
    },
  });

  const [open, setOpen] = useState(false);
  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const { reset, watch } = methods;

  const onClose = useCallback(() => {
    setOpen(false);
    reset();
    setErrorMsg('');
  }, [reset]);

  const { onSubmit } = useImportCurrency({
    setErrorMsg,
    reset,
    onClose,
  });
  const fileWatch = watch('file');

  useEffect(() => {
    fileWatch.length > 0 && setErrorMsg('');
  }, [fileWatch]);

  const uploader = {
    append: false,
    description: <>{t('foreign_currency.import_csv.description')}</>,
    accept: '.csv',
    name: 'file' as const,
    control: methods.control,
  };

  return (
    <div>
      <ButtonV2
        onClick={onOpen}
        isTertiary
        testId='import-button'
        isDisabled={isDisabled}
      >
        {t('import_label')}
      </ButtonV2>
      <Form<ImportCurrencyForm> formMethod={methods} onSubmit={onSubmit}>
        <div className={cx(styles['uploader-container'])}>
          {/* Update to Import component when it is ready */}
          <UploadDrawerLeadingFiles
            buttons={
              <div>
                <ButtonGroup>
                  <ButtonV2 color='primary' isSecondary onClick={onClose}>
                    {t('cancel')}
                  </ButtonV2>
                  <ButtonV2
                    color='primary'
                    type='submit'
                    isDisabled={methods.getValues('file').length === 0}
                    testId='submit-button'
                  >
                    {t('import_label')}
                  </ButtonV2>
                </ButtonGroup>
              </div>
            }
            title={t('foreign_currency.import_csv.title')}
            open={open}
            size='slim'
            onClose={onClose}
            control={methods.control}
            name='file'
            arrayName='file'
            uploader={uploader}
            uploadFileLimitTotal={UPLOAD_SINGLE_FILE}
            header={
              <>
                <InlineNotification
                  message={errorMsg}
                  type='error'
                  visible={errorMsg ? true : false}
                  onClick={() => setErrorMsg('')}
                />
                <div className={cx(styles['header-container'])}>
                  {t('foreign_currency.import_csv.header')}
                </div>
              </>
            }
            details={<SampleCSV />}
          />
        </div>
      </Form>
    </div>
  );
});
ImportCSV.displayName = 'ImportCSV';
