import {
  ConfirmModalArgs,
  useConfirmModal,
} from '@/components/ConfirmationModal';
import { useSubmitInvoiceReport } from '@/context/services/reportsType/invoiceReports/Edit.service';
import { tpmData } from '@/context/services/tpm/type';
import { InvoiceKindValues } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/services/type';
import { useInvoiceReportNavigation } from '@/features/InvoiceReport/Edit/components/hooks/useGetInvoiceReportPath';
import {
  ApiErrors,
  convertFlashMessageTypeToInlineNotificationType,
  ErrorFields,
} from '@/features/InvoiceReport/Edit/components/type';
import {
  InvoiceTransaction,
  PaymentRequestForm,
} from '@/features/InvoiceReport/Edit/type';
import { useTranslation } from '@/i18n';
import yup from '@/libs/validation';
import { useParams } from '@/router';
import { parseErrorSchema, SendErrorTracking } from '@/utils/errors';
import { InlineNotificationProps } from '@moneyforward/ap-frontend-components';
import {
  FormInputIdMap,
  InvoiceReportDetailFormInputsResponse,
  InvoiceReportDetailResponseFlashMessagesItem,
  useCsrfToken,
} from 'ap-openapi';
import dayjs from 'dayjs';
import {
  BaseSyntheticEvent,
  FC,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Control,
  ErrorOption,
  FieldPath,
  SubmitHandler,
  useWatch,
} from 'react-hook-form';
import { ValidationError } from 'yup';
import { useGetInvoiceKind } from '../InvoiceTransaction/hooks/useInvoiceKind';
import { useSelectorExcise } from '../InvoiceTransaction/hooks/useSelectorExcise';

export const useReportForm = () => {
  const { getIsZeroPer } = useSelectorExcise();
  const getInvoiceKind = useGetInvoiceKind();
  const updateInvoiceKind = useCallback(
    (
      invoice: InvoiceTransaction,
      hasSpecialException: boolean,
      isDutyFree: boolean,
      tpmData: tpmData
    ): InvoiceTransaction => {
      const dealDate = dayjs(invoice.dealDate);
      const exciseId = invoice.drExciseId;
      const invoiceKind = getInvoiceKind({
        dealDate: dealDate,
        tpmData,
        isZeroPer: getIsZeroPer(exciseId),
        hasSpecialException: Boolean(hasSpecialException),
        isDutyFree,
      });
      invoice.invoiceKind = invoiceKind;
      return invoice;
    },
    [getInvoiceKind, getIsZeroPer]
  );

  const getInvoiceKindValue = useCallback(
    (
      hasSpecialException: boolean,
      isDutyFree: boolean,
      tpmData: tpmData,
      exciseId?: string,
      dealDateValue?: string
    ): InvoiceKindValues | '' => {
      const dealDate = dayjs(dealDateValue || '');
      const invoiceKind = getInvoiceKind({
        dealDate: dealDate,
        tpmData,
        isZeroPer: getIsZeroPer(exciseId),
        hasSpecialException: Boolean(hasSpecialException),
        isDutyFree,
      });
      return invoiceKind;
    },
    [getInvoiceKind, getIsZeroPer]
  );

  return { updateInvoiceKind, getInvoiceKindValue };
};

export const useHasSpecialException = (
  specialExceptionValue: PaymentRequestForm['specialException']
) => {
  return useMemo(() => {
    if (!specialExceptionValue) {
      return false;
    }

    return (
      specialExceptionValue.value !== null &&
      specialExceptionValue.value !== undefined &&
      specialExceptionValue.value !== '0'
    );
  }, [specialExceptionValue]);
};

export const useHasSpecialExceptionWithControl = (
  control: Control<PaymentRequestForm>
) => {
  const specialExceptionValue = useWatch<
    PaymentRequestForm,
    'specialException'
  >({
    control: control,
    name: 'specialException',
  });
  return useHasSpecialException(specialExceptionValue);
};

export const useSubmitPaymentReport = (
  schema: yup.ObjectSchema<{
    invoiceTransactions: Partial<InvoiceTransaction>[];
  }>,
  formInputIdMap: FormInputIdMap,
  invoicePayeeTypeInfo: InvoiceReportDetailFormInputsResponse,
  invoiceFileTypeId: string,
  setError: (name: FieldPath<PaymentRequestForm>, error: ErrorOption) => void,
  handleInlineNotificationErrors: (error: ErrorFields | ApiErrors) => boolean,
  handleInvalidScroll: (ele: Element | undefined, name: string) => void
) => {
  const [isLoading, setLoading] = useState(false);
  const submitReport = useSubmitInvoiceReport(
    formInputIdMap,
    invoicePayeeTypeInfo,
    invoiceFileTypeId
  );
  const onSaveSubmit = useCallback(
    async (values: PaymentRequestForm) => {
      handleInlineNotificationErrors({
        invoiceReport: undefined,
        invoiceTransactions: undefined,
        invoiceTransactionsAlert: undefined,
        invoiceTransactionsRow: undefined,
      });
      try {
        const { invoiceReportResponse, invoiceTransactionResponse } =
          await submitReport(values);
        const newErrors: ApiErrors = {
          invoiceReport:
            invoiceReportResponse?.status === 'error'
              ? invoiceReportResponse?.errors?.messages?.join('<br/>')
              : undefined,
          invoiceTransactions:
            invoiceTransactionResponse?.status === 'error'
              ? invoiceTransactionResponse?.errors?.messages?.join('<br/>')
              : undefined,
          invoiceTransactionsAlert: undefined,
          invoiceTransactionsRow:
            invoiceTransactionResponse?.status === 'error'
              ? invoiceTransactionResponse?.errors
              : undefined,
        };
        handleInlineNotificationErrors(newErrors);
        return !newErrors.invoiceReport && !newErrors.invoiceTransactions;
      } catch (err) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        throw new SendErrorTracking(err as unknown as Error);
      }
    },
    [handleInlineNotificationErrors, submitReport]
  );

  const { onDeleteSubmit, DeleteForm, ConfirmModal } = useDeleteForm();
  const { goToInvoiceReportIndex, goToInvoiceReportSelectApprover } =
    useInvoiceReportNavigation();

  const onSubmit: SubmitHandler<PaymentRequestForm> = useCallback(
    async (values: PaymentRequestForm, e?: BaseSyntheticEvent) => {
      try {
        setLoading(true);
        try {
          await schema.validate(values, {
            abortEarly: false,
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (!(e instanceof ValidationError)) {
            throw e;
          }
          const errFields = parseErrorSchema(e, true);
          Object.entries(errFields).forEach(([key, err]) => {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            setError(key as FieldPath<PaymentRequestForm>, err);
          });
          const errNames = Object.keys(errFields);
          if (errNames.length > 0 && errNames[0]) {
            handleInvalidScroll(undefined, errNames[0]);
          }
          if (handleInlineNotificationErrors(errFields)) {
            return;
          }
        }
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const event = e?.nativeEvent as unknown as SubmitEvent;
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const action = event.submitter?.getAttribute('formAction') as string;
        switch (action) {
          case 'draft':
            (await onSaveSubmit(values)) && goToInvoiceReportIndex();
            break;
          case 'next':
            (await onSaveSubmit(values)) && goToInvoiceReportSelectApprover();
            break;
          default:
            break;
        }
      } finally {
        setLoading(false);
      }
    },
    [
      schema,
      handleInlineNotificationErrors,
      setError,
      handleInvalidScroll,
      onSaveSubmit,
      goToInvoiceReportIndex,
      goToInvoiceReportSelectApprover,
    ]
  );
  return {
    onSubmit,
    isLoading,
    onDeleteSubmit,
    DeleteForm,
    ConfirmModal,
  };
};

export const useConfirm = () => {
  const { t } = useTranslation();
  const args: ConfirmModalArgs = useMemo(
    () => ({
      title: t('payment_request_delete_title'),
      body: t('payment_request_delete_body'), // この申請を削除してよいですか？
      okButtonProps: {
        color: 'danger',
        children: t('delete'),
      },
    }),
    [t]
  );
  return useConfirmModal(args);
};

const useDeleteForm = () => {
  const ref = useRef<HTMLFormElement | null>(null);
  const { confirm, ConfirmModal } = useConfirm();

  const onDeleteSubmit = useCallback(async () => {
    if (ref.current) {
      if (await confirm()) {
        ref.current.submit();
      }
    }
  }, [confirm]);
  const { report_type_id, report_id } = useParams(
    '/report_types/:report_type_id/invoice_reports/:report_id/edit'
  );
  const url = `/report_types/${report_type_id}/invoice_reports/${report_id}`;
  const csrfToken = useCsrfToken();
  const DeleteForm: FC = useCallback(() => {
    return (
      <form ref={ref} action={url} method='post' hidden>
        <input name='_method' value='delete' hidden />
        <input name='authenticity_token' value={csrfToken} hidden />
      </form>
    );
  }, [csrfToken, url]);
  return {
    DeleteForm,
    ConfirmModal,
    onDeleteSubmit,
  };
};

type FlashMessageInlineProps = {
  type: InlineNotificationProps['type'];
  message?: string;
};
export const useFlashMessageInvoiceReport = (
  messages: InvoiceReportDetailResponseFlashMessagesItem[] | undefined
): FlashMessageInlineProps[] | undefined => {
  return useMemo(() => {
    if (!messages) return undefined;
    const grouped: Record<string, FlashMessageInlineProps> = messages.reduce(
      (acc: Record<string, FlashMessageInlineProps>, curr) => {
        const key = convertFlashMessageTypeToInlineNotificationType(curr.type);
        if (acc[key]) {
          if (curr.message) {
            acc[key] = {
              ...curr,
              type: key,
              message: acc[key]?.message
                ? `${acc[key]?.message}<br/>${curr.message}`
                : curr.message,
            };
          }
        } else {
          acc[key] = { ...curr, type: key };
        }
        return acc;
      },
      {}
    );
    return Object.values(grouped);
  }, [messages]);
};
