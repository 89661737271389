/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseInfiniteQueryOptions,
  UseSuspenseInfiniteQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { ApPayee } from '../model/apPayee';
import type { ApPayeeDetail } from '../model/apPayeeDetail';
import type { BadRequestResponse } from '../model/badRequestResponse';
import type { CreateApPayeeErrorResponse } from '../model/createApPayeeErrorResponse';
import type { CreateApPayeeRequest } from '../model/createApPayeeRequest';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { GetApPayeeDefaultPropertyParams } from '../model/getApPayeeDefaultPropertyParams';
import type { GetApPayeeDetailDisplayResponse } from '../model/getApPayeeDetailDisplayResponse';
import type { GetApPayeeDetailResponse } from '../model/getApPayeeDetailResponse';
import type { GetApPayeesParams } from '../model/getApPayeesParams';
import type { GetApPayeesResponse } from '../model/getApPayeesResponse';
import type { GetOfficeMembersParams } from '../model/getOfficeMembersParams';
import type { GetOfficeMembersResponse } from '../model/getOfficeMembersResponse';
import type { NotFoundResponse } from '../model/notFoundResponse';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import type { UnprocessableEntityResponse } from '../model/unprocessableEntityResponse';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType, BodyType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Get a list of payees (existing API)
 */
export const useGetApPayeesHook = () => {
  const getApPayees = useCustomClient<GetApPayeesResponse>();

  return (
    params?: GetApPayeesParams,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return getApPayees(
      { url: `/api/js/ap_payees`, method: 'GET', params },
      options
    );
  };
};

export const getGetApPayeesQueryKey = (params?: GetApPayeesParams) => {
  return [`/api/js/ap_payees`, ...(params ? [params] : [])] as const;
};

export const useGetApPayeesInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
    GetApPayeesParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApPayeesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
        QueryKey,
        GetApPayeesParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApPayeesQueryKey(params);

  const getApPayees = useGetApPayeesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
    QueryKey,
    GetApPayeesParams['page']
  > = ({ pageParam }) =>
    getApPayees(
      { ...params, page: pageParam || params?.['page'] },
      requestOptions
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
    TError,
    TData,
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
    QueryKey,
    GetApPayeesParams['page']
  > & { queryKey: QueryKey };
};

export type GetApPayeesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>
>;
export type GetApPayeesInfiniteQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of payees (existing API)
 */
export const useGetApPayeesInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
    GetApPayeesParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApPayeesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
        QueryKey,
        GetApPayeesParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApPayeesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetApPayeesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApPayeesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApPayeesQueryKey(params);

  const getApPayees = useGetApPayeesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>
  > = () => getApPayees(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApPayeesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>
>;
export type GetApPayeesSuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of payees (existing API)
 */
export const useGetApPayeesSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApPayeesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApPayeesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetApPayeesSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
    GetApPayeesParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApPayeesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
        QueryKey,
        GetApPayeesParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApPayeesQueryKey(params);

  const getApPayees = useGetApPayeesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
    QueryKey,
    GetApPayeesParams['page']
  > = ({ pageParam }) =>
    getApPayees(
      { ...params, page: pageParam || params?.['page'] },
      requestOptions
    );

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
    TError,
    TData,
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
    QueryKey,
    GetApPayeesParams['page']
  > & { queryKey: QueryKey };
};

export type GetApPayeesSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>
>;
export type GetApPayeesSuspenseInfiniteQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of payees (existing API)
 */
export const useGetApPayeesSuspenseInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
    GetApPayeesParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApPayeesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetApPayeesHook>>>,
        QueryKey,
        GetApPayeesParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApPayeesSuspenseInfiniteQueryOptions(
    params,
    options
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create ap payee
 */
export const useCreateApPayeeHook = () => {
  const createApPayee = useCustomClient<ApPayee>();

  return (
    createApPayeeRequest: BodyType<CreateApPayeeRequest>,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return createApPayee(
      {
        url: `/api/js/ap_payees`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: createApPayeeRequest,
      },
      options
    );
  };
};

export const useCreateApPayeeMutationOptions = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | CreateApPayeeErrorResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateApPayeeHook>>>,
    TError,
    { data: BodyType<CreateApPayeeRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateApPayeeHook>>>,
  TError,
  { data: BodyType<CreateApPayeeRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const createApPayee = useCreateApPayeeHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateApPayeeHook>>>,
    { data: BodyType<CreateApPayeeRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return createApPayee(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateApPayeeMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateApPayeeHook>>>
>;
export type CreateApPayeeMutationBody = BodyType<CreateApPayeeRequest>;
export type CreateApPayeeMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | CreateApPayeeErrorResponse
>;

/**
 * @summary Create ap payee
 */
export const useCreateApPayee = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | CreateApPayeeErrorResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateApPayeeHook>>>,
    TError,
    { data: BodyType<CreateApPayeeRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions = useCreateApPayeeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get a Setting of payees (existing API)
 */
export const useGetApPayeeDefaultPropertyHook = () => {
  const getApPayeeDefaultProperty = useCustomClient<ApPayeeDetail>();

  return (
    id: string,
    params?: GetApPayeeDefaultPropertyParams,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getApPayeeDefaultProperty(
      { url: `/api/js/ap_payees/${id}`, method: 'GET', params, signal },
      options
    );
  };
};

export const getGetApPayeeDefaultPropertyQueryKey = (
  id: string,
  params?: GetApPayeeDefaultPropertyParams
) => {
  return [`/api/js/ap_payees/${id}`, ...(params ? [params] : [])] as const;
};

export const useGetApPayeeDefaultPropertyQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApPayeeDefaultPropertyHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  params?: GetApPayeeDefaultPropertyParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useGetApPayeeDefaultPropertyHook>>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApPayeeDefaultPropertyQueryKey(id, params);

  const getApPayeeDefaultProperty = useGetApPayeeDefaultPropertyHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeeDefaultPropertyHook>>>
  > = ({ signal }) =>
    getApPayeeDefaultProperty(id, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeeDefaultPropertyHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApPayeeDefaultPropertyQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApPayeeDefaultPropertyHook>>>
>;
export type GetApPayeeDefaultPropertyQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a Setting of payees (existing API)
 */
export const useGetApPayeeDefaultProperty = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApPayeeDefaultPropertyHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  params?: GetApPayeeDefaultPropertyParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useGetApPayeeDefaultPropertyHook>>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApPayeeDefaultPropertyQueryOptions(
    id,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetApPayeeDefaultPropertySuspenseQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApPayeeDefaultPropertyHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  params?: GetApPayeeDefaultPropertyParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useGetApPayeeDefaultPropertyHook>>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApPayeeDefaultPropertyQueryKey(id, params);

  const getApPayeeDefaultProperty = useGetApPayeeDefaultPropertyHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeeDefaultPropertyHook>>>
  > = ({ signal }) =>
    getApPayeeDefaultProperty(id, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeeDefaultPropertyHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApPayeeDefaultPropertySuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApPayeeDefaultPropertyHook>>>
>;
export type GetApPayeeDefaultPropertySuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a Setting of payees (existing API)
 */
export const useGetApPayeeDefaultPropertySuspense = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApPayeeDefaultPropertyHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  params?: GetApPayeeDefaultPropertyParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useGetApPayeeDefaultPropertyHook>>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApPayeeDefaultPropertySuspenseQueryOptions(
    id,
    params,
    options
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 * @summary Get details of a payee
 */
export const useGetApPayeesDetailHook = () => {
  const getApPayeesDetail = useCustomClient<GetApPayeeDetailResponse>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getApPayeesDetail(
      { url: `/api/js/ap_payees/${id}/detail`, method: 'GET', signal },
      options
    );
  };
};

export const getGetApPayeesDetailQueryKey = (id: string) => {
  return [`/api/js/ap_payees/${id}/detail`] as const;
};

export const useGetApPayeesDetailQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApPayeesDetailQueryKey(id);

  const getApPayeesDetail = useGetApPayeesDetailHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailHook>>>
  > = ({ signal }) => getApPayeesDetail(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApPayeesDetailQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailHook>>>
>;
export type GetApPayeesDetailQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @deprecated
 * @summary Get details of a payee
 */
export const useGetApPayeesDetail = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApPayeesDetailQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetApPayeesDetailSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApPayeesDetailQueryKey(id);

  const getApPayeesDetail = useGetApPayeesDetailHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailHook>>>
  > = ({ signal }) => getApPayeesDetail(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApPayeesDetailSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailHook>>>
>;
export type GetApPayeesDetailSuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @deprecated
 * @summary Get details of a payee
 */
export const useGetApPayeesDetailSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApPayeesDetailSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get details of a payee
 */
export const useGetApPayeesDetailDisplayHook = () => {
  const getApPayeesDetailDisplay =
    useCustomClient<GetApPayeeDetailDisplayResponse>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getApPayeesDetailDisplay(
      { url: `/api/js/ap_payees/${id}/details/display`, method: 'GET', signal },
      options
    );
  };
};

export const getGetApPayeesDetailDisplayQueryKey = (id: string) => {
  return [`/api/js/ap_payees/${id}/details/display`] as const;
};

export const useGetApPayeesDetailDisplayQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApPayeesDetailDisplayHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailDisplayHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApPayeesDetailDisplayQueryKey(id);

  const getApPayeesDetailDisplay = useGetApPayeesDetailDisplayHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailDisplayHook>>>
  > = ({ signal }) => getApPayeesDetailDisplay(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailDisplayHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApPayeesDetailDisplayQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailDisplayHook>>>
>;
export type GetApPayeesDetailDisplayQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get details of a payee
 */
export const useGetApPayeesDetailDisplay = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApPayeesDetailDisplayHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailDisplayHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApPayeesDetailDisplayQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetApPayeesDetailDisplaySuspenseQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApPayeesDetailDisplayHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailDisplayHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApPayeesDetailDisplayQueryKey(id);

  const getApPayeesDetailDisplay = useGetApPayeesDetailDisplayHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailDisplayHook>>>
  > = ({ signal }) => getApPayeesDetailDisplay(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailDisplayHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApPayeesDetailDisplaySuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailDisplayHook>>>
>;
export type GetApPayeesDetailDisplaySuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get details of a payee
 */
export const useGetApPayeesDetailDisplaySuspense = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApPayeesDetailDisplayHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApPayeesDetailDisplayHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApPayeesDetailDisplaySuspenseQueryOptions(
    id,
    options
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get a list of office members (existing API)
 */
export const useGetOfficeMembersHook = () => {
  const getOfficeMembers = useCustomClient<GetOfficeMembersResponse>();

  return (
    params?: GetOfficeMembersParams,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return getOfficeMembers(
      { url: `/api/js/office_members`, method: 'GET', params },
      options
    );
  };
};

export const getGetOfficeMembersQueryKey = (
  params?: GetOfficeMembersParams
) => {
  return [`/api/js/office_members`, ...(params ? [params] : [])] as const;
};

export const useGetOfficeMembersInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>,
    GetOfficeMembersParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetOfficeMembersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>,
        QueryKey,
        GetOfficeMembersParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetOfficeMembersQueryKey(params);

  const getOfficeMembers = useGetOfficeMembersHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>,
    QueryKey,
    GetOfficeMembersParams['page']
  > = ({ pageParam }) =>
    getOfficeMembers(
      { ...params, page: pageParam || params?.['page'] },
      requestOptions
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>,
    TError,
    TData,
    Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>,
    QueryKey,
    GetOfficeMembersParams['page']
  > & { queryKey: QueryKey };
};

export type GetOfficeMembersInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>
>;
export type GetOfficeMembersInfiniteQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of office members (existing API)
 */
export const useGetOfficeMembersInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>,
    GetOfficeMembersParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetOfficeMembersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>,
        QueryKey,
        GetOfficeMembersParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetOfficeMembersInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetOfficeMembersSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>,
    GetOfficeMembersParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetOfficeMembersParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>,
        QueryKey,
        GetOfficeMembersParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetOfficeMembersQueryKey(params);

  const getOfficeMembers = useGetOfficeMembersHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>,
    QueryKey,
    GetOfficeMembersParams['page']
  > = ({ pageParam }) =>
    getOfficeMembers(
      { ...params, page: pageParam || params?.['page'] },
      requestOptions
    );

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>,
    TError,
    TData,
    Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>,
    QueryKey,
    GetOfficeMembersParams['page']
  > & { queryKey: QueryKey };
};

export type GetOfficeMembersSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>
>;
export type GetOfficeMembersSuspenseInfiniteQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of office members (existing API)
 */
export const useGetOfficeMembersSuspenseInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>,
    GetOfficeMembersParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetOfficeMembersParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetOfficeMembersHook>>>,
        QueryKey,
        GetOfficeMembersParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetOfficeMembersSuspenseInfiniteQueryOptions(
    params,
    options
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
