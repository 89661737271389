import { useApPayeeSelectedValue } from '@/components/ReportForm/Modals/ChangeXXX';
import { ApPayeeDetail } from 'ap-openapi';
import { stringToDayjs } from 'date-util';
import { useCallback, useEffect } from 'react';
import {
  FieldPath,
  FieldValues,
  Path,
  PathValue,
  useFormContext,
} from 'react-hook-form';

type ChangePayeeValueArgs<TFieldValues extends FieldValues> = {
  bookDateName: FieldPath<TFieldValues>;
  dueDateName: FieldPath<TFieldValues>;
};

export const useUpdatePayeeRelatedInfo = <TFieldValues extends FieldValues>(
  bookDateName: FieldPath<TFieldValues>,
  dueDateName: FieldPath<TFieldValues>
) => {
  const { setValue } = useFormContext<TFieldValues>();
  return useCallback(
    (payee: ApPayeeDetail | undefined) => {
      const bookDate = stringToDayjs(payee?.book_date);
      if (bookDate)
        setValue(
          bookDateName,
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          bookDate as PathValue<TFieldValues, Path<TFieldValues>>
        );
      const paymentDate = stringToDayjs(payee?.payment_date);
      if (paymentDate) {
        setValue(
          dueDateName,
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          paymentDate as PathValue<TFieldValues, Path<TFieldValues>>
        );
      }
    },
    [bookDateName, dueDateName, setValue]
  );
};

export const useChangePayeeValue = <TFieldValues extends FieldValues>({
  bookDateName,
  dueDateName,
}: ChangePayeeValueArgs<TFieldValues>) => {
  const selectedValue = useApPayeeSelectedValue();
  const { resetField } = useFormContext<TFieldValues>();
  const updatePayeeRelatedInfo = useUpdatePayeeRelatedInfo(
    bookDateName,
    dueDateName
  );
  useEffect(() => {
    if (selectedValue === undefined) return;
    const { type, args: payee } = selectedValue;
    switch (type) {
      case 'all': {
        updatePayeeRelatedInfo(payee);

        resetField(
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          'payee.value' as FieldPath<TFieldValues>,
          {
            defaultValue:
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              payee?.id as PathValue<TFieldValues, Path<TFieldValues>>,
          }
        );
        break;
      }
      case 'only':
        break;
      default:
        break;
    }
  }, [
    bookDateName,
    dueDateName,
    selectedValue,
    resetField,
    updatePayeeRelatedInfo,
  ]);
};
