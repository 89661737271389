import { defaultBusinessPersonData } from '@/context/services/tpm/hooks';
import { BusinessPersonData, tpmData } from '@/context/services/tpm/type';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

type BusinessPersonDataContextType = {
  businessPersonData: BusinessPersonData;
  setBusinessPersonData: React.Dispatch<
    React.SetStateAction<BusinessPersonData>
  >;
};

const defaultContextValue: BusinessPersonDataContextType = {
  businessPersonData: defaultBusinessPersonData,
  setBusinessPersonData: () => {},
};

const BusinessPersonDataContext =
  createContext<BusinessPersonDataContextType>(defaultContextValue);

type Props = {
  businessPersonData?: BusinessPersonData;
};

export const BusinessPersonDataProvider: FC<PropsWithChildren<Props>> = ({
  children,
  businessPersonData: initData,
}) => {
  const [businessPersonData, setBusinessPersonData] =
    useState<BusinessPersonData>(initData ?? defaultBusinessPersonData);

  const BusinessPersonDataContextValue = useMemo(
    () => ({
      businessPersonData,
      setBusinessPersonData,
    }),
    [businessPersonData, setBusinessPersonData]
  );

  return (
    <BusinessPersonDataContext.Provider value={BusinessPersonDataContextValue}>
      {children}
    </BusinessPersonDataContext.Provider>
  );
};

export const useBusinessPersonData = (): BusinessPersonDataContextType => {
  const context = useContext(BusinessPersonDataContext);
  if (context === undefined) {
    throw new Error(
      'useBusinessPersonData must be used within a BusinessPersonDataProvider'
    );
  }
  return context;
};

export const useTpmDataFromBusinessPersonData = (): tpmData => {
  const { businessPersonData } = useBusinessPersonData();
  return useMemo(
    () => businessPersonData.parseValidityDate(),
    [businessPersonData]
  );
};
