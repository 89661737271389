import { EditTemplate as InvoiceReportEditTemplate } from '@/features/InvoiceReport/Edit';
import { useParams } from '@/router';
import { FC, memo } from 'react';

const Page: FC = memo(() => {
  const { report_id } = useParams('/approving_invoice_reports/:report_id/edit');
  return (
    <InvoiceReportEditTemplate invoiceReportsId={report_id} route='approving' />
  );
});
Page.displayName = 'Page';

export default Page;
