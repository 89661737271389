import { getPageData } from '@/utils/getPageData';
import { uniqueArray } from '@/utils/uniqueArray';
import { InfiniteData } from '@tanstack/react-query';
import { CommonError } from 'ap-openapi';
import { AxiosResponse } from 'axios';
import {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type UseGetItemArgsWithNumber = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  per?: any;
};

type TBaseResponse<
  T,
  TResponse extends Record<string, T[]> | object
> = TResponse & {
  /** Link to next page */
  next?: string | null;
  /** Link to previous page */
  prev?: string | null;
};

type UseGetItemData<T, TResponse extends Record<string, T[]> | object> = {
  data:
    | InfiniteData<
        AxiosResponse<TBaseResponse<T, TResponse>, unknown>,
        number | undefined
      >
    | undefined;
  // data: AxiosResponse<TBaseResponse<T, TResponse>, unknown> | undefined;
};

type UseGetItemReturn<T, TResponse extends Record<string, T[]> | object> = {
  error: CommonError | null;
  isLoading: boolean;
  fetchNextPage: () => Promise<UseGetItemData<T, TResponse>>;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  isFetching: boolean;
} & UseGetItemData<T, TResponse>;

type UseGetItem<T, TResponse extends Record<string, T[]> | object> = (
  args: UseGetItemArgsWithNumber,
  options?: object
) => UseGetItemReturn<T, TResponse>;

type Find<T, K extends keyof T, R extends keyof T> = {
  key: K;
  return: R;
  defaultVal: T[R];
};

export const createSelectItemContext = <
  T,
  TResponse extends Record<string, T[]> | object,
  K1 extends keyof T,
  R1 extends keyof T,
  K2 extends keyof T,
  R2 extends keyof T
>(
  per: number,
  useGetItem: UseGetItem<T, TResponse>,
  dataKey: keyof TResponse,
  uniqueKey: keyof T,
  findId: Find<T, K1, R1>,
  findName: Find<T, K2, R2>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cacheKeyFn: (args: any) => readonly unknown[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any
) => {
  const itemsContext = createContext<T[]>([]);
  const isLoadingContext = createContext<boolean>(true);
  const setItemsContext = createContext<(items: T[]) => void>(() => {});
  const perContext = createContext(per);
  const isFetchingContext = createContext<boolean>(true);
  const cacheKeyContext = createContext<readonly unknown[] | undefined>(
    undefined
  );

  const Provider: FC<PropsWithChildren> = memo(({ children }) => {
    const defaultPagination = useDefaultPagination();
    const cacheKey = cacheKeyFn({ per, query: undefined });
    const wrapOptions = useMemo(
      () => ({
        query: {
          ...(options?.query ?? {}),
          getNextPageParam: defaultPagination,
          queryKey: cacheKey,
        },
        ...options,
      }),
      [cacheKey, defaultPagination]
    );
    const [items, setItems] = useState<T[]>([]);
    const onWrapSetItem = useCallback((items: T[]) => {
      setItems((prev) => uniqueArray(prev, items, uniqueKey));
    }, []);
    const {
      data,
      error,
      isLoading: isApiLoading,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      isFetching,
    } = useGetItem({ per }, wrapOptions);
    const [isLoading, setIsLoading] = useState(isApiLoading);
    const handleSetItem = useCallback(
      (
        data:
          | InfiniteData<
              AxiosResponse<TBaseResponse<T, TResponse>, unknown>,
              number | undefined
            >
          | undefined
      ) => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const value = getPageData(data, dataKey) as T[];
        onWrapSetItem(value);
      },
      [onWrapSetItem]
    );
    useEffect(() => {
      if (!isApiLoading) {
        handleSetItem(data);
        setIsLoading(false);
      }
    }, [data, handleSetItem, isApiLoading]);
    useEffect(() => {
      const f = async () => {
        if (hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      };
      f();
    }, [fetchNextPage, handleSetItem, hasNextPage, isFetchingNextPage]);
    if (error) throw error;
    return (
      <perContext.Provider value={per}>
        <cacheKeyContext.Provider value={cacheKey}>
          <setItemsContext.Provider value={onWrapSetItem}>
            <isLoadingContext.Provider value={isLoading}>
              <itemsContext.Provider value={items}>
                <isFetchingContext.Provider value={isFetching}>
                  {children}
                </isFetchingContext.Provider>
              </itemsContext.Provider>
            </isLoadingContext.Provider>
          </setItemsContext.Provider>
        </cacheKeyContext.Provider>
      </perContext.Provider>
    );
  });
  Provider.displayName = 'SelectItemProvider';

  const useSetItems = () => useContext(setItemsContext);
  const useItems = () => useContext(itemsContext);
  const useIsLoading = () => useContext(isLoadingContext);
  const useIsFetching = () => useContext(isFetchingContext);
  const useFetchPer = () => useContext(perContext);
  const useCacheKey = (query: string | undefined, args = {}) => {
    const key = useContext(cacheKeyContext);
    return useMemo(() => {
      if (key === undefined) return undefined;
      if (typeof key[1] === 'object') {
        return [key[0], { ...key[1], query, ...args }];
      }
      return [...key, query, args];
    }, [key, query, args]);
  };
  const useFindById = () => {
    const items = useItems();
    return useCallback(
      (value: string | undefined): T[R1] => {
        if (typeof value === 'undefined' || value === '') {
          return findId.defaultVal;
        }
        return (
          items.find((item) => item[findId.key] === value)?.[findId.return] ||
          findId.defaultVal
        );
      },
      [items]
    );
  };
  const useFindByName = () => {
    const items = useItems();
    return useCallback(
      (value: string | undefined): T[R2] => {
        if (typeof value === 'undefined' || value === '') {
          return findName.defaultVal;
        }
        return (
          items.find(
            (item) =>
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              (item[findName.key] as string | undefined)?.indexOf(value) !== -1
          )?.[findName.return] || findName.defaultVal
        );
      },
      [items]
    );
  };

  return {
    useFetchPer,
    useItems,
    useSetItems,
    useCacheKey,
    useIsLoading,
    useIsFetching,
    useFindById,
    useFindByName,
    Provider,
  };
};

type PageData = {
  data: {
    next?: string;
  };
};

const useDefaultPagination = () => {
  return useCallback((page: PageData) => {
    const url = new URL(page.data.next ?? '/', location.href);
    if (!url.searchParams.has('page')) return undefined;
    return Number(url.searchParams.get('page'));
  }, []);
};
