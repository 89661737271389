export type InvoiceTransactionMapping = {
  dealDate: string;
  name: string;
  exItemName: string;
  unitPriceExcludingTax?: string;
  unitPriceIncludingTax?: string;
  quantity?: string;
  totalValueExcludingTax?: string;
  totalValueIncludingTax?: string;
  exciseName: string;
  tax: string;
  withholdingIncomeTax: string;
  memo: string;
  deptName: string;
  projectName: string;
  crItemName: string;
  crSubItemName: string;
  currency: string;
  jpyRate: string;
};

export type CustomInvoiceTransactionMapping = InvoiceTransactionMapping & {
  invoiceKind?: string;
};

const invoiceTransactionMapping: InvoiceTransactionMapping = {
  dealDate: '取引日',
  name: '品目',
  exItemName: '経費科目',
  unitPriceExcludingTax: '単価（税抜）',
  unitPriceIncludingTax: '単価（税込）',
  quantity: '数量',
  totalValueExcludingTax: '金額（税抜）',
  totalValueIncludingTax: '金額（税込）',
  exciseName: '税区分',
  tax: '消費税額',
  withholdingIncomeTax: '源泉徴収額',
  memo: 'メモ',
  deptName: '費用負担部門',
  projectName: 'プロジェクト',
  crItemName: '貸方科目',
  crSubItemName: '貸方補助科目',
  currency: '通貨',
  jpyRate: 'レート',
};

// For using in /approving_reports and /admin_invoice_reports
const customInvoiceTransactionMapping: CustomInvoiceTransactionMapping = {
  dealDate: '取引日',
  name: '品目',
  exItemName: '経費科目',
  unitPriceExcludingTax: '単価（税抜）',
  unitPriceIncludingTax: '単価（税込）',
  quantity: '数量',
  totalValueExcludingTax: '金額（税抜）',
  totalValueIncludingTax: '金額（税込）',
  exciseName: '税区分',
  invoiceKind: 'インボイス経過措置',
  tax: '消費税額',
  withholdingIncomeTax: '源泉徴収額',
  memo: 'メモ',
  deptName: '費用負担部門',
  projectName: 'プロジェクト',
  crItemName: '貸方科目',
  crSubItemName: '貸方補助科目',
  currency: '通貨',
  jpyRate: 'レート',
};

export const exportMappings = (
  isApproverRole: boolean,
  showDetails: boolean,
  taxInclude: boolean
) => {
  const mapping: Record<string, string> = Object.assign(
    {},
    isApproverRole ? customInvoiceTransactionMapping : invoiceTransactionMapping
  );
  if (showDetails) {
    const keys = taxInclude
      ? ['unitPriceExcludingTax', 'totalValueExcludingTax']
      : ['unitPriceIncludingTax', 'totalValueIncludingTax'];
    keys.forEach((key) => {
      delete mapping[key];
    });
    return mapping;
  } else {
    [
      'unitPriceExcludingTax',
      'unitPriceIncludingTax',
      'quantity',
      taxInclude ? 'totalValueExcludingTax' : 'totalValueIncludingTax',
    ].forEach((key) => {
      delete mapping[key];
    });
    return mapping;
  }
};

const importMapping: Record<string, keyof InvoiceTransactionMapping> =
  Object.entries(invoiceTransactionMapping).reduce(
    (prev, [key, value]) => ({ ...prev, [value]: key }),
    {} satisfies Record<string, string>
  );
const importCustomMapping: Record<string, keyof InvoiceTransactionMapping> =
  Object.entries(customInvoiceTransactionMapping).reduce(
    (prev, [key, value]) => ({ ...prev, [value]: key }),
    {} satisfies Record<string, string>
  );

export const importMappings = (
  isApproverRole: boolean,
  showDetails: boolean,
  taxInclude: boolean
) => {
  const mapping = Object.assign(
    {},
    isApproverRole ? importCustomMapping : importMapping
  );
  if (showDetails) {
    if (taxInclude) {
      ['単価（税抜）', '金額（税抜）'].forEach((key) => {
        delete mapping[key];
      });
    } else {
      ['単価（税込）', '金額（税込）'].forEach((key) => {
        delete mapping[key];
      });
    }
    return mapping;
  } else {
    [
      '単価（税抜）',
      '単価（税込）',
      '数量',
      taxInclude ? '金額（税抜）' : '金額（税込）',
    ].forEach((key) => {
      delete mapping[key];
    });
    return mapping;
  }
};
