import { ApPayeeRef } from '@/components/ApPayee';
import { OfficeMember } from '@/components/OfficeMember';
import { SuspenseErrorBoundary } from '@/components/SuspenseErrorBoundary';
import { ChangeNotification } from '@/features/invoices/components/Details/Components/ReceivedInvoice/Edit/OfficeMemberEdit/ChangeNotification';
import { usePayeeOfficeMembersUpdate } from '@/features/invoices/components/Details/Components/ReceivedInvoice/hooks/usePayeeOfficeMembersUpdate';
import { OfficeMembers } from '@/features/invoices/components/Details/Components/ReceivedInvoice/type';
import { ReceivedInvoiceForm } from '@/features/invoices/components/Details/Components/ReceivedInvoice/types';
import { useTranslation } from '@/i18n';
import classnames from 'classnames/bind';
import { Dispatch, FC, memo, SetStateAction, useMemo } from 'react';
import {
  Control,
  UseFormClearErrors,
  UseFormResetField,
  UseFormSetValue,
} from 'react-hook-form';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  control: Control<ReceivedInvoiceForm>;
  setValue: UseFormSetValue<ReceivedInvoiceForm>;
  clearErrors: UseFormClearErrors<ReceivedInvoiceForm>;
  resetField: UseFormResetField<ReceivedInvoiceForm>;
  picErrors: string[];
  setPicError: Dispatch<SetStateAction<string[]>>;
  apPayeeRef: React.RefObject<ApPayeeRef>;
  originalPics: OfficeMembers | undefined;
  originalPayeeId: string;
  enableFeature: boolean;
};

export const _OfficeMemberEdit: FC<Props> = memo(
  ({
    control,
    picErrors,
    originalPics,
    enableFeature,
    apPayeeRef,
    setValue,
    resetField,
    setPicError,
    clearErrors,
  }) => {
    const { t } = useTranslation();

    const { isShowNotification, handleUndo, formPicsOptions } =
      usePayeeOfficeMembersUpdate(
        control,
        apPayeeRef,
        originalPics,
        setValue,
        resetField,
        enableFeature
      );
    const onUndo = () => {
      clearErrors('formPics');
      setPicError([]);
      handleUndo();
    };

    const defaultOptions = useMemo(() => {
      if (!formPicsOptions) return [];
      return formPicsOptions.toOptions();
    }, [formPicsOptions]);

    return (
      <div
        className={cx(styles['container'])}
        data-testid='office-member-edit-container'
      >
        <OfficeMember<ReceivedInvoiceForm>
          key={formPicsOptions?.toFormValues().join(',')}
          control={control}
          name='formPics'
          inputId='office-member'
          testId='office-member'
          statusValues={picErrors}
          validateMaxCount={5}
          defaultOptions={defaultOptions}
          aria-label={t('received_invoice_pics')}
        />

        {enableFeature && (
          <ChangeNotification
            isShowNotification={isShowNotification}
            onUndo={onUndo}
          />
        )}
      </div>
    );
  }
);

_OfficeMemberEdit.displayName = '_OfficeMemberEdit';

export const OfficeMemberEdit: FC<Props> = memo((props) => {
  return (
    <SuspenseErrorBoundary {...props}>
      <_OfficeMemberEdit {...props} />
    </SuspenseErrorBoundary>
  );
});
OfficeMemberEdit.displayName = 'OfficeMemberEdit';
