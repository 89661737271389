import { BaseProps } from '@/components/ReportForm/TotalAmountPaid/TotalAmountPaid';
import { useGetForeignCurrencySetting } from '@/context/services/foreign_currency/ForeignCurrencySetting.service';
import {
  RoundSettingMode,
  RoundSettingModeType,
} from '@/features/foreign_currency_settings/components/RoundSetting/type';
import { InvoiceTransaction } from '@/features/InvoiceReport/Edit/type';
import { useBooleanFlagValue } from '@openfeature/react-sdk';
import { Excises } from 'ap-openapi';
import { Big } from 'big.js';

const nullNumber = (value: number | null | undefined, defValue = 0): number => {
  if (typeof value === 'string') {
    try {
      return parseFloat(value);
    } catch (error) {
      return defValue;
    }
  }
  return value ?? defValue;
};

export const calculateConversion = (
  total: number,
  rate: number,
  roundSetting: RoundSettingModeType
) => {
  const totalBig = Big(total);
  const rateBig = Big(rate);
  const result = totalBig.times(rateBig);

  switch (roundSetting) {
    case RoundSettingMode.RoundDown:
      return Number(result.round(0, Big.roundDown));
    case RoundSettingMode.RoundUp:
      return Number(result.round(0, Big.roundUp));
    default:
      return Number(result.round(0));
  }
};

/**
 * Calculate the maximum currency, which is the currency with the highest total value when converted into Japanese Yen.
 */
function _getMaxCurrency(
  invoiceList: InvoiceTransaction[],
  roundingMode: RoundSettingModeType
) {
  const currencyTotals = invoiceList
    .filter((invoice) => invoice.currency !== 'JPY')
    .reduce((acc, curr) => {
      const { currency, totalValue = 0, jpyRate = 1 } = curr;
      if (!currency) return acc;

      const currencyTotalValue = acc[currency]?.totalValue ?? 0;
      return {
        ...acc,
        [currency]: {
          ...(acc[currency] ?? {
            currency,
            totalValue,
            jpyRate,
          }),
          totalValue:
            currencyTotalValue +
            calculateConversion(
              nullNumber(totalValue),
              nullNumber(jpyRate),
              roundingMode
            ),
        },
      };
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    }, {} as Record<string, { currency: string; totalValue: number; jpyRate: number }>);

  return Object.values(currencyTotals).reduce(
    (max, currentCurrency) => {
      return currentCurrency.totalValue > max.totalValue
        ? currentCurrency
        : max;
    },
    { currency: '', totalValue: 0, jpyRate: 1 }
  );
}

/**
 * Calculate the total amount(not converted into Japanese Yen) for the specified currency (max currency).
 */
function _getMaxCurrencyTotalValue(
  invoiceList: InvoiceTransaction[],
  maxCurrency: string
): number {
  return invoiceList.reduce((sum, invoice) => {
    return invoice.currency === maxCurrency
      ? sum + nullNumber(invoice.totalValue)
      : sum;
  }, 0);
}

export const useCalcSummary = (
  invoiceTransactions: InvoiceTransaction[] | undefined,
  exciseList: Excises[]
): BaseProps => {
  const isForeignCurrencyFlag = useBooleanFlagValue(
    'foreign-currency-support',
    false
  );
  const { data } = useGetForeignCurrencySetting() || {
    rounding_mode: RoundSettingMode.RoundDown,
  };
  const roundingMode = data?.rounding_mode ?? RoundSettingMode.RoundDown;

  const maxCurrency = isForeignCurrencyFlag
    ? _getMaxCurrency(invoiceTransactions || [], roundingMode)
    : { currency: '', totalValue: 0, jpyRate: 1 };

  const maxCurrencyTotalValue: number = isForeignCurrencyFlag
    ? _getMaxCurrencyTotalValue(invoiceTransactions || [], maxCurrency.currency)
    : 0;

  const calculateSummaryProps: {
    withExciseTaxRateOf: {
      [key: string]: {
        totalValue: number;
        exciseValue: number;
        exciseTaxIncludedTotalValue: number;
        name: string;
      };
    };
    totalValue: number;
    exciseValue: number;
    withholdingIncomeTax: number;
    uniqueCurrency: Set<string>;
  } = {
    withExciseTaxRateOf: {
      '0percent': {
        totalValue: 0,
        exciseValue: 0,
        exciseTaxIncludedTotalValue: 0,
        name: '対象外',
      },
      '5percent': {
        totalValue: 0,
        exciseValue: 0,
        exciseTaxIncludedTotalValue: 0,
        name: '5%対象',
      },
      '8percent': {
        totalValue: 0,
        exciseValue: 0,
        exciseTaxIncludedTotalValue: 0,
        name: '8%対象',
      },
      '10percent': {
        totalValue: 0,
        exciseValue: 0,
        exciseTaxIncludedTotalValue: 0,
        name: '10%対象',
      },
    },
    totalValue: 0,
    exciseValue: 0,
    withholdingIncomeTax: 0,
    uniqueCurrency: new Set(),
  };
  invoiceTransactions?.forEach((invoice) => {
    if (isForeignCurrencyFlag) {
      if (invoice.currency && invoice.currency !== 'JPY') {
        calculateSummaryProps.uniqueCurrency.add(invoice.currency);
      }
      calculateSummaryProps.totalValue += calculateConversion(
        nullNumber(invoice.totalValue),
        invoice.jpyRate ?? 1,
        roundingMode
      );
    } else {
      calculateSummaryProps.totalValue += nullNumber(invoice.totalValue);
    }
    calculateSummaryProps.exciseValue += nullNumber(invoice.exciseValue);
    calculateSummaryProps.withholdingIncomeTax +=
      invoice.hasWithholdingIncomeTax
        ? nullNumber(invoice.withholdingIncomeTax)
        : 0;
    // 税率0%のものはnullが入っているためnullをkeyとする
    const exciseRateMapping: { [key: number]: string } = {
      0: '0percent',
      0.1: '10percent',
      0.08: '8percent',
      0.05: '5percent',
    };
    const breakdownDrExcise = exciseList.find(
      (item) => item.id === invoice.drExciseId
    );

    // 税区分が0%扱いのものだった場合、nullを入れる
    const exciseRate = breakdownDrExcise?.is_zero_per
      ? 0
      : breakdownDrExcise?.excise_rate ?? undefined;

    // 税率ごとの小計と消費税合計を集計する
    if (
      exciseRate !== undefined &&
      Object.hasOwn(exciseRateMapping, exciseRate)
    ) {
      const key = exciseRateMapping[exciseRate];
      if (key) {
        const withExciseTaxRateOf =
          calculateSummaryProps.withExciseTaxRateOf[key];
        if (withExciseTaxRateOf) {
          if (isForeignCurrencyFlag) {
            withExciseTaxRateOf.totalValue += calculateConversion(
              nullNumber(invoice.totalValue),
              invoice.jpyRate ?? 1,
              roundingMode
            );
          } else {
            withExciseTaxRateOf.totalValue += nullNumber(invoice.totalValue);
          }
          withExciseTaxRateOf.exciseValue += nullNumber(invoice.exciseValue);
          if (isForeignCurrencyFlag) {
            withExciseTaxRateOf.exciseTaxIncludedTotalValue +=
              calculateConversion(
                nullNumber(invoice.totalValue),
                invoice.jpyRate ?? 1,
                roundingMode
              ) + nullNumber(invoice.exciseValue);
          } else {
            withExciseTaxRateOf.exciseTaxIncludedTotalValue +=
              nullNumber(invoice.totalValue) + nullNumber(invoice.exciseValue);
          }
        }
      }
    }
  });
  return {
    summary: {
      detailsTotalAmount: {
        value:
          calculateSummaryProps.totalValue + calculateSummaryProps.exciseValue,
      },
      totalAmount: {
        value:
          calculateSummaryProps.totalValue +
          calculateSummaryProps.exciseValue -
          calculateSummaryProps.withholdingIncomeTax,
        foreignCurrency: {
          value: maxCurrencyTotalValue,
          unit: maxCurrency?.currency,
          isMultipleCurrency: calculateSummaryProps.uniqueCurrency.size > 1,
        },
      },
      taxAmount: {
        value: calculateSummaryProps.exciseValue,
      },
      withholdingIncomeTaxValue: {
        value: calculateSummaryProps.withholdingIncomeTax,
      },
    },
    details: {
      headers: [
        { value: '税率別内訳' },
        { value: '小計（税抜）', textRight: true, size: 'sm' },
        { value: '消費税', textRight: true, size: 'sm' },
        { value: '小計（税込）', textRight: true, size: 'sm' },
      ],
      rows: Object.entries(calculateSummaryProps.withExciseTaxRateOf)
        .filter(
          ([, value]) =>
            value.exciseTaxIncludedTotalValue !== 0 || value.totalValue !== 0
        )
        .reverse()
        .map(([key, value]) => ({
          key: key,
          cells: [
            { value: value.name },
            { value: value.totalValue, textRight: true, size: 'sm' },
            { value: value.exciseValue, textRight: true, size: 'sm' },
            {
              value: value.exciseTaxIncludedTotalValue,
              textRight: true,
              size: 'sm',
            },
          ],
        })),
    },
  };
};
