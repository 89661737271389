import { ApPayeeDetail } from 'ap-openapi';
import { FC, memo } from 'react';
import { Dialog as BaseDialog } from '../BaseDialog';
import { SelectedType } from '../type';
import { ApPayee } from './ApPayee';

export type Props = {
  open: boolean;
  onClose: VoidFunction;
  onClick: (value: SelectedType, args?: ApPayeeDetail | undefined) => void;
};

export const Dialog: FC<Props> = memo(({ open, onClose, onClick }) => {
  return (
    <BaseDialog<ApPayeeDetail>
      open={open}
      onClose={onClose}
      onClick={onClick}
      testId='ap-payee-dialog'
    >
      {({ onChange }) => (
        <ApPayee key={`change-ap-payee-${open}`} onChange={onChange} />
      )}
    </BaseDialog>
  );
});
Dialog.displayName = 'Dialog';
