import { ApPayeeRef } from '@/components/ApPayee';
import { OfficeMembers } from '@/features/invoices/components/Details/Components/ReceivedInvoice/type';
import { ReceivedInvoiceForm } from '@/features/invoices/components/Details/Components/ReceivedInvoice/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Control,
  UseFormResetField,
  UseFormSetValue,
  useFormState,
  useWatch,
} from 'react-hook-form';

const arePicsEqual = (
  pics1?: OfficeMembers,
  pics2?: OfficeMembers
): boolean => {
  if (!pics1 && !pics2) return true;
  if (!pics1 || !pics2) return false;

  if (pics1.values.length !== pics2.values.length) return false;

  return pics1.values.every((pic1) =>
    pics2.values.some((pic2) => pic1.id === pic2.id)
  );
};

interface ChangeState {
  isSystemChange: boolean;
  userModified: boolean;
  payeeIdChanged: boolean;
  lastSystemFormPics: string[];
}

export const usePayeeOfficeMembersUpdate = (
  control: Control<ReceivedInvoiceForm>,
  apPayeeRef: React.RefObject<ApPayeeRef>,
  originalPics: OfficeMembers | undefined,
  setValue: UseFormSetValue<ReceivedInvoiceForm>,
  resetField: UseFormResetField<ReceivedInvoiceForm>,
  enableFeature: boolean
) => {
  const [isShowNotification, setIsShowNotification] = useState(false);
  const changeStateRef = useRef<ChangeState>({
    isSystemChange: false,
    userModified: false,
    payeeIdChanged: false,
    lastSystemFormPics: [],
  });
  const { dirtyFields } = useFormState({ control });
  const payeeId = useWatch({ control, name: 'apPayeeId' });
  const formPics = useWatch({ control, name: 'formPics' });
  const [formPicsOptions, setFormPicsOptions] = useState<
    OfficeMembers | undefined
  >(originalPics);
  const originalPicsRef = useRef(originalPics);

  const fetchPayeeDetails = useCallback(
    async (id: string) => {
      if (!apPayeeRef.current) return;

      try {
        const payeeDetails = await apPayeeRef.current.findDetails(id);
        const contactOfficeMembers = payeeDetails?.contact_office_members || [];
        let newOfficeMembers: OfficeMembers = {
          values: [],
          toFormValues: () => [],
          toLabels: () => '',
          toOptions: () => [],
        };

        if (contactOfficeMembers.length > 0) {
          const validPics = contactOfficeMembers
            .filter((item) => item !== null)
            .map((item) => ({
              id: item?.id || '',
              name: item?.name || '',
            }));

          if (validPics.length > 0) {
            newOfficeMembers = {
              values: validPics,
              toFormValues: () => validPics.map((item) => item.id),
              toLabels: () => validPics.map((item) => item.name).join(','),
              toOptions: () =>
                validPics.map((item) => ({ label: item.name, value: item.id })),
            };
          }
        }

        const picsChanged = !arePicsEqual(
          originalPicsRef.current,
          newOfficeMembers
        );

        changeStateRef.current.isSystemChange = true;
        changeStateRef.current.userModified = false;
        changeStateRef.current.payeeIdChanged = true;
        changeStateRef.current.lastSystemFormPics =
          newOfficeMembers?.toFormValues() ?? [];
        setFormPicsOptions(newOfficeMembers);
        setIsShowNotification(picsChanged);
        resetField('formPics', { keepDirty: false });
        setValue('formPics', newOfficeMembers?.toFormValues() ?? [], {
          shouldValidate: true,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching payee details:', error);
        changeStateRef.current.isSystemChange = false;
      }
    },
    [apPayeeRef, setValue, resetField]
  );

  // Pics changes when payee changes
  useEffect(() => {
    if (!enableFeature) return;
    if (!payeeId) return;
    if (dirtyFields.apPayeeId || changeStateRef.current.payeeIdChanged)
      fetchPayeeDetails(payeeId);
  }, [payeeId, fetchPayeeDetails, dirtyFields.apPayeeId, enableFeature]);

  // Pics changes when formPics changeStateRef
  useEffect(() => {
    if (!enableFeature) return;
    const isPicsChanged =
      JSON.stringify(formPics) !==
      JSON.stringify(changeStateRef.current.lastSystemFormPics);

    if (isPicsChanged && !changeStateRef.current.isSystemChange) {
      changeStateRef.current.userModified = true;
      setIsShowNotification(false);
    } else if (changeStateRef.current.isSystemChange) {
      changeStateRef.current.isSystemChange = false;
    }
  }, [formPics, enableFeature, dirtyFields.formPics]);

  const resetState = useCallback(() => {
    changeStateRef.current = {
      isSystemChange: false,
      userModified: false,
      payeeIdChanged: false,
      lastSystemFormPics: [],
    };
  }, []);

  useEffect(() => {
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUndo = useCallback(() => {
    setFormPicsOptions(originalPicsRef.current);
    setValue('formPics', originalPicsRef.current?.toFormValues() ?? [], {
      shouldValidate: true,
      shouldDirty: false,
    });
    resetState();
    setIsShowNotification(false);
  }, [setValue, resetState]);

  return {
    isShowNotification,
    handleUndo,
    formPicsOptions,
  };
};
