import { useGetFlags } from '@/hooks/useFeatureFlag';
import { IconMemo } from '@moneyforward/ap-frontend-components';
import { Tooltip } from 'antd';
import cx from 'classnames';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import styles from '../columns.module.scss';

interface MemoCellProps {
  value: string;
}

export const MemoCell: FC<MemoCellProps> = ({ value }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const flagEnhanceInvoiceListSearch = useGetFlags(
    'ENHANCE_INVOICE_LIST_SEARCH'
  );
  const textStyle = useMemo(() => ({ color: 'var(--color-text-primary)' }), []);

  const tooltipContent = useMemo(
    () => <div className={cx(styles['tooltipContent'])}>{value}</div>,
    [value]
  );

  useEffect(() => {
    const checkTruncation = () => {
      const element = contentRef.current;
      if (element) {
        setIsTruncated(element.scrollHeight > element.clientHeight);
      }
    };

    checkTruncation();
    window.addEventListener('resize', checkTruncation);
    return () => window.removeEventListener('resize', checkTruncation);
  }, [value]);

  if (flagEnhanceInvoiceListSearch) {
    return (
      <Tooltip
        overlayClassName={cx(styles['tooltip'])}
        title={isTruncated ? tooltipContent : ''}
        placement='topRight'
        destroyTooltipOnHide
      >
        <div
          ref={contentRef}
          className={cx(styles['memoEnhanced'])}
          data-testid='memo-tooltip'
          style={textStyle}
        >
          {value}
        </div>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={value}>
        <div className={cx(styles['memo'])} data-testid='memo-tooltip'>
          <IconMemo />
        </div>
      </Tooltip>
    );
  }
};
