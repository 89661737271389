import { PaymentRequestSetting } from '@/features/notification_setting/components/PaymentRequestSetting';
import { ReceivedInvoiceSetting } from '@/features/notification_setting/components/ReceivedInvoiceSetting';
import { NotificationSettingEditContextProvider } from '@/features/notification_setting/context/NotificationSettingEdit';
import { useTranslation } from '@/i18n';
import { Layout } from '@/Layout';

export const NotificationSetting = () => {
  const { t } = useTranslation();

  return (
    <NotificationSettingEditContextProvider>
      <Layout title={t('notification')}>
        <ReceivedInvoiceSetting />
        <PaymentRequestSetting />
      </Layout>
    </NotificationSettingEditContextProvider>
  );
};
