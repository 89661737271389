import {
  InputSelect,
  Props as InputSelectProps,
} from '@/components/InputSelect';
import { OtherInput } from '@/components/OtherInput';
import { useGetPopupContainer } from '@/components/ReportForm/Container/PopupContainer';
import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { useOtherInput } from '@/components/ReportForm/hooks/useOtherValue';
import { FormSelectProps } from '@moneyforward/ap-frontend-components';
import { InputProps } from 'antd';
import { memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { UnionOtherInputProps } from '../type';

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues> &
    InputSelectProps<TFieldValues> &
    UnionOtherInputProps<TFieldValues>;

const InnerApReportFormInputSelect = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  label,
  caption,
  options: _options,
  withOtherInput,
  otherInputName,
  otherInputTestId,
  hidden,
  required = false,
}: Props<TFieldValues>) => {
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label }),
    [required, label]
  );

  const options = useMemo(() => _options || [], [_options]);
  const { isOtherValueChecked, onChangeOtherValueChecked } =
    useOtherInput<TFieldValues>(name, otherInputName, withOtherInput);
  const inputProps: InputProps = useMemo(() => {
    return {
      testId: otherInputTestId,
      placeholder: '',
      disabled: !isOtherValueChecked,
    };
  }, [isOtherValueChecked, otherInputTestId]);
  const memorizedOtherInput = useMemo(
    () =>
      withOtherInput ? (
        <OtherInput
          inputProps={inputProps}
          onChange={onChangeOtherValueChecked}
          otherValueChecked={isOtherValueChecked}
          control={control}
          inputId={otherInputName}
          name={otherInputName}
        />
      ) : undefined,
    [
      withOtherInput,
      inputProps,
      onChangeOtherValueChecked,
      isOtherValueChecked,
      control,
      otherInputName,
    ]
  );
  const containerRef = useGetPopupContainer();
  const memorizedSelectProps = useMemo(
    (): Omit<
      FormSelectProps<TFieldValues>['selectProps'],
      'options' | 'comboBox'
    > => ({
      getPopupContainer: containerRef,
      disabled: isOtherValueChecked,
    }),
    [containerRef, isOtherValueChecked]
  );
  return (
    <InputBase labelProps={labelProps} description={caption} hidden={hidden}>
      <InputSelect<TFieldValues>
        options={options}
        control={control}
        name={name}
        inputId={inputId}
        selectProps={memorizedSelectProps}
        otherInput={memorizedOtherInput}
        required={required}
        isOtherInputChecked={isOtherValueChecked}
      />
    </InputBase>
  );
};

InnerApReportFormInputSelect.displayName = 'InnerApReportFormInputSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputSelect = memo((props) => (
  <InnerApReportFormInputSelect {...props} />
)) as typeof InnerApReportFormInputSelect;
ApReportFormInputSelect.displayName = 'ApReportFormInputSelect';
