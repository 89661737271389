import { useTranslation } from '@/i18n';
import { Link, Path } from '@/router';
import classnames from 'classnames/bind';
import { FC, memo, useMemo } from 'react';
import styles from './reportFormStatus.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  invoiceId?: string;
};

export const ReportFormStatusNumber: FC<Props> = memo(({ invoiceId }) => {
  const { t } = useTranslation();
  const linkTo = useMemo((): { pathname: Path; search: string } => {
    const searchPath = new URLSearchParams({ id: invoiceId ?? '' }).toString();
    return { pathname: '/received_invoices', search: searchPath };
  }, [invoiceId]);

  if (!invoiceId) {
    return;
  }
  return (
    <div className={cx(styles['report-form-status-text-wrapper'])}>
      <p className={cx(styles['report-form-status-text-label'])}>
        {t('invoice_id')}
      </p>
      <p className={cx(styles['report-form-status-text-value'])}>
        <Link to={linkTo} target='_blank'>
          {invoiceId}
        </Link>
      </p>
    </div>
  );
});
ReportFormStatusNumber.displayName = 'ReportForm';
