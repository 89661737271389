import { useCallback, useMemo } from 'react';
import {
  FieldPath,
  FieldValues,
  Path,
  PathValue,
  useFormContext,
} from 'react-hook-form';

type ReturnUseGetFormValue<TValue = File> = {
  defaultValue: TValue | null;
  onChange: (value: TValue) => void;
};

export const useGetFormValue = <
  TFieldValues extends FieldValues,
  TValue = File
>(
  name: FieldPath<TFieldValues>
): ReturnUseGetFormValue<TValue> => {
  const { getValues, setValue } = useFormContext<TFieldValues>();
  const onChange = useCallback(
    (value: TValue) => {
      const v = value ?? null;
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      setValue(name, v as PathValue<TFieldValues, Path<TFieldValues>>);
    },
    [name, setValue]
  );
  const defaultValue: TValue | null = useMemo(() => {
    const value = getValues(name) ?? null;
    return value;
  }, [getValues, name]);
  return {
    defaultValue,
    onChange,
  };
};
