import { useTranslation } from '@/i18n';
import { OfficeMemberDept, useGetOfficeMemberPositionDepts } from 'ap-openapi';
import { useMemo } from 'react';

export const useOfficeMemberPositionDeptData = (
  officeMemberId: string,
  query?: string
) => {
  const { data: apiData } = useGetOfficeMemberPositionDepts(officeMemberId);
  const { t } = useTranslation();

  const data: OfficeMemberDept[] = useMemo(
    () =>
      [
        ...(apiData?.data.office_member_position_depts?.map((item) => {
          const deptName = item.dept?.name ?? t('common.dept.noDept');
          const positionName = item.position?.name
            ? `(${item.position?.name})`
            : '';
          return {
            id: item?.id,
            name: `${deptName}${positionName}`,
          };
        }) ?? []),
      ].filter((item) => (query ? item.name?.indexOf(query) !== -1 : true)),
    [apiData?.data.office_member_position_depts, query, t]
  );

  return data;
};
