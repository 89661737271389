import { InstantPayeeFieldNamesType } from '@/components/InstantPayeeForm';
import { MapFormControlComponent, Mapping } from '@/components/ReportForm';
import { UnionOtherInputProps } from '@/components/ReportForm/type';
import { TInvoiceReportDetailFormInputsResponse } from '@/context/services/reportsType/invoiceReports/type';
import { typeGuardInvoiceReportMfFile } from '@/context/services/reportsType/invoiceReports/type-guard';
import { useHasSpecialExceptionWithControl } from '@/features/InvoiceReport/Edit/components/hooks/hooks';
import { PaymentRequestForm } from '@/features/InvoiceReport/Edit/type';
import { SendErrorTracking } from '@/utils/errors';
import { FC, ReactNode, memo, useMemo } from 'react';
import { Control, FieldPath } from 'react-hook-form';

export type Props = {
  item: TInvoiceReportDetailFormInputsResponse;
  control: Control<PaymentRequestForm>;
  isInstantPayeeEnabled: boolean;
};

const typeGuardComponentOfKey = (key: string): key is keyof typeof Mapping => {
  return key in Mapping;
};

const typeGuardComp = <T extends keyof MapFormControlComponent>(
  component: MapFormControlComponent[keyof MapFormControlComponent],
  key: T
): component is MapFormControlComponent[T] => {
  return component === Mapping[key];
};

export const ReportFormInputs: FC<Props> = memo(
  ({ item, control, isInstantPayeeEnabled }) => {
    const hasSpecialException = useHasSpecialExceptionWithControl(control);

    const instantPayeeFieldNames: InstantPayeeFieldNamesType<PaymentRequestForm>['instantPayeeFieldNames'] =
      useMemo(
        () => ({
          bankAccountName: 'payee.instantPayee.name',
          bankAccount: 'payee.instantPayee.bankId',
          bankAccountBranch: 'payee.instantPayee.bankBranchId',
          bankType: 'payee.instantPayee.accountType',
          bankNumber: 'payee.instantPayee.number',
          bankNameHolder: 'payee.instantPayee.holderNameKana',
        }),
        []
      );
    const defaultOption = useMemo(() => {
      if (item.input_values && item.input_values.length > 0) {
        const value = item.input_values[0];
        if (value?.value && value?.label) {
          return [
            {
              label: value.label,
              value: value.value,
            },
          ];
        }
      }
      return undefined;
    }, [item.input_values]);
    if (typeGuardComponentOfKey(item.type)) {
      const names = Mapping.names;
      const Element = Mapping[item.type];
      const formProps: {
        control: Control<PaymentRequestForm>;
        name: FieldPath<PaymentRequestForm>;
        inputId: string;
        label: string;
        required: boolean;
        caption: ReactNode;
        hidden: boolean;
      } = {
        control: control,
        name: `reportForm.${item.id}`,
        inputId: item.id,
        label: item.label,
        required: item.is_hidden ? false : item.is_required,
        caption: item.annotation,
        hidden: item.is_hidden,
      };
      const otherInputName: FieldPath<PaymentRequestForm> = `reportForm.${item.id}_other`;
      const options =
        item.input_options?.map((item) => ({
          id: `${formProps.inputId}_${item.id}`,
          value: item.value,
          label: item.display_value,
        })) ?? [];
      if (typeGuardComp(Element, names.ApReportFormInputCheckBox)) {
        const rest: UnionOtherInputProps<PaymentRequestForm> =
          item.has_other_value
            ? {
                withOtherInput: item.has_other_value,
                otherInputName,
              }
            : {};
        return (
          <Element<PaymentRequestForm>
            {...formProps}
            {...rest}
            options={options}
          />
        );
      } else if (
        typeGuardComp(Element, names.ApReportFormInputContractNumberField)
      ) {
        return <Element<PaymentRequestForm> {...formProps} />;
      } else if (
        typeGuardComp(Element, names.ApReportFormInputOfficeMemberSelect)
      ) {
        return (
          <Element<PaymentRequestForm>
            {...formProps}
            defaultOptions={defaultOption}
          />
        );
      } else if (typeGuardComp(Element, names.ApReportFormInputDateField)) {
        return <Element<PaymentRequestForm> {...formProps} />;
      } else if (typeGuardComp(Element, names.ApReportFormInputDeptSelect)) {
        return (
          <Element<PaymentRequestForm>
            {...formProps}
            defaultOptions={defaultOption}
          />
        );
      } else if (typeGuardComp(Element, names.ApReportFormInputExItemSelect)) {
        return (
          <Element<PaymentRequestForm>
            {...formProps}
            defaultOptions={defaultOption}
          />
        );
      } else if (typeGuardComp(Element, names.ApReportFormInputFileField)) {
        const data = item?.input_additional_values?.[0];
        const isMfFile = typeGuardInvoiceReportMfFile(data);
        const canDelete = isMfFile && data.can_delete;
        const mfFile = isMfFile ? data : undefined;

        return (
          <Element<PaymentRequestForm>
            {...formProps}
            canDelete={canDelete}
            mfFile={mfFile}
          />
        );
      } else if (
        typeGuardComp(Element, names.ApReportFormInputInvoiceBookDateField)
      ) {
        return (
          <Element<PaymentRequestForm> {...formProps} name='payee.bookDate' />
        );
      } else if (
        typeGuardComp(Element, names.ApReportFormInputInvoiceDueDateField)
      ) {
        return (
          <Element<PaymentRequestForm> {...formProps} name='payee.dueDate' />
        );
      } else if (
        typeGuardComp(Element, names.ApReportFormInputInvoicePayeeSelect)
      ) {
        return (
          <Element<PaymentRequestForm>
            {...formProps}
            name='payee.value'
            instantPayeeFieldNames={instantPayeeFieldNames}
            bookDateName='payee.bookDate'
            dueDateName='payee.dueDate'
            isInstantPayeeEnabled={isInstantPayeeEnabled}
            defaultOptions={defaultOption}
          />
        );
      } else if (
        typeGuardComp(
          Element,
          names.ApReportFormInputInvoiceRegistrationNumberField
        )
      ) {
        return (
          <Element<PaymentRequestForm>
            {...formProps}
            isDisabled={hasSpecialException}
          />
        );
      } else if (typeGuardComp(Element, names.ApReportFormInputNumberField)) {
        return <Element<PaymentRequestForm> {...formProps} />;
      } else if (
        typeGuardComp(Element, names.ApReportFormInputNumberFieldForBranch)
      ) {
        return <Element<PaymentRequestForm> {...formProps} />;
      } else if (typeGuardComp(Element, names.ApReportFormInputPayeeSelect)) {
        return (
          <Element<PaymentRequestForm>
            {...formProps}
            instantPayeeActivated={false}
            defaultOptions={defaultOption}
          />
        );
      } else if (typeGuardComp(Element, names.ApReportFormInputProjectSelect)) {
        return (
          <Element<PaymentRequestForm>
            {...formProps}
            defaultOptions={defaultOption}
          />
        );
      } else if (typeGuardComp(Element, names.ApReportFormInputRadioButton)) {
        const rest: UnionOtherInputProps<PaymentRequestForm> =
          item.has_other_value
            ? {
                withOtherInput: item.has_other_value,
                otherInputName,
              }
            : {};
        return (
          <Element<PaymentRequestForm>
            {...formProps}
            {...rest}
            options={options}
          />
        );
      } else if (typeGuardComp(Element, names.ApReportFormInputReceiptType)) {
        return (
          <Element<PaymentRequestForm>
            {...formProps}
            options={options}
            invoiceFileName='invoice_file'
          />
        );
      } else if (
        typeGuardComp(
          Element,
          names.ApReportFormInputReportNumberForExpenseField
        )
      ) {
        return <Element<PaymentRequestForm> {...formProps} />;
      } else if (typeGuardComp(Element, names.ApReportFormInputSelect)) {
        const rest: UnionOtherInputProps<PaymentRequestForm> =
          item.has_other_value
            ? {
                withOtherInput: item.has_other_value,
                otherInputName,
              }
            : {};
        return (
          <Element<PaymentRequestForm>
            {...formProps}
            {...rest}
            options={options}
          />
        );
      } else if (
        typeGuardComp(Element, names.ApReportFormInputSpecialExceptionStatus)
      ) {
        return (
          <Element<PaymentRequestForm>
            {...formProps}
            options={options}
            name='specialException.value'
            subInputName='specialException.options.addressOrLocation'
          />
        );
      } else if (typeGuardComp(Element, names.ApReportFormInputTextArea)) {
        return <Element<PaymentRequestForm> {...formProps} />;
      } else if (typeGuardComp(Element, names.ApReportFormInputTextField)) {
        return <Element<PaymentRequestForm> {...formProps} />;
      } else if (typeGuardComp(Element, names.ApReportFormInputTimeField)) {
        return <Element<PaymentRequestForm> {...formProps} />;
      } else if (
        typeGuardComp(Element, names.ApReportFormInputInvoiceFileField)
      ) {
        const data = item?.input_additional_values?.[0];
        const canDelete = data && 'can_delete' in data && data.can_delete;

        return (
          <Element<PaymentRequestForm>
            {...formProps}
            name='invoice_file'
            canDelete={canDelete === undefined ? true : canDelete}
          />
        );
      } else if (
        typeGuardComp(Element, names.ApReportFormInputBusinessDocumentsField)
      ) {
        return <Element<PaymentRequestForm> {...formProps} />;
      } else {
        throw new SendErrorTracking(`Un support type:${item.type}`);
      }
    }
    return <></>;
  }
);
ReportFormInputs.displayName = 'ReportForm';
