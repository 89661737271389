import {
  useExItemCacheKey,
  useExItemPer,
  useExItems,
} from '@/components/ExItemSelect/Provider';
import {
  BaseOption,
  useModelsToOptionsWithData,
} from '@/hooks/useTypeToOptions';
import { useTranslation } from '@/i18n';
import { getPageData } from '@/utils/getPageData';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';

import {
  ExItem as ExItemModel,
  useGetExItemsInfinite,
  useGetExItemsSuspenseInfinite,
} from 'ap-openapi';
import { memo, startTransition, useCallback, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';

const useGetExItem = (suspense: boolean) =>
  suspense ? useGetExItemsSuspenseInfinite : useGetExItemsInfinite;

export type Props<TFieldValues extends FieldValues> = {
  suspense?: boolean;
  selectProps?: Omit<
    FormSelectProps<TFieldValues>['selectProps'],
    'options' | 'comboBox'
  >;
  query?: string;
  per?: number;
  deptId?: string;
  validateMaxCount?: number;
  defaultOptions?: BaseOption[];
  comboBox?: boolean;
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

const InnerExItemSelect = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  defaultOptions: _defaultOptions,
  deptId,
  suspense,
  per = 100,
  comboBox = true,
  ...rest
}: Props<TFieldValues>) => {
  const memorizedSuspense = useMemo(
    () => (suspense === undefined ? true : suspense),
    [suspense]
  );
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );
  const { t } = useTranslation();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const ctxPer = useExItemPer();
  const ctxData = useExItems();
  const ctxCacheKey = useExItemCacheKey(query, { dept_id: deptId });
  const {
    data: apiData,
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useGetExItem(memorizedSuspense)(
    {
      query,
      dept_id: deptId,
      per: ctxPer ?? per,
    },
    {
      query: {
        getNextPageParam: (page) => {
          // FIXME: OpenAPIの間違い？
          //@ts-ignore
          const url = new URL(page.data.next ?? '/', location.href);
          if (!url.searchParams.has('page')) return undefined;
          return Number(url.searchParams.get('page'));
        },
        queryKey: ctxCacheKey,
      },
    }
  );
  if (error) throw error;
  const data = useMemo(
    () => [
      ...(query || deptId ? [] : ctxData),
      ...getPageData(apiData, 'ex_items'),
    ],
    [apiData, ctxData, query, deptId]
  );
  const options = useModelsToOptionsWithData<ExItemModel>(
    data,
    query,
    defaultOptions,
    {
      labelKeys: ['name'],
      value: 'id',
    }
  );
  const fetchMore = useCallback(
    async (callback: () => void) => {
      if (hasNextPage) {
        await fetchNextPage();
      }
      callback();
    },
    [fetchNextPage, hasNextPage]
  );
  const onSearch = useCallback((value: string) => {
    startTransition(() => {
      setQuery(value);
    });
  }, []);
  const selectProps: SelectProps = useMemo(() => {
    return {
      onSearchDebounceWait: 600,
      comboBox: comboBox,
      placeholder: t('ex_item_placeholder'),
      inputPlaceholder: t('ex_item_input_placeholder'),
      ..._selectProps,
      options: options,
      onSearch: onSearch,
      fetchMore: fetchMore,
      loading: isLoading,
      mode: 'single',
      selectAll: undefined,
      selectAllLabel: undefined,
    };
  }, [comboBox, t, _selectProps, options, onSearch, fetchMore, isLoading]);
  return <FormSelect<TFieldValues> selectProps={selectProps} {...rest} />;
};
InnerExItemSelect.displayName = 'InnerExItemSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ExItemSelect = memo((props) => (
  <InnerExItemSelect {...props} suspense />
)) as typeof InnerExItemSelect;
ExItemSelect.displayName = 'ExItemSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ExItemSelectWithoutSuspense = memo((props) => (
  <InnerExItemSelect {...props} suspense={false} />
)) as typeof InnerExItemSelect;
ExItemSelectWithoutSuspense.displayName = 'ExItemSelectWithoutSuspense';
