import { CurrenciesSelect } from '@/components/CurrenciesSelect';
import { CurrencyRateQuery } from '@/context/services/foreign_currency/CurrencyRates.service';
import { useTranslation } from '@/i18n';
import {
  ButtonV2,
  DatepickerProps,
  Form,
  FormDatepicker,
  Label,
  LabelProps,
  SearchConditions,
} from '@moneyforward/ap-frontend-components';
import { Col, Row } from 'antd';
import classnames from 'classnames/bind';
import { DATE_FORMAT, format } from 'date-util';
import { FC, memo, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import styles from './Search.module.scss';

const cx = classnames.bind(styles);

export type SearchType = {
  currency: string;
  date: string;
};

export type SearchInput = {
  currency: string;
};

type Props = {
  onSearch: (query: CurrencyRateQuery) => void;
};

export const Search: FC<Props> = memo(({ onSearch }) => {
  const { t } = useTranslation();
  const methods = useForm<SearchType>({
    defaultValues: {
      currency: '',
      date: '',
    },
    mode: 'all',
  });

  const datepickerProps: DatepickerProps = useMemo(
    () => ({
      placeholder: t('foreign-currency.search.date.placeholder'),
      label: {
        required: false,
        text: t('foreign-currency.search.date.label'),
      },
    }),
    [t]
  );
  const datepickerLabel: LabelProps = useMemo(
    () => ({
      required: false,
      text: t('foreign-currency.search.date.label'),
    }),
    [t]
  );
  const { watch } = methods;
  const values = watch();
  const inputs = useMemo(() => {
    const { currency, date } = values;
    return {
      currency,
      date: date ? format(date, DATE_FORMAT) : '',
    };
  }, [values]);

  const onSubmit = useCallback(() => {
    onSearch(inputs);
  }, [inputs, onSearch]);

  const search = useLocation().search;
  useMemo(() => {
    const currency = new URLSearchParams(search).get('currency');
    if (currency) {
      methods.setValue('currency', currency);
    }
  }, [search, methods]);

  return (
    <div className={cx('container')}>
      <Label
        labelClassName={cx('label')}
        text={t('foreign-currency.currency.label')}
        tooltip={t('foreign-currency.currency.description')}
      />
      <SearchConditions>
        <Form<SearchType> formMethod={methods} onSubmit={onSubmit}>
          <Row gutter={8} align='bottom' wrap>
            <Col className={cx(styles['search-input'])}>
              <CurrenciesSelect<SearchType>
                control={methods.control}
                name='currency'
                inputId='currency'
                testId='currencies-select'
                showLabel
                excludeJpy
              />
            </Col>
            <Col className={cx(styles['search-input'])}>
              <FormDatepicker
                single
                control={methods.control}
                name='date'
                inputId='date'
                datepickerProps={datepickerProps}
                label={datepickerLabel}
                testId='foreign-currency'
              />
            </Col>
            <Col>
              <ButtonV2 color='primary' type='submit' isSecondary size='sm'>
                {t('search')}
              </ButtonV2>
            </Col>
          </Row>
        </Form>
      </SearchConditions>
    </div>
  );
});

Search.displayName = 'Search';
